import { Injectable, NgZone, EventEmitter } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../core/storage.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { RequestService } from '../core/request.service';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { MenuController, ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { Globalization } from '@ionic-native/globalization/ngx';
import { PopupPage } from '../views/modals/popup/popup.page';
import { environment } from 'src/environments/environment';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { ToppingsPage } from '../views/modals/toppings/toppings.page';
import { CartPage } from '../views/modals/cart/cart.page';
import { CrossSellingPage } from '../views/modals/cross-selling/cross-selling.page';
import { AlertPage } from '../views/modals/alert/alert.page';
import { ModalPopoverPage } from '../views/web-views/shared/modal-popover/modal-popover.page';
import consts from '../views/web-views/shared/constatnts.json';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { SelectOrderTypePage } from '../views/modals/select-order-type/select-order-type.page';
import { Title } from '@angular/platform-browser';

declare var google: any;
declare var config: any;
declare var PlatformReady: any;


@Injectable({
  providedIn: 'root'
})

export class AppServiceService {
  POPOVER_TYPES = consts.POPOVER_TYPES;


  //App Object

  animationDrop: boolean = false;
  className: string = '';
  className2: string = '';

  AddNsUB: any = [];
  alagic_list: any = [];

  STORAGE_KEYS = {
    PAGES_KEY: "APP_PAGES"
  };
  googleAutocomplete: any;
  currentLang: string = 'en';
  cartIsOpen: boolean = false;
  editing_cart_item: any = null;

  routeToRestaurantList: boolean = false;
  activeMenu = null;
  menuList: any = [];
  dishList: any = [];
  hideCartButton: boolean = true;
  selectedDish: any = {
    total: 0,
    toping_total: 0,
    size_index: null,
    size_id: null,
    selectedTopings: [],
    fullDishInfo: []
  };

  isSideMenu: boolean = false;
  isFooterMenu: boolean = false;
  closenotice: boolean = false;
  isPreOrderAccept: boolean = false;
  close_note_toggle: boolean = true;

  public appObject: any = {
    appVersion: '0.1',
    isFirstTime: true,
    isAuthed: false,
    platformIsReady: false,
    viewPlatform: '',
    isLoading: true,
    currentLang: '',
    isAddressHave: false,
    isOrderProcess: false,
    sideMenuPages: [],
    FooterCouponLink: null,
    myAddress: {
      name: '',
      lat: '',
      lng: ''
    },
    oneSignalConfig: {
      key: '',
      fireid: ''
    },
    deviceUUID: '',
    userId: '',
    user: {
      type: 'guest',
      fname: '',
      lname: '',
      email: '',
      telephone: '',
      backyard: '',
      instructions: ''
    },
    baseUrl: '',
    promotions: [],
    new_promotions: [],
    settings: [],
    errors: [],
    restaurants: [],
    langList: [],
    selectedRestaurant: null,
    selectedRestaurantDetails: [],
    isPointsEnable: false,
    selectedRestaurantId: null,
    selectedDeliveryMethod: 'pickup', //delivery, dine_in, pickup
    selectedCoupon: [],
    orderObjectDup: {
      cart: []
    },
    orderObject: {
      cart: [],
      discounts: [],
      order_tax: [],
      delivery_tax: [],
      cart_summery: {
        total_tax_inclusive: 0,
        total_tax_exclusive: 0,
        net_total_without_tax: 0,
        net_total: 0,
        total_discount: 0,
        total_with_discount_price: 0,
        delivery_cost: 0,
        gross_total: 0,
        min_reached: false,
        min_amount: 0,
        remarks: '',
        total_dish_count: 0
      },
      order_details: {
        delivery_type: '',
        delivery_date: 'asap',
        delivery_time: 'asap',
        delivery_address: {
          name: '',
          lat: '',
          lng: ''
        },
        payment_type: '',
        name: '',
        email: '',
        mobile_number: '',
        backyard: '',
        special_note: ''

      },
      resturent_id: "",
      device_id: "",
      device_type: "",
      lang: ""
    },
    theme: this.getDefaultThemeColors(),
    couponWeb: '',
    couponWebAdded: false
  };
  orderList: [];
  public email_content = true;
  public code_content = false;
  public repeatpass_content = false;

  public isWebVersion = false;
  private addToCartSuccessfulyCallBack = new Subject<any>();
  private settingsLoadedCallBack = new Subject<any>();
  public isSettingsLoaded: boolean = false;

  public availableDeliveryMethods: any[] = [];

  public selectedNotification: any = null;

  dishFetchTimeoutList: any[] = [];

  public doNotFetchMain: boolean = false;
  public discountListWeb: any = [];

  public registrationServerError: boolean = false;

  public themeConfigFor: string = 'app';

  public isThemeObjectLoaded: boolean = false;

  public themeOb: any = this.getDefaultThemeColors();

  /**
   * in mobile app display location to change favourite restaurant
   */
  public tourModeOn: boolean = false;

  /**
   * keep selected restaurant categories for calculate category taxes later
   */
  public selectedRestaurantCategories = {
    restaurantId: null,
    categories: []
  }

  /**
   * New validation added before placing order
   * will check wether selected menu categories are available
   * for the selected time slots by user
   * so if there are any unavailable categories they will be on below array object
   */
  public menusNotAvailable: any[] = [];
  public tempDeliveryDate: string = '';
  public tempDeliveryTime: string = '';

  // This will use to highlight cart items that arn't available for
  // customer selected date and time in checkout page
  public unavailableDishes: any[] = [];

  public tourModeOnDeliveryPriceWeb:boolean = false;

  constructor(
    private storage: StorageService,
    private translate: TranslateService,
    private router: Router,
    private geolocation: Geolocation,
    private requestService: RequestService,
    private oneSignal: OneSignal,
    private platform: Platform,
    private globalization: Globalization,
    private modalController: ModalController,
    private iab: InAppBrowser,
    private menu: MenuController,
    private navController: NavController,
    public toastController: ToastController,
    public zone: NgZone,
    public titleService: Title,
    public route: ActivatedRoute
  ) {


  }


  //START INIT

  async appInit() {

    this.appObject.isLoading = true;
    //get App Object from storage if available else set object
    let appObject = await this.storage.get('appObject');

    if (appObject && appObject.theme) {
      this.themeOb = appObject.theme;
    }

    //if app version is different force init
    this.appObject.deviceUUID = config.did;
    this.appObject.currentLang = environment.DEFAULT_LANG;
    if (appObject && appObject.appVersion == environment.APP_VERSION) {
      this.appObject = appObject;
      this.appObject.platformIsReady = false;
      this.appObject.isLoading = true;
      this.appObject.errors = [];
    } else {
      this.appObject.appVersion = environment.APP_VERSION;
      this.appObject.baseUrl = environment.BASE_URL;

    }

    if (!this.appObject.theme.app || !this.appObject.theme.web) this.appObject.theme = this.getDefaultThemeColors();


    this.isWebVersion ? this.themeConfigFor = 'web' : '';

    this.isThemeObjectLoaded = true;

    //register device in server
    await this.deviceRegister();
    //set language
    await this.getLanguages();
    await this.setDefaultLanguage();

    //get google key
    await this.getGoogleKey();
    //get one signal key
    //await this.getOneSignalKey();
    //init one signal
    //await this.initOneSignal();

    //settigns
    await this.getSettings();

    this.appObject.platformIsReady = true;
    this.appObject.isLoading = false;
    //set global to ready
    config.platformReady = true;
    //clear cart for now
    //this.appObject.orderObject.cart = [];
    //this.appObject.orderObjectDup.cart = [];

    if (!appObject) {
      await this.updateAppObject();
      //should route to getting started
      //this.navController.navigateRoot(['/getting-started'], { replaceUrl: true });
    }
  }

  /**
   * This function for set default language as device language, function will map default language with
   * our avaiable language if user doesn't set manually in first time
  */
  async setDefaultLanguage() {

    if (this.appObject.currentLang != '') {
      this.translate.setDefaultLang(this.appObject.currentLang);
      this.translate.use(this.appObject.currentLang);
    } else {
      if ((this.platform.is('android') || this.platform.is('ios')) && !this.isMobileWeb()) {
        this.appObject.currentLang = environment.DEFAULT_LANG;
        let DeviceLang = await this.globalization.getPreferredLanguage();
        var patde = new RegExp("de");
        var patsi = new RegExp("si");
        if (this.appObject.langList.length > 0) {
          for (let i = 0; i < this.appObject.langList.length; i++) {
            let pat = new RegExp(this.appObject.langList[i]['code']);
            if (pat.test(DeviceLang.value)) {
              this.appObject.currentLang = this.appObject.langList[i]['code'];
            }
          }
        }
        this.translate.setDefaultLang(this.appObject.currentLang);
        this.translate.use(this.appObject.currentLang);
      } else {
        this.translate.setDefaultLang(environment.DEFAULT_LANG);
        this.translate.use(environment.DEFAULT_LANG);
        this.appObject.currentLang = environment.DEFAULT_LANG;
      }

    }

  }

  /***
   * Device register in server, this device id required for every api calls in future
  */
  async deviceRegister(force = false) {
    let currentid = await this.storage.get('DEVICEUUID');
    // this.showAlert('error','DEVICEUUID' ,(currentid + ' | ' + this.appObject.deviceUUID),false)
    if (force) {
      if (currentid == this.appObject.deviceUUID) {
        return false;
      } else {
        await this.storage.set('DEVICEUUID', this.appObject.deviceUUID);
      }
    }

    if (!this.appObject.userId || force)
      // this.showAlert('error','old user id | ' + force + '  | ' ,this.appObject.userId,false)
      return await this.requestService.post('register', {
        'device_id': this.appObject.deviceUUID,
        'lang': this.appObject.currentLang,
        'type': this.appObject.user.type
      }).then(data => {
        if (data.status) {
          this.appObject.userId = data.data.id;
          this.gettingStarted();
        }
      }, error => {
        this.appObject.isLoading = false;
      });

  }

  /**
   * Get google key from server side
  */
  async getGoogleKey() {

    return await this.requestService.post('google/map-key?device_type=' + config.plf, []).then(data => {

      if (data.status) {
        config.gkey = data.key;
      }
    }, error => {
      this.appObject.isLoading = false;
    });

  }

  /**
   * Get one signal key from server before initialize push notifications
  */
  async getOneSignalKey() {

    return await this.requestService.post('onesignal/key?device_type=' + config.plf, []).then(data => {

      if (data.status) {
        //set one signal id for web to init
        config.osid = data.onesignal_key;

        this.appObject.oneSignalConfig.key = data.onesignal_key;
        this.appObject.oneSignalConfig.fireid = data.firebase_id;

      }
    }, error => {
      this.appObject.isLoading = false;
    });

  }

  /**
   *Initialize one signal push notifications
  */
  async initOneSignal() {

    if (this.platform.is('android') || this.platform.is('ios')) {
      window["plugins"].oneSignal.startInit(this.appObject.oneSignalConfig.key, this.appObject.oneSignalConfig.fireid);

      window["plugins"].oneSignal.inFocusDisplaying(window["plugins"].oneSignal.OSInFocusDisplayOption.InAppAlert);

      window["plugins"].oneSignal.handleNotificationReceived().subscribe(() => {
        // do something when notification is received
      });

      window["plugins"].oneSignal.handleNotificationOpened().subscribe(() => {
        // do something when a notification is opened
      });

      window["plugins"].oneSignal.endInit();
      window["plugins"].oneSignal.getIds().then(identity => {
        this.appObject.deviceUUID = identity.userId;
        //call device registration again to store device id which from one signal
        this.deviceRegister();
        this.updateAppObject();
      });

    }

  }

  /**
   * Update app object to store appObject
  */
  async updateAppObject() {

    return await this.storage.set('appObject', this.appObject);

  }

  //END INIT


  /**
   * Getting started update
  */
  async gettingStarted() {

    this.appObject.isFirstTime = false;
    await this.updateAppObject();
    this.router.navigateByUrl('home');
  }

  /**
 * Init google map
*/
  async initGoogle(googleaddress) {
    try {

      var options = {
        componentRestrictions: { country: environment.GOOGLE_COUNTRIES }
      };

      this.googleAutocomplete = new google.maps.places.Autocomplete(await googleaddress.getInputElement(), options);

      this.googleAutocomplete.addListener('place_changed', () => {



        var place = this.googleAutocomplete.getPlace();
        let addressIsValid = [];
        let address = '';
        //for testing only
        //addressIsValid['street_number'] = true;
        //addressIsValid['route'] = true;
        //addressIsValid['postal_code'] = true;
        //addressIsValid['postal_code'] = true;
        //should remove in production

        if (place.address_components) {
          addressIsValid = this.validateAddressComponents(place.address_components);
          // place.address_components.forEach(part => {

          //   // console.log(part);

          //   if (part.types[0]) {
          //     if (part.types[0] === "street_number") {
          //       addressIsValid['street_number'] = true;
          //     }
          //     if (part.types[0] === "route") {
          //       addressIsValid['route'] = true;
          //     }
          //     if (part.types[0] === "postal_code") {
          //       addressIsValid['postal_code'] = true;
          //     }
          //     if (part.types[0] === "locality" || (part.types[1] && part.types[1] === "political")) {
          //       addressIsValid['locality'] = true;
          //     }

          //   }
          // });


        }

        // console.log(place, addressIsValid);

        this.zone.run(() => {
          if (this.isAddressComponentValid(addressIsValid)) {
            this.appObject.isAddressHave = true;
            // let address_splited = place.formatted_address.split(',');
            // address = place.formatted_address;
            // let country = address_splited[address_splited.length - 1];
            var changed = false;

            // if (country.trim() === 'Germany') {
            //   address = address.replace(", Germany", "");
            // }

            let streetNumber = '';
            if (addressIsValid['street_number'] === true) {
              let streetNumberComponent = place.address_components.find((component) => component.types[0] && component.types[0] === 'street_number');
              if (streetNumberComponent) streetNumber = streetNumberComponent.short_name ? streetNumberComponent.short_name.trim() : '';
            }

            this.appObject.myAddress.streetNo = streetNumber;
            // this.appObject.myAddress.name = address;
            let generatedAddress = this.genarateAddressFromAddressComponents(place.address_components);
            this.appObject.myAddress.name = generatedAddress;
            // console.log(this.appObject.myAddress.name);
            this.appObject.myAddress.lat = place.geometry.location.lat();
            this.appObject.myAddress.lng = place.geometry.location.lng();
            this.updateAppObject();
            this.appObject.errors = [];
          } else {
            //address not valid
            this.appObject.isAddressHave = false;
            this.appObject.myAddress.name = '';
            this.appObject.myAddress.lat = '';
            this.appObject.myAddress.lng = '';
            this.appObject.myAddress.streetNo = '';


            this.updateAppObject();
            this.appObject.errors['address'] = ['address_is_not_valid'];
            // console.log(this.appObject);
          }

        });

      });
    } catch (error) {
      setTimeout(() => {
        this.initGoogle(googleaddress);
      }, 1000);
    }


  }

  /**
   * Generate address from google address component list
   * @param addressComponents google address component list
   * @returns 
   */
  genarateAddressFromAddressComponents(addressComponents) {
    let address = '';
    let routeComponent = addressComponents.find((component) => component.types.length && component.types.includes('route'));
    if (routeComponent) address += routeComponent.short_name + ', ';
    let streetNumberComponent = addressComponents.find((component) => component.types.length && component.types.includes('street_number'));
    if (streetNumberComponent) address += streetNumberComponent.short_name + ', ';
    let postalCodeComponent = addressComponents.find((component) => component.types.length && component.types.includes('postal_code'));
    if (postalCodeComponent) address += postalCodeComponent.short_name + ', ';
    // added another check for sublocality due to an issue with a specific address
    // "Kölner Str. 21,  50226 Frechen" selected but return "Kölner Str. 21,  50226 Lindenthal" 
    // so sublocallity is excluded from the result set
    let localityComponent = addressComponents.find((component) => component.types.length && ((component.types.includes('locality') || component.types.includes('political')) && !component.types.includes("sublocality")));
    if (!localityComponent) {
      // if cannot find such a value, use condition as it was before
      localityComponent = addressComponents.find((component) => component.types.length && (component.types.includes('locality') || component.types.includes('political')));
    }

    if (localityComponent) address += localityComponent.short_name;

    return address;
  }

  /**
   * 
   * @param addressIsValid validated Address component array
   * @returns valid or not (boolean)
   */
  public isAddressComponentValid(addressIsValid) {
    // addressIsValid['street_number'] === true && 
    return addressIsValid['route'] === true && addressIsValid['postal_code'] === true && addressIsValid['locality'] === true
  }

  /**
   * Will validate address component object of google response from google autocomplete 
   * @param addressComponents address component object returned from google api
   * @returns validated address array
   */
  public validateAddressComponents(addressComponents) {
    let addressIsValid = [];
    addressComponents.forEach(part => {

      // console.log(part);

      if (part.types[0]) {
        if (part.types[0] === "street_number") {
          addressIsValid['street_number'] = true;
        }
        if (part.types[0] === "route") {
          addressIsValid['route'] = true;
        }
        if (part.types[0] === "postal_code") {
          addressIsValid['postal_code'] = true;
        }
        if (part.types[0] === "locality" || (part.types[1] && part.types[1] === "political")) {
          addressIsValid['locality'] = true;
        }

      }
    });
    return addressIsValid;
  }

  /**
   * Find restaurant near by address
  */
  async findNearByRestaurants(pageToGo: any = 'restaurant-list', getAllRestaurants: boolean = false) {
    this.appObject.isLoading = true;
    var sending;
    if (this.appObject.myAddress.lat == '' || this.appObject.myAddress.lng == '' || getAllRestaurants) {
      sending = {
        'device_id': this.appObject.deviceUUID,
        'lang': this.appObject.currentLang,
        'delivery_method': this.appObject.selectedDeliveryMethod
      };
    } else {
      if (this.appObject.selectedDeliveryMethod == 'delivery' || (this.appObject.settings.is_franchise === 'true' && this.appObject.settings.app_configuration && this.appObject.settings.app_configuration.has_multiple_restaurants)) {
        sending = {
          'device_id': this.appObject.deviceUUID,
          'lang': this.appObject.currentLang,
          'latitude': this.appObject.myAddress.lat,
          'longitude': this.appObject.myAddress.lng,
          'delivery_method': this.appObject.selectedDeliveryMethod
        };
      } else {
        sending = {
          'device_id': this.appObject.deviceUUID,
          'lang': this.appObject.currentLang,
          'delivery_method': this.appObject.selectedDeliveryMethod
        };
      }

    }
    return await this.requestService.post('restaurant/list', sending).then(data => {
      if (data.status) {
        let restaurants = data.data;
        this.routeToRestaurantList = false;

        // get only selected ordering type is available restaurants
        let filteredRestaurants = restaurants;
        // .filter((restaurant) => {
        //   return restaurant.openDetails && restaurant.openDetails['is_' + this.appObject.selectedDeliveryMethod]
        // });
        // .filter((restaurant) => {
        //   return restaurant.openDetails && restaurant.openDetails['is_' + this.appObject.selectedDeliveryMethod]
        // });

        this.appObject.restaurants = filteredRestaurants;

        !this.isWebVersion && pageToGo && pageToGo !== 'branch-selected' && pageToGo !== 'do-not-redirect' ? this.router.navigate([pageToGo], { replaceUrl: true }) : '';

      } else {
        this.appObject.restaurants = [];
        this.updateAppObject();
        if (!this.isWebVersion && pageToGo !== 'branch-selected' && pageToGo !== 'do-not-redirect') this.router.navigate(['restaurant-list'], { replaceUrl: true });

      }
      this.appObject.isLoading = false;
    }, error => {
      this.appObject.isLoading = false;
    });

  }


  /**
   * This function will return all the actiuve restaurants
   * Specially made for postal code feature, So it can get all the delivery enabled restaurants
   * and filter by selected postal code
   */
  getAllRestaurants() {
    return new Promise((resolve, reject) => {
      let sending = {
        'device_id': this.appObject.deviceUUID,
        'lang': this.appObject.currentLang,
        'delivery_method': 'delivery'
      };
      this.requestService.post('restaurant/list', sending).then(data => {
        this.appObject.isLoading = false;
        return resolve(data);
      }, error => {
        return reject(error);
        this.appObject.isLoading = false;
      });
    });
  }

  /**
   * Find restaurants nearby user
   * @returns Promise
   */
  async getRestaurantListForFavourite() {
    let params = {
      'device_id': this.appObject.deviceUUID,
      'lang': this.appObject.currentLang,
      'latitude': this.appObject.myAddress.lat,
      'longitude': this.appObject.myAddress.lng,
    }
    setTimeout(() => {
      this.appObject.isLoading = true;
    }, 10);
    this.appObject.restaurants = [];
    return await this.requestService.post('restaurant/list', params).then(data => {
      if (data.status) {
        let restaurants = data.data;
        this.routeToRestaurantList = false;
        let filteredRestaurants = restaurants;
        this.appObject.restaurants = filteredRestaurants;
      }
      this.appObject.isLoading = false;
    }, error => {
      this.appObject.isLoading = false;
    });
  }

  /**
   * Get current location of user
   */

  async getCurrentLocation() {

    this.appObject.isLoading = true;
    let options = { maximumAge: 60000, timeout: 30000, enableHighAccuracy: true };

    var cpos = "";
    try {
      let Glocation = await this.geolocation.getCurrentPosition(options).catch((error) => {
        if (error && error.code === error.PERMISSION_DENIED) {
          this.translate.get(['key.location_access_denied', 'key.please_allow_location_permission_to_get_your_current_location']).toPromise().then((response) => {
            this.showAlert('error', response['key.location_access_denied'], response['key.please_allow_location_permission_to_get_your_current_location'], false);
          });
          this.appObject.isLoading = false;
          console.log('please allow location');
          // this.showAlert('error','Location access denied','Please allow location access permission');
        }
      });

      this.appObject.myAddress.lat = Glocation['coords']['latitude'];
      this.appObject.myAddress.lng = Glocation['coords']['longitude'];

      var postal = "";
      var city = "";
      var road = "";
      var homeno = "";

      var geocoder = new google.maps.Geocoder();
      var latlng = { lat: this.appObject.myAddress.lat, lng: this.appObject.myAddress.lng };
      geocoder.geocode({ 'location': latlng }, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {

          for (let i = 0; i < results[0]['address_components'].length; i++) {
            if (results[0]['address_components'][i]['types'][0] == "postal_code") {
              postal = results[0]['address_components'][i]['short_name'];
            }
            if (results[0]['address_components'][i]['types'][0] == "locality") {
              city = results[0]['address_components'][i]['short_name'];
            }
            if (results[0]['address_components'][i]['types'][0] == "route") {
              road = results[0]['address_components'][i]['short_name'];
            }
            // if (results[0]['address_components'][i]['types'][0] == "street_number") {
            //   homeno = results[0]['address_components'][i]['short_name'];
            // }
          }

          cpos = cpos + (road != "" ? road + ", " : "");
          // cpos = cpos + (homeno != "" ? homeno + ", " : "");
          cpos = cpos + (postal != "" ? postal + ", " : "");
          cpos = cpos + (city != "" ? city : "");


        }

      });


    } catch (error) {

    }

    setInterval(() => {
      if (cpos != "") {
        this.appObject.myAddress.name = cpos;
        this.appObject.isAddressHave = true;
        this.updateAppObject();
        cpos = "";
        this.appObject.isLoading = false;
        this.appObject.errors = [];

      }
    }, 1000);
  }

  /**
   * Get promotions
  */
  async getPromo() {

    return await this.requestService.post('promotion/list', {
      'device_id': this.appObject.deviceUUID,
      'lang': this.appObject.currentLang
    }).then(data => {
      if (data.status) {
        this.appObject.promotions = data.data;

        this.appObject.FooterCouponLink = '/my-products/' + this.appObject?.promotions?.resturent?.show_on_list[0]?.slug + '/category/' + this.appObject?.promotions?.category?.show_on_list[0]?.id + '/' + this.appObject?.promotions?.category?.show_on_list[0]?.name;

      }
    }, error => {
      this.appObject.isLoading = false;
    });

  }

  /**
   * Show languages
  */
  async showLanguage() {
    const modal = await this.modalController.create({
      component: PopupPage,
      cssClass: 'custom-popup',
      componentProps: {
        'currentUI': 'lang'
      }
    });

    await modal.present();
  }


  /**
   * Login
  */
  async login(login_details: any) {

    this.appObject.errors = [];
    login_details['lang'] = this.appObject.currentLang;
    login_details['device_id'] = this.appObject.deviceUUID;
    login_details['device_type'] = config.plf;


    return new Promise((resolve, reject) => {
      this.requestService.post('login', login_details).then(response => {
        if (response.status) {

          this.appObject.userId = response.data.id;
          this.appObject.isAuthed = true;
          this.appObject.deviceUUID = response.data.device_id;
          this.appObject.user.type = 'registered';

          // this.navigateToRegisterd();
        } else {
          this.appObject.errors = this.getErrorArray(response.msg);
        }
        return resolve(response);
      }, error => {
        this.appObject.isLoading = false;
        return reject(error);
      });
    })

  }

  /**
   * Logout
   * @param isWebVersion send a boolean to check web or app version
  */
  async logOut(isWebVersion: boolean = false) {
    return new Promise(async (resolve, reject) => {
      await this.requestService.post('logout', {
        'device_id': this.appObject.deviceUUID,
        'language': this.appObject.currentLang,
        'device_type': config.plf
      }).then(async (response) => {
        // console.log('logout response : ', response);
        if (response.status) {
          this.appObject.isAuthed = false;
          // reset user object to guest
          this.appObject.user.type = 'guest';
          this.appObject.user.backyard = "";
          this.appObject.user.email = "";
          this.appObject.user.fname = "";
          this.appObject.user.instructions = "";
          this.appObject.user.lname = "";
          this.appObject.user.telephone = "";

          this.appObject.userId = '';
          await this.deviceRegister();
          await this.updateAppObject();

          // only redirect in mobile app
          if (!isWebVersion) this.router.navigate(['/home'], { replaceUrl: true });

          return resolve(response);

        }
      }).catch((error) => {
        // console.log('error :  ', error);
        return reject(error);
      })
    })
  }


  /**
   * Register
  */

  async register(registration_details: any) {

    return new Promise((resolve, reject) => {
      this.appObject.errors = [];
      registration_details['lang'] = this.appObject.currentLang;
      registration_details['device_id'] = this.appObject.deviceUUID;
      this.requestService.post("register", registration_details).then(data => {
        if (data.status) {

          this.appObject.isAuthed = true;
          this.appObject.userId = data.data.id;
          this.appObject.deviceUUID = data.data.device_id;
          this.appObject.user.type = 'registered';
          if (!this.isWebVersion) this.navigateToRegisterd();

        } else {
          this.appObject.errors = this.getErrorArray(data.msg);
        }
        // console.log('registration_details : ', registration_details);
        if (registration_details.type === 'facebook' || registration_details.type === 'google' || registration_details.type === 'apple') {
          // console.log('Triggered');
          return resolve(data);
        }
        // console.log(this.appObject.errors);
      }, error => {
        this.appObject.isLoading = false;
        return reject(error);
      });
    });

  }


  async register_from_email(registration_details: any, isWebVersion: boolean = false) {
    this.registrationServerError = false;

    this.appObject.isLoading = true;
    this.appObject.errors = [];
    registration_details['lang'] = this.appObject.currentLang;
    registration_details['device_id'] = this.appObject.deviceUUID;
    return await this.requestService.post("register", registration_details).then(data => {
      // console.log('response : ', data)
      if (data.status) {

        this.appObject.isAuthed = true;
        this.appObject.userId = data.data.id;
        this.appObject.deviceUUID = data.data.device_id;
        this.appObject.user.type = 'registered';
        if (!isWebVersion) this.navigateToRegisterd();

      } else {
        this.appObject.errors = this.getErrorArray(data.msg);

        if (data.already_exists_mobile) {
          console.log('mobile number already exists');
        }
        // console.log('am called')
      }

      this.appObject.isLoading = false;

    }, error => {
      this.registrationServerError = true;
      this.appObject.isLoading = false;
    });

  }

  async navigateToRegisterd() {

    await this.updateAppObject();
    if (this.appObject.isOrderProcess) {
      this.router.navigate(['checkout']);
    } else {
      this.router.navigate(['profile']);

    }

  }


  getErrorArray(data) {
    let err = new Object();
    Object.keys(data).map(function (k) {

      if (typeof data[k][0] === 'object') {
        let e = new Object();
        let i = 0;
        Object.keys(data[k][0]).map(function (ke) {
          e[i] = data[k][0][ke];
          i++;
        });

        err[k] = e;

      } else {
        err[k] = { 0: data[k][0] };
      }

    });



    return err;

  }

  /**
  * Clear address
 */

  async clearAddress(clearByForce = '') {
    this.appObject.isAddressHave = false;
    this.appObject.myAddress.name = "";
    this.appObject.myAddress.lat = "";
    this.appObject.myAddress.lng = "";
    this.appObject.myAddress.streetNo = "";
    await this.updateAppObject();
    this.appObject.errors = {
      address: {
        0: clearByForce ? clearByForce : 'address_is_required'
      }
    };
  }

  async clearCart() {
    // console.log('clear cart called');
    this.appObject.orderObjectDup.cart = [];
    this.appObject.orderObject.cart = [];
    this.appObject.orderObject.cart_summery = {
      // cart_summery: {
      total_tax_inclusive: 0,
      total_tax_exclusive: 0,
      net_total_without_tax: 0,
      net_total: 0,
      total_discount: 0,
      total_with_discount_price: 0,
      delivery_cost: 0,
      gross_total: 0,
      min_reached: false,
      min_amount: 0,
      remarks: '',
      total_dish_count: 0,
      dish_net_total_with_discount: 0
      //  }
    };
    this.appObject.orderObject.selectedTable = null;
    this.appObject.orderObject.save_items = null;
    this.appObject.orderObject.table_order_id = null;

    this.appObject.selectedCoupon = [];
    this.appObject.couponWeb = '';
    this.appObject.couponWebAdded = false;
    // console.log('cart cleared');
    this.updateAppObject();
  }

  /**
  * Get restaurant by slug
 */

  async getRestaurantBySlug(slug, cat = null) {

    this.appObject.isLoading = true;

    // reset selected restaurant data and close notice data
    this.closenotice = false;
    this.isPreOrderAccept = false;
    this.appObject.selectedRestaurantDetails = null;
    this.menuList = [];

    if (this.appObject.deviceUUID == "") {
      setTimeout(() => {
        this.getRestaurantBySlug(slug);
      }, 100);
    } else {
      // console.log('called multiple times');
      await this.requestService.post('restaurant/' + slug, {
        'device_id': this.appObject.deviceUUID,
        'language': this.appObject.currentLang,
        'device_type': config.plf,
        'delivery_type': this.appObject.selectedDeliveryMethod,
        'lat': this.appObject.myAddress.lat,
        'lng': this.appObject.myAddress.lng
      }).then(data => {
        this.appObject.isLoading = false;

        if (data.status) {
          // console.log('this.appObject.selectedRestaurantId | ', this.appObject.selectedRestaurantId, '  |  data.data.id : ', data.data.id);
          if (this.appObject.selectedRestaurantId != data.data.id) {
            this.clearCart();
            // console.log('restaurant not equal');
            // console.log('Cleared Babe');
          }

          this.appObject.selectedRestaurant = slug;
          let payment_methods = JSON.parse(data.data['payment_methods']);
          this.appObject.selectedRestaurantDetails = data.data;

          this.appObject.selectedRestaurantId = this.appObject.selectedRestaurantDetails.id;

          this.appObject.selectedRestaurantDetails['payment_methods'] = [];
          // make points system turned off by default, if its enabled it will turn on below
          this.appObject.isPointsEnable = false;
          payment_methods.forEach(element => {

            if (element.isActive == "true") {

              let paymentMethodObj = {
                displayName: element.displayName,
                id: element.id,
                isActive: element.isActive,
                is_dine_in: element.is_dine_in,
                is_delivery: element.is_delivery,
                is_pickup: element.is_pickup,
              }

              // // get points payment method configurations
              // if (element.id === 'points' && element.pointsConfigurations) {
              //   paymentMethodObj['config'] = element.pointsConfigurations;
              // }
              // // get paypal payment method configurations
              // if (element.id === 'paypal' && element.paypalConfigurations) {
              //   paymentMethodObj['config'] = element.paypalConfigurations;
              // }

              if(element[element.id+'Configurations']){
                paymentMethodObj['config'] = element[element.id+'Configurations'];
              }

              this.appObject.selectedRestaurantDetails['payment_methods'].push(paymentMethodObj);

            }

            // find whether selected restaurant is support for point system
            if (element.id === 'points' && element.isActive === 'true') this.appObject.isPointsEnable = true;

          });

          if (this.appObject.selectedRestaurantDetails.openDetails.current_status == "closed" && !this.appObject.selectedRestaurantDetails.openDetails.is_pre_order) {
            this.closenotice = true;
          } else if (this.appObject.selectedRestaurantDetails.openDetails.current_status == "closed" && this.appObject.selectedRestaurantDetails.openDetails.is_pre_order) {
            this.isPreOrderAccept = true;
            this.closenotice = true;
          } else {
            this.closenotice = false;
          }

          this.appObject.selectedRestaurantId = data.data.id;
          this.selectedRestaurantCategories.restaurantId = this.appObject.selectedRestaurantId;

          // update if there is selected postal code detail with new pricing
          if (this.appObject.postalCodeDetails && this.appObject.postalCodeDetails.price) {
            let postalChargesOfSelectedRestaurant = this.appObject.postalCodeDetails.price.find((postalCharges) => postalCharges.restaurantId === this.appObject.selectedRestaurantDetails.id);
            let selectedPostalCodeNewDetails = this.appObject.selectedRestaurantDetails.postal_code_details_v2.find((postal) => postal.code === this.appObject.postalCodeDetails.code && postal.name === this.appObject.postalCodeDetails.name);

            if (postalChargesOfSelectedRestaurant && selectedPostalCodeNewDetails) postalChargesOfSelectedRestaurant.time_slots = selectedPostalCodeNewDetails.time_slots;
          }

          this.updateAppObject();
          this.getMenuDetails(cat);
        }
      }, error => {
        this.appObject.isLoading = false;
      });
    }

  }

  async getCouponPormo(slug) {
    return await this.requestService.post('restaurant/coupon-list/' + slug, {
      'device_id': this.appObject.deviceUUID,
      'language': this.appObject.currentLang,
      'device_type': config.plf,
    });
  }


  /**
  * Get menu details by restaurant_id
 */

  async getMenuDetails(cat = null) {
    this.appObject.isLoading = true;

    this.requestService.post('menu-category/list', {
      'device_id': this.appObject.deviceUUID,
      'language': this.appObject.currentLang,
      'device_type': config.plf,
      'restaurant_id': this.appObject.selectedRestaurantId,
      'is_dish': 'true'
    }).then(data => {
      if (data.status) {
        this.menuList = data.data;

        this.selectedRestaurantCategories.categories = [...this.menuList];

        // get dishes
        for (let i = 0; i < this.menuList.length; i++) {
          if (!this.menuList[i].dishes || !this.menuList[i].dishes.length) {
            this.get_dishes(i);
          }
        }

        // if (this.menuList.length > 0) {
        //   if (cat == null) {
        //     this.getDishDetails(this.menuList[0].id, this.menuList[0].restaurants_id);
        //   } else {
        //     this.getDishDetails(cat, cat.restaurants_id);
        //   }

        // } else {
        //   this.appObject.isLoading = false;
        // }
        this.appObject.isLoading = false;
      }
    }, error => {
      this.appObject.isLoading = false;
    });
  }

  async getDishDetails(cat_id, res_id) {

    //this.appObject.isLoading = true;
    this.activeMenu = cat_id;
    this.requestService.post('dish/list', {
      'device_id': this.appObject.deviceUUID,
      'language': this.appObject.currentLang,
      'device_type': config.plf,
      'menu_categorie_id': cat_id,
      'restaurant_id': 2
    }).then(data => {
      if (data.status) {
        this.dishList = data.data;
      }
      this.appObject.isLoading = false;
    }, error => {
      this.appObject.isLoading = false;
    });
  }

  async getPriceLabel(price) {

    let p = '';
    if (this.appObject.settings.currency_position == 'after') {
      p = price + ' ' + this.appObject.settings.currency_symbol;
    } else {
      p = this.appObject.settings.currency_symbol + ' ' + price;
    }
    // console.log(p);
    return p;
  }

  async goToOrder() {
    if (this.appObject.isAddressHave) {
      this.router.navigate(['/restaurant-list'], { replaceUrl: false });
    } else {
      this.routeToRestaurantList = true;
      this.router.navigate(['/find-near-by-me'], { replaceUrl: true });
    }
  }

  async calcSelectedDishTotal() {

    if (this.selectedDish.fullDishInfo.is_size == 'true') {

      this.selectedDish.total = this.selectedDish.fullDishInfo.sizes[this.selectedDish.size_index][this.appObject.selectedDeliveryMethod];

    } else {
      this.selectedDish.total = this.selectedDish.fullDishInfo[this.appObject.selectedDeliveryMethod];

    }

    this.selectedDish.toping_total = 0;

    if (this.selectedDish.selectedTopings.length > 0) {
      var topingTotal = 0;
      var dIshTotal = 0;
      dIshTotal += this.selectedDish.total;
      // console.log("this.selectedDish.selectedTopings  :",this.selectedDish.selectedTopings)
      for (let index = 0; index < this.selectedDish.selectedTopings.length; index++) {
        // to avoid expression change exception
        dIshTotal += this.selectedDish.selectedTopings[index].total;
        topingTotal += this.selectedDish.selectedTopings[index].total;
      }
      setTimeout(() => {
        this.selectedDish.toping_total = topingTotal;
        this.selectedDish.total = dIshTotal;
      }, 0.1);
    }

    // // console.log(this.selectedDish);
  }

  check_is_selected(t_id, is_mand, type) {
    let available = 0;
    this.selectedDish.selectedTopings.forEach(element => {
      if (t_id == element.topping_id) {
        available = element.count;
      }
    });

    if (available == 0 && is_mand == 'true' && type == "add-on") {
      this.appObject.errors['shoud_select_toping_' + t_id] = true;
      this.appObject.errors['mandatory_toping_should_be_selected'] = true;

    }
    return available;
  }

  async addSizeToCart(fromCrossSelling: boolean = false) {
    this.appObject.errors = [];
    // show alert when store is closed and preorder not accept
    if (this.closenotice && !this.isPreOrderAccept) {
      if (!this.isWebVersion) this.showAlert('error', 'key.restaurant_closed',
        this.appObject.selectedRestaurantDetails.openDetails.text ? this.appObject.selectedRestaurantDetails.openDetails.text : 'closeNote',
        false);
      else {
        this.translate.get('key.we_are_closed_at_the_moment_and_wouldnt_accept_any_orders').toPromise().then((data) => {
          this.showToast(data, 'danger', 'bottom', 5000);
        }).catch((err) => {
          console.log('error while fetching close notice : ', err);
        })
      }
      return;
    }
    // check the selected ordering method is available in selected restaurant 
    // if (this.appObject.selectedRestaurantDetails) {
    //   let availbaleDeliveryMethodsInRest: string[] = this.findSelectedRestaurantOrderTypes(this.appObject.selectedRestaurantDetails.openDetails, true);
    //   if (!availbaleDeliveryMethodsInRest.includes(this.appObject.selectedDeliveryMethod)) {
    //     this.translate.get('key.selected_ordering_method_not_available_at_the_moment').toPromise().then((data) => {
    //       this.showToast(data, 'danger', 'bottom', 5000);
    //     }).catch((err) => {
    //       console.log('error while fetching close notice : ', err);
    //     })
    //     return;
    //   }
    // }

    // console.log(this.selectedDish.fullDishInfo);
    //check total toping count if is_mandatory
    let error_on_dish = false;
    let selected_size_or_scenario = [];
    if (this.selectedDish.fullDishInfo.is_size == 'true') {
      if (this.selectedDish.size_index === null) {
        this.appObject.errors['size_select'] = 'please_select_a_size';
        return;
      }

      selected_size_or_scenario = this.selectedDish.fullDishInfo.sizes[this.selectedDish.size_index].scenarios;

    } else {
      selected_size_or_scenario = this.selectedDish.fullDishInfo.scenarios;


    }

    //check mandatory toping is selected
    /*for (let index = 0; index < selected_size_or_scenario.length; index++) {
      if(selected_size_or_scenario[index].is_mandatory == 'true'){
        this.check_is_selected(selected_size_or_scenario[index].id);
      }
    }*/

    if (selected_size_or_scenario) {
      try {
        //scenario
        selected_size_or_scenario.forEach(element => {

          let this_scenario_selected_count = 0;
          //toping
          element['topings'].forEach(elementt => {
            let selected_count = this.check_is_selected(elementt.id, elementt.is_mandatory, element.btn_type);
            // // console.log('zzz:', selected_count);
            this_scenario_selected_count += selected_count;

          });

          if (element.is_mandatory == 'true') {
            //validate by checking min and max
            if (element.btn_type == "optional") {
              //check min and max 
              if (!(element.min_toping_count <= this_scenario_selected_count && element.max_toping_count >= this_scenario_selected_count)) {
                this.appObject.errors['error_in_scenario_' + element.id] = true;
                error_on_dish = true;
              }
            } else {
              //only min
              if (!(element.min_toping_count <= this_scenario_selected_count)) {
                this.appObject.errors['error_in_scenario_' + element.id] = true;
                error_on_dish = true;
              }
            }
          }
          // // console.log('selected count', this_scenario_selected_count);

        });


      } catch (error) {

      }

    }


    if (this.appObject.errors['mandatory_toping_should_be_selected'] || error_on_dish) {
      this.appObject.errors['mandatory_toping_should_be_selected'] = true;
      return false;
    }


    if (this.editing_cart_item) {
      this.appObject.orderObject.cart.splice(this.editing_cart_item, 1);
      this.appObject.orderObjectDup.cart.splice(this.editing_cart_item, 1);
    }

    let dishDetails = [];
    dishDetails['dish_id'] = this.selectedDish.fullDishInfo.id;
    dishDetails['count'] = 1;

    if (this.selectedDish.fullDishInfo.is_size == 'true') {
      dishDetails['name'] = this.selectedDish.fullDishInfo.name + ' (' + this.selectedDish.fullDishInfo.sizes[this.selectedDish.size_index].name + ')';
      dishDetails['size_info'] = {
        size_id: this.selectedDish.size_id,
        topping_info: this.selectedDish.selectedTopings
      };
    } else {

      dishDetails['name'] = this.selectedDish.fullDishInfo.name;
      dishDetails['size_info'] = {
        size_id: this.selectedDish.size_id,
        topping_info: this.selectedDish.selectedTopings
      };
    }

    this.appObject.orderObject.cart.push({
      'dish_id': dishDetails['dish_id'],
      'count': dishDetails['count'],
      'name': dishDetails['name'],
      'size_info': dishDetails['size_info'],
      'points_per_dish': (this.appObject.isPointsEnable && this.selectedDish.fullDishInfo.points_per_dish) ? this.selectedDish.fullDishInfo.points_per_dish : 0
    });
    this.appObject.orderObjectDup.cart.push(this.selectedDish.fullDishInfo);

    await this.calculateCartAmounts();

    this.modalController.dismiss({
      'dismissed': true
    });

    if (this.cartIsOpen) {
      this.editing_cart_item = false;
      const modal = await this.modalController.create({
        component: CartPage,
        cssClass: 'custom-popup'
      });
      await modal.present();
    } else {
      this.animationDrop = true;
      this.className = 'animating'
      setTimeout(function () {
        this.className = '';
        this.animationDrop = false;
      }.bind(this), 1000);
      // if not from cross selling page, then allow to display cart icon
      if (!fromCrossSelling) this.hideCartButton = false;
    }

    // check if the process came from cross selling form, if it is then mark the question as answered
    // otherwise open cross selling popup if available on the selected dish
    let validQuestion = this.selectedDish.fullDishInfo && this.selectedDish.fullDishInfo.cross_selling_products && this.selectedDish.fullDishInfo.cross_selling_products.length ?
      this.selectedDish.fullDishInfo.cross_selling_products.find((csproduct) => csproduct.category.count > 0) : null;

    if (!fromCrossSelling && validQuestion && this.selectedDish.fullDishInfo && this.selectedDish.fullDishInfo.cross_selling_products && this.selectedDish.fullDishInfo.cross_selling_products.length) {
      if (!this.isWebVersion) {
        this.hideCartButton = true;
        const modal = await this.modalController.create({
          component: CrossSellingPage,
          cssClass: 'custom-popup',
          componentProps: {
            'crossSellingProducts': this.selectedDish.fullDishInfo.cross_selling_products,
            'selectedDishCategory': this.selectedDish.fullDishInfo.menu_categories_id.id,
            'title': this.selectedDish.fullDishInfo.name
          }
        });
        await modal.present();
      } else {
        const modal = await this.modalController.create({
          component: ModalPopoverPage,
          componentProps: {
            'type': this.POPOVER_TYPES.CROSS_SELLING.TYPE,
            'title': this.selectedDish.fullDishInfo.name,
            'crossSellingProducts': this.selectedDish.fullDishInfo.cross_selling_products,
            'selectedDishCategory': this.selectedDish.fullDishInfo.menu_categories_id.id,
          }
        });
        await modal.present();
      }
    } else if (fromCrossSelling) {
      this.watchAddToCartFromCrossSelling({ status: 'done' });
    }
  }

  /**
   * This function will validate topping senario configurations 
   * when its checked or even quantity change
   * 
   * Part of this function is copied from addSizeToCart function
   * implemented by Pumayk26 at 2024-04-23
   */
  validateDishSenarioToppingConfigsRealTime(){
    //check total toping count if is_mandatory
    let error_on_dish = false;
    let selected_size_or_scenario = [];
    if (this.selectedDish.fullDishInfo.is_size == 'true') {
      if (this.selectedDish.size_index === null) {
        return;
      }
      selected_size_or_scenario = this.selectedDish.fullDishInfo.sizes[this.selectedDish.size_index].scenarios;
    } else {
      selected_size_or_scenario = this.selectedDish.fullDishInfo.scenarios;
    }

    if (selected_size_or_scenario) {
      try {
        //scenario
        selected_size_or_scenario.forEach(element => {
          element.disable_rest = false;

          let this_scenario_selected_count = 0;
          //toping
          element['topings'].forEach(elementt => {
            let selected_count = this.check_is_selected(elementt.id, elementt.is_mandatory, element.btn_type);
            this_scenario_selected_count += selected_count;

          });

          if (element.is_mandatory == 'true') {
            this.appObject.errors['error_in_scenario_' + element.id] = false;
            if (element.btn_type == "optional") {
              if (this_scenario_selected_count === element.max_toping_count) {
                // this.appObject.errors['error_in_scenario_' + element.id] = true;
                element.disable_rest = true;
              }
            }
          }
          // // console.log('selected count', this_scenario_selected_count);
        });

      } catch (error) {
        console.log('error occoured while realtime topping cal : ', error);
      }

    }
  }

  async addToCart(dish, fromCrossSelling: boolean = false) {
    // // console.log('dish : ', dish);
    return new Promise(async (resolve, reject) => {
      if (this.closenotice && !this.isPreOrderAccept) {
        // console.log('Am Called.... : ', this.appObject.selectedRestaurantDetails.openDetails.is_pre_order);
        if (!this.isWebVersion) this.showAlert('error', 'key.restaurant_closed',
          this.appObject.selectedRestaurantDetails.openDetails.text ? this.appObject.selectedRestaurantDetails.openDetails.text : 'closeNote',
          false);
        else {
          this.translate.get('key.we_are_closed_at_the_moment_and_wouldnt_accept_any_orders').toPromise().then((data) => {
            this.showToast(data, 'danger', 'bottom', 5000);
          }).catch((err) => {
            console.log('error while fetching close notice : ', err);
          })
          // this.showToast('')
        }
        return;
      }

      // check the selected ordering method is available in selected restaurant 
      // if (this.appObject.selectedRestaurantDetails) {
      //   let availbaleDeliveryMethodsInRest: string[] = this.findSelectedRestaurantOrderTypes(this.appObject.selectedRestaurantDetails.openDetails, true);
      //   if (!availbaleDeliveryMethodsInRest.includes(this.appObject.selectedDeliveryMethod)) {
      //     this.translate.get('key.selected_ordering_method_not_available_at_the_moment').toPromise().then((data) => {
      //       this.showToast(data, 'danger', 'bottom', 5000);
      //     }).catch((err) => {
      //       console.log('error while fetching close notice : ', err);
      //     })
      //     return;
      //   }
      // }

      if (!this.isWebVersion) this.animationDrop = true;
      this.className = 'animating'
      setTimeout(function () {
        this.className = '';
        this.animationDrop = false;
      }.bind(this), 1000);


      let counted = false;
      let dishDetails = [];

      // only if bar mode is disable, so we can add same item separated into bill (then use going to pay we can collect each items and send as a single dish)
      // console.log('am called in here : ' , this.appObject.selectedRestaurantDetails.is_bar_mode , '  |  ', this.appObject.selectedDeliveryMethod);
      if (this.appObject.selectedRestaurantDetails && (this.appObject.selectedRestaurantDetails.is_bar_mode !== 'true' || this.appObject.selectedDeliveryMethod !== 'dine_in')) {
        //for each to check this already exit
        if (this.appObject?.orderObject?.cart.length > 0) {
          for (let index = 0; index < this.appObject.orderObject.cart.length; index++) {

            if (this.appObject.orderObject.cart[index].dish_id == dish.id && dish.is_size == 'false' && dish.is_customise == 'false') {
              counted = true;
              //change count
              this.appObject.orderObject.cart[index].count += 1;
              if (this.appObject.isPointsEnable && dish.points_per_dish) this.appObject.orderObject.cart[index].points_per_dish = (dish.points_per_dish * this.appObject.orderObject.cart[index].count);

            }

          }

        }
      }

      if (!counted) {
        dishDetails['points_per_dish'] = (this.appObject.isPointsEnable && dish.points_per_dish) ? dish.points_per_dish : 0;
        dishDetails['dish_id'] = dish.id;
        dishDetails['count'] = 1;
        dishDetails['name'] = dish.name;
        this.appObject.orderObject.cart.push({
          'dish_id': dishDetails['dish_id'],
          'count': dishDetails['count'],
          'name': dishDetails['name'],
          'points_per_dish': dishDetails['points_per_dish']
        });

        this.appObject.orderObjectDup.cart.push(dish);

      }


      await this.calculateCartAmounts();

      // check if the user came from crossselling form, if not open to select cross selling product
      let validQuestion = dish && dish.cross_selling_products && dish.cross_selling_products.length ?
        dish.cross_selling_products.find((csproduct) => csproduct.category.count > 0) : null;

      if (!fromCrossSelling && validQuestion && dish && dish.cross_selling_products && dish.cross_selling_products.length) {
        if (!this.isWebVersion) {
          this.hideCartButton = true;
          const modal = await this.modalController.create({
            component: CrossSellingPage,
            cssClass: 'custom-popup',
            componentProps: {
              'crossSellingProducts': dish.cross_selling_products,
              'selectedDishCategory': dish.menu_categories_id.id,
              'title': dish.name
            }
          });
          await modal.present();
        } else {
          const modal = await this.modalController.create({
            component: ModalPopoverPage,
            componentProps: {
              'type': this.POPOVER_TYPES.CROSS_SELLING.TYPE,
              'title': dish.name,
              'crossSellingProducts': dish.cross_selling_products,
              'selectedDishCategory': dish.menu_categories_id.id,
            }
          });
          await modal.present();
        }
      } else if (fromCrossSelling) {
        return resolve({ status: 'done' })
      }
    })

  }

  async setSelectedSize(index) {
    if (index?.detail?.value) {
      index = index.detail.value;
    }
    this.selectedDish.selectedTopings = [];
    this.selectedDish.size_index = index;
    this.selectedDish.size_id = this.selectedDish.fullDishInfo.sizes[index].id;

    this.calcSelectedDishTotal();
  }

  async topingInc(evt, toping, sc, t) {
    let s = this.selectedDish.size_index;
    for (let index = 0; index < this.selectedDish.selectedTopings.length; index++) {
      if (this.selectedDish.selectedTopings[index].topping_id == toping.id) {
        this.selectedDish.selectedTopings[index].count += 1;
        this.selectedDish.selectedTopings[index].total = this.selectedDish.selectedTopings[index].price * this.selectedDish.selectedTopings[index].count;
        toping.count_selected++;

        this.validateDishSenarioToppingConfigsRealTime();
      }
    }

    this.calcSelectedDishTotal();
  }

  async topingDec(evt, toping, sc, t) {
    let s = this.selectedDish.size_index;
    for (let index = 0; index < this.selectedDish.selectedTopings.length; index++) {
      if (this.selectedDish.selectedTopings[index].topping_id == toping.id) {
        if (this.selectedDish.selectedTopings[index].count > 1) {
          this.selectedDish.selectedTopings[index].count -= 1;
          this.selectedDish.selectedTopings[index].total = this.selectedDish.selectedTopings[index].price * this.selectedDish.selectedTopings[index].count;
          toping.count_selected--;

          this.validateDishSenarioToppingConfigsRealTime();
        }

      }
    }

    this.calcSelectedDishTotal();

  }

  /**
   * Oldfunction modified to use scenario object instead of indexes
   * @param evt select event
   * @param sc scenario object
   */
  async selectTopingOp2(evt, sc) {
    // console.log('hereeee', evt);
    // console.log('sc : ' , sc);
    let s = this.selectedDish.size_index;
    let toping = [];
    let found = false;
    //check this optional group exist in the selected array, if remove it or add
    // let tt_each = [];
    // if (this.selectedDish.fullDishInfo.is_size == 'true') {
    //   tt_each = sc.topings;
    // } else {
    //   tt_each = sc.topings;
    // }

    let tt_each = sc.topings;



    let type = 'checkbox';
    if (typeof evt.detail.checked === 'undefined') {
      type = 'dropdown';

    }
    // console.log('tt_each : ' , tt_each);
    for (let findex = 0; findex < tt_each.length; findex++) {

      if (type == 'dropdown') {
        let top_id = tt_each[findex].id;
        for (let index = 0; index < this.selectedDish.selectedTopings.length; index++) {
          // console.log('found val', this.selectedDish.selectedTopings[index].topping_id);
          if (top_id == this.selectedDish.selectedTopings[index].topping_id) {
            //exist and remove 
            // console.log('found and removed');
            this.selectedDish.selectedTopings.splice(index, 1);
            found = true;

          }

        }

      }

      if (tt_each[findex].id == evt.detail.value) {
        if (this.selectedDish.fullDishInfo.is_size == 'true') {
          toping = sc.topings[findex];

        } else {
          toping = sc.topings[findex];

        }
      }


    }

    if (!evt.detail.checked) {
      //remove toping
      for (let index = 0; index < this.selectedDish.selectedTopings.length; index++) {
        if (evt.detail.value == this.selectedDish.selectedTopings[index].topping_id) {
          //exist and remove 
          this.selectedDish.selectedTopings.splice(index, 1);
          found = true;

        }

      }

      // console.log('selected topings : ' , this.selectedDish.selectedTopings);
    }

    if (evt.detail.checked || type == 'dropdown') {
      let top = [];
      top['topping_id'] = toping['id'];
      top['count'] = 1;
      top['price'] = toping[this.appObject.selectedDeliveryMethod];

      //for future use
      top['delivery'] = toping['delivery'];
      top['pickup'] = toping['pickup'];
      top['dine_in'] = toping['dine_in'];
      top['name'] = toping['name'];

      //end for future use

      // console.log('top : ' , top);

      // if(top['price'] > 0){
      //   top['total'] = top['count'] * top['price'];
      // }else{
      //   top['total'] = 0;
      // }

      top['total'] = (top['count'] > 0 && top['price'] > 0) ? top['count'] * top['price'] : 0;

      // console.log('top[topping_id] : ' , toping);

      this.selectedDish.selectedTopings.push({
        'topping_id': top['topping_id'],
        'count': top['count'],
        'price': top['price'],
        'delivery': top['delivery'],
        'pickup': top['pickup'],
        'dine_in': top['dine_in'],
        'total': top['total'],
        'name': top['name'],
        'senario_position': sc.position,
        'topping_position': toping['position'],
        'topping_tax': sc.toppingTax,
      });

      this.selectedDish.selectedTopings.sort((a, b) => {
        // return +(a['senario_position'] + '' + a['topping_position']) - +(b['senario_position'] + '' + b['topping_position']);
        if (a.senario_position === b.senario_position) {
          return a.topping_position - b.topping_position;
        }
        return a.senario_position - b.senario_position;
      });
    }
    // console.log('selected', this.selectedDish.selectedTopings);
    this.calcSelectedDishTotal();

  }

  async selectTopingOp(evt, sc) {
    // console.log('hereeee', evt);
    let s = this.selectedDish.size_index;
    let toping = [];
    let found = false;
    //check this optional group exist in the selected array, if remove it or add
    let tt_each = [];
    if (this.selectedDish.fullDishInfo.is_size == 'true') {
      tt_each = this.selectedDish.fullDishInfo.sizes[s].scenarios[sc].topings;
    } else {
      tt_each = this.selectedDish.fullDishInfo.scenarios[sc].topings;
    }

    let type = 'checkbox';
    if (typeof evt.detail.checked === 'undefined') {
      type = 'dropdown';

    }

    for (let findex = 0; findex < tt_each.length; findex++) {

      if (type == 'dropdown') {
        let top_id = tt_each[findex].id;
        for (let index = 0; index < this.selectedDish.selectedTopings.length; index++) {
          // console.log('found val', this.selectedDish.selectedTopings[index].topping_id);
          if (top_id == this.selectedDish.selectedTopings[index].topping_id) {
            //exist and remove 
            // console.log('found and removed');
            this.selectedDish.selectedTopings.splice(index, 1);
            found = true;

          }

        }

      }

      if (tt_each[findex].id == evt.detail.value) {
        if (this.selectedDish.fullDishInfo.is_size == 'true') {
          toping = this.selectedDish.fullDishInfo.sizes[s].scenarios[sc].topings[findex];

        } else {
          toping = this.selectedDish.fullDishInfo.scenarios[sc].topings[findex];

        }
      }


    }

    if (!evt.detail.checked) {
      //remove toping
      for (let index = 0; index < this.selectedDish.selectedTopings.length; index++) {
        if (evt.detail.value == this.selectedDish.selectedTopings[index].topping_id) {
          //exist and remove 
          this.selectedDish.selectedTopings.splice(index, 1);
          found = true;

        }

      }
    }

    if (evt.detail.checked || type == 'dropdown') {
      let top = [];
      top['topping_id'] = toping['id'];
      top['count'] = 1;
      top['price'] = toping[this.appObject.selectedDeliveryMethod];

      //for future use
      top['delivery'] = toping['delivery'];
      top['pickup'] = toping['pickup'];
      top['dine_in'] = toping['dine_in'];
      top['name'] = toping['name'];

      //end for future use

      this.selectedDish.selectedTopings.push({
        'topping_id': top['topping_id'],
        'count': top['count'],
        'price': top['price'],
        'delivery': top['delivery'],
        'pickup': top['pickup'],
        'dine_in': top['dine_in'],
        'total': top['total'],
        'name': top['name'],
        'senario_position': sc.position,
        'topping_position': toping['position'],
        'topping_tax': sc.toppingTax,
      });

      this.selectedDish.selectedTopings.sort((a, b) => {
        // return +(a['senario_position'] + '' + a['topping_position']) - +(b['senario_position'] + '' + b['topping_position']);
        if (a.senario_position === b.senario_position) {
          return a.topping_position - b.topping_position;
        }
        return a.senario_position - b.senario_position;
      });
    }
    // console.log('selected', this.selectedDish.selectedTopings);
    this.calcSelectedDishTotal();

  }

  /**
   * COPIED FROM TOPPINGS PAGE by Pumayk26 at 2024-04-23 in order to fix an issue with topping select lists
 * Will return status active topping list
 * @param toppingList topping list to filter from
 * @returns filtered topping list that status is active
 */
  getActiveToppings(toppingList) {
    if (toppingList && toppingList.length) {
      return toppingList.filter((topping) => {
        return topping.status === 'true'
      });
    } else {
      return [];
    }
  }

  async selectTopingAddon(evt, toping, sc, t) {
    // console.log('aaa:', sc);
    let s = this.selectedDish.size_index;

    // get only active toppings of the senario coz in the topping select list index get from the active toppings
    // the "t" (topping index) is not compatible with "sc.topings" array
    let activeToppings = this.getActiveToppings(sc.topings)

    if (
      (this.selectedDish.fullDishInfo.is_size == 'true' && (typeof activeToppings[t].checked === 'undefined' ||
        !activeToppings[t].checked))
      ||
      (this.selectedDish.fullDishInfo.is_customise == 'true' && (typeof activeToppings[t].checked === 'undefined' ||
        !activeToppings[t].checked))

    ) {

      //add toping
      let found = false;
      for (let index = 0; index < this.selectedDish.selectedTopings.length; index++) {
        if (toping.id == this.selectedDish.selectedTopings[index].topping_id) {

          found = true;

        }
      }

      if (!found) {
        let top = [];
        top['topping_id'] = toping.id;
        top['count'] = 1;
        top['price'] = toping[this.appObject.selectedDeliveryMethod];

        //for future use
        top['delivery'] = toping['delivery'];
        top['pickup'] = toping['pickup'];
        top['dine_in'] = toping['dine_in'];
        top['name'] = toping['name'];

        //end for future use

        top['total'] = top['count'] * top['price'];
        if (this.selectedDish.fullDishInfo.is_size == 'true') {
          activeToppings[t].checked = true;
        } else {
          activeToppings[t].checked = true;
        }
        toping.count_selected = 1;
        this.selectedDish.selectedTopings.push({
          'topping_id': top['topping_id'],
          'count': top['count'],
          'price': top['price'],
          'delivery': top['delivery'],
          'pickup': top['pickup'],
          'dine_in': top['dine_in'],
          'total': top['total'],
          'name': top['name'],
          'senario_position': sc.position,
          'topping_position': toping.position,
          'topping_tax': sc.toppingTax,
        });

        this.selectedDish.selectedTopings.sort((a, b) => {
          // return +(a['senario_position'] + '' + a['topping_position']) - +(b['senario_position'] + '' + b['topping_position']);
          if (a.senario_position === b.senario_position) {
            return a.topping_position - b.topping_position;
          }
          return a.senario_position - b.senario_position;
        });
        // console.log('ads : ' , ads);
      }

    } else {

      //remove toping
      for (let index = 0; index < this.selectedDish.selectedTopings.length; index++) {
        if (toping.id == this.selectedDish.selectedTopings[index].topping_id) {
          //delete

          this.selectedDish.selectedTopings.splice(index, 1);
          if (this.selectedDish.fullDishInfo.is_size == 'true') {
            activeToppings[t].checked = false;

          } else {
            activeToppings[t].checked = false;

          }


        }
      }
    }

    this.validateDishSenarioToppingConfigsRealTime();

    this.calcSelectedDishTotal();

  }



  async topingPopupOpen(dish, isFromCrossSelling: boolean = false) {

    dish.progressing = true;

    if (dish.is_size == 'true') {
      this.selectedDish.size_id = dish.sizes[0].id;
    } else {
      this.selectedDish.size_id = null;
    }

    this.selectedDish.selectedTopings = [];
    this.selectedDish.fullDishInfo = JSON.parse(JSON.stringify(dish));

    // if selected restaurant settings avoid size auto select is enabled then dont set a size index
    if (this.appObject.selectedRestaurantDetails.is_size_auto_select === 'true') {
      this.selectedDish.size_index = null;
      // make the price of selected dish to 0, otherwise the add to cart button price 
      // will display the previously selected dish total value
      // bug fixed 2023-01-21 by Pumayk26
      this.selectedDish.total = 0;

    } else this.selectedDish.size_index = 0;

    if (this.selectedDish.size_index !== null) this.calcSelectedDishTotal();

    if (!this.isWebVersion) {
      const modal = await this.modalController.create({
        component: ToppingsPage,
        cssClass: 'custom-popup',
        componentProps: {
          'isFromCrossSelling': isFromCrossSelling
        }
      });

      await modal.present();
    } else {
      const modal = await this.modalController.create({
        component: ModalPopoverPage,
        componentProps: {
          'type': this.POPOVER_TYPES.TOPPINGS.TYPE,
          'title': dish.name,
          'isFromCrossSelling': isFromCrossSelling
        }
      });
      await modal.present();

      modal.onDidDismiss().then((data) => {
        dish.progressing = false;
      });
    }

  }

  async showAlert(type, title = (type === 'success' ? 'Success' : 'Error'), message, autoClose = true) {
    const modal = await this.modalController.create({
      component: AlertPage,
      cssClass: 'custom-popup',
      componentProps: {
        'type': type,
        'message': message,
        'autoClose': autoClose,
        'title': title
      }
    });

    await modal.present();
  }

  async setDeliveryMethod(method, orderProcess = false) {
    this.appObject.selectedDeliveryMethod = method;

    if (method == 'delivery') {

      if (orderProcess) {
        this.router.navigate(['find-near-by-me'], {
          queryParams: {
            isOrderProcess: orderProcess
          }
        });
      } else {
        this.router.navigate(['find-near-by-me']);
      }


    } else {


      if (orderProcess) {
        await this.calculateCartAmounts();
        await this.updateAppObject();
        this.routeToSelectedRestaurant();
      } else {
        this.router.navigate(['restaurant-list']);
      }
    }

  }

  async loginOrCheckout() {
    await this.calculateCartAmounts();
    await this.updateAppObject();
    if (this.appObject.isAuthed) {
      this.router.navigateByUrl('checkout');
    } else {
      this.router.navigate(['login'], {
        queryParams: {
          isOrderProcess: true
        }
      });
    }
  }

  async calculateCartAmountsV2() {
    return new Promise(async (resolve, reject) => {
      let dishTotalTaxExclusive = 0;
      let dishTotalTaxInclusive = 0;
      let dishNetTotal = 0;
      let dishNetTotalWithDiscount = 0;
      let dishNetTotalWithoutTax = 0;
      let dishTotalDiscount = 0;
      let totalDishCount = 0;
      let deliveryTaxInclusive = 0;
      let deliveryTaxExclusive = 0;

      // ------- Topping tax feature
      // _v2 variables added with topping tax feature
      let dishTotalDiscount_v2 = 0;
      let dishToppingTotalDiscount_v2 = 0;
      let dishNetTotal_v2 = 0;
      let dishToppingNetTotal_v2 = 0;
      let dishTotalTaxExclusive_v2 = 0;
      let dishTotalTaxInclusive_v2 = 0;
      let dishToppingTotalTaxExclusive_v2 = 0;
      let dishToppingTotalTaxInclusive_v2 = 0;
      let orderDeliveryTaxExclusive_v2 = 0;
      let orderDeliveryTaxInclusive_v2 = 0;
      // ------- 
      
      let orderMainDiscountOb = null;

      // will check this value with restaurant min order amount 
      let totalForMinOrderAmount = 0;

      // these deliveryTaxHelper is to calculate tax for the delivery cost
      // will be used in few places,
      // the new way to calculate this delivery tax is as below,
      // Find the most valuable item in the cart (x cost 10 and qty is 1, y cost 5 and qty is 3, so the most valuble one is y because qty is 3 and it cost 15 so we take the tax of dish y)
      let deliveryCostAvailable = false;
      if (this.appObject?.orderObject?.cart_summery?.delivery_cost > 0) deliveryCostAvailable = true;

      let deliveryTaxHelper = {
        maximumAmountDish: null,
        tax: null,
        deliveryTax: 0
      };

      // get order taxes array filled up
      // we need all taxes in dishes as a summary
      // all 7% included, all 7% excluded like that
      let taxes = [];
      let taxes_v2 = [];

      if (this.appObject?.orderObject?.cart.length > 0) {

        let dailySpecialAvailability = this.checkDailySpecialDiscountEligibility([...this.appObject.orderObject.cart]);

        for (let index = 0; index < this.appObject.orderObject.cart.length; index++) {
          let dish = this.appObject.orderObject.cart[index];

          // _v2 variables added with topping tax feature
          dish.net_total_v2 = 0;
          dish.net_topping_total_v2 = 0
          dish.discounted_amount_v2 = 0;
          dish.total_discount_v2 = 0;

          // findout dish category for future uses 
          let dishesCategory = this.selectedRestaurantCategories.categories.find((category) => category.id === this.appObject.orderObjectDup.cart[index].menu_categories_id.id);

          // check dishes are available for current ordering type
          dish.not_available = false;
          if (dishesCategory && dishesCategory['disabled_for_' + this.appObject.selectedDeliveryMethod] === 'true') dish.not_available = true;


          // clear previously calculated data
          dish.discount = [];
          dish.total_discount = 0;
          dish.tax = [];
          dish.tax_v2 = [];
          // total dish count of the order
          totalDishCount += dish.count;

          // calculate basic dish amounts
          if (this.appObject.orderObjectDup.cart[index].is_customise == 'false' && this.appObject.orderObjectDup.cart[index].is_size == 'false') {
            dish.dish_price = this.appObject.orderObjectDup.cart[index][this.appObject.selectedDeliveryMethod];
            dish.total = dish.dish_price;
            dish.net_total = dish.dish_price * dish.count;

            // for topping tax
            dish.net_total_v2 = dish.dish_price * dish.count;
          }

          // calculations for toppings / if the dish is customizable
          if (this.appObject.orderObjectDup.cart[index].is_customise == 'true' && dish.size_info) {
            dish.dish_price = this.appObject.orderObjectDup.cart[index][this.appObject.selectedDeliveryMethod];

            //TOPING FOREACH // Topping calculatons 
            let sctoping_total = 0;
            for (let stindex = 0; stindex < dish.size_info.topping_info.length; stindex++) {

              dish.size_info.topping_info[stindex]['price'] = dish.size_info.topping_info[stindex][this.appObject.selectedDeliveryMethod];
              dish.size_info.topping_info[stindex]['total'] = dish.size_info.topping_info[stindex]['price'] * dish.size_info.topping_info[stindex]['count'];

              sctoping_total += dish.size_info.topping_info[stindex]['total'];

            }

            dish['topping_price'] = sctoping_total;
            dish['total'] = dish['dish_price'] + dish['topping_price'];
            dish.net_total = dish.total * dish['count'];

            // for topping tax
            dish.net_topping_total_v2 += sctoping_total
            dish.net_total_v2 = dish.dish_price * dish['count'];

          }

          // Check if the dish has sizes enabled, diffrent sizes has diffrent prices..
          // sizes calculations goes here
          if (this.appObject.orderObjectDup.cart[index].is_size == 'true') {

            //for each to find correct size values
            for (let sindex = 0; sindex < this.appObject.orderObjectDup.cart[index].sizes.length; sindex++) {
              if (dish.size_info && dish.size_info.size_id == this.appObject.orderObjectDup.cart[index].sizes[sindex].id) {
                //this is correct size
                //re get size value
                dish['dish_price'] = this.appObject.orderObjectDup.cart[index].sizes[sindex][this.appObject.selectedDeliveryMethod];

                //TOPING FOREACH
                let toping_total = 0;
                for (let stindex = 0; stindex < dish.size_info.topping_info.length; stindex++) {

                  dish.size_info.topping_info[stindex]['price'] = dish.size_info.topping_info[stindex][this.appObject.selectedDeliveryMethod];
                  dish.size_info.topping_info[stindex]['total'] = dish.size_info.topping_info[stindex]['price'] * dish.size_info.topping_info[stindex]['count'];

                  toping_total += dish.size_info.topping_info[stindex]['total'];

                }

                //END TOPING
                dish['topping_price'] = toping_total;
                dish['total'] = dish['dish_price'] + dish['topping_price'];
                dish['net_total'] = dish['total'] * dish['count'];

                // for topping tax
                dish.net_total_v2 = dish.dish_price * dish['count'];
                dish.net_topping_total_v2 += toping_total;
              }
            }

          }

          dish.total_discount = 0;
          // as the default discounted amount
          dish.discounted_amount = dish.net_total;

          let minOrderTotalClaAmountForThisDish = 0;
          let finalDishNetTotalWithDiscount = 0;

          // calculate discounts, if dish category has discounts enabled
          if (dishesCategory && dishesCategory.discounts_disabled !== 'true') {

            // only one discount can be applied for an order, so to check that use this variable
            let appliedAnyDiscount: boolean = false;

            /*if (this.appObject.selectedCoupon.length) {
              // oscoup['discount_id'] = this.appObject.selectedCoupon[0].id;
              // oscoup['amount'] = this.appObject.selectedCoupon[0].amount;
              // oscoup['type'] = this.appObject.selectedCoupon[0].apply_as;
              // oscoup['coupon_code'] = this.appObject.selectedCoupon[0].coupon_code;
              // oscoup['name'] = 

              let disc = [];
              disc['discount_id'] = this.appObject.selectedCoupon[0].id;
              disc['amount'] = this.appObject.selectedCoupon[0].amount;
              disc['type'] = this.appObject.selectedCoupon[0].apply_as;
              disc['name'] = this.appObject.selectedCoupon[0].title;
              disc['coupon'] = this.appObject.selectedCoupon[0].coupon_code;


              // let discountCalculations = await this.getDiscount(dish.gross_total, disc['type'], disc['amount']);
              let discountCalculations = await this.getDiscount(dish.net_total, disc['type'], disc['amount']);

              if (disc['type'] == "fixed") {
                discountCalculations['dis_amoount'] = discountCalculations['dis_amoount'] * dish['count'];
              }

              dish.discounted_amount = dish.net_total - discountCalculations['dis_amoount'];

              // dishNetTotalWithDiscount += dish.discounted_amount;
              finalDishNetTotalWithDiscount = dish.discounted_amount;

              // if the menu category has excluded from getting calculated for min order amount, this won't calculate for min order amount check
              // if (dishesCategory.exclude_min_order_amount_cal !== 'true') totalForMinOrderAmount += dish.discounted_amount;
              minOrderTotalClaAmountForThisDish = dish.discounted_amount;

              disc['discount_amount'] = discountCalculations['dis_amoount'];

              dish.total_discount += discountCalculations['dis_amoount'];
              dishTotalDiscount += discountCalculations['dis_amoount'];
              dish.discount.push({
                'discount_id': disc['discount_id'],
                'amount': disc['amount'],
                'type': disc['type'],
                'name': disc['name'],
                'discount_amount': disc['discount_amount'],
                'coupon': disc['coupon']
              });

              appliedAnyDiscount = true;

            }*/


            // 1st Discounts available for menu category
            if (!appliedAnyDiscount && this.appObject.orderObjectDup.cart[index]?.discounts?.length > 0) {
              for (let dindex = 0; dindex < this.appObject.orderObjectDup.cart[index].discounts.length; dindex++) {
                if (this.appObject.orderObjectDup.cart[index].discounts[dindex].apply_platform == 'both' || this.appObject.orderObjectDup.cart[index].discounts[dindex].apply_platform == config.plf) {
                  let disc = [];
                  disc['discount_id'] = this.appObject.orderObjectDup.cart[index].discounts[dindex].id;
                  disc['amount'] = this.appObject.orderObjectDup.cart[index].discounts[dindex].amount;
                  disc['type'] = this.appObject.orderObjectDup.cart[index].discounts[dindex].apply_as;
                  disc['name'] = this.appObject.orderObjectDup.cart[index].discounts[dindex].title;


                  // let discountCalculations = await this.getDiscount(dish.gross_total, disc['type'], disc['amount']);
                  let discountCalculations = await this.getDiscount(dish.net_total, disc['type'], disc['amount']);

                  if (disc['type'] == "fixed") {
                    discountCalculations['dis_amoount'] = discountCalculations['dis_amoount'] * dish['count'];
                  }

                  // implemented v2 with topping tax calculation feature
                  let discountCalculations_v2 = await this.getDiscount(dish.net_total_v2, disc['type'], disc['amount']);
                  dish.discounted_amount_v2 = dish.net_total_v2 - discountCalculations_v2['dis_amoount'];
                  dishTotalDiscount_v2 += discountCalculations_v2['dis_amoount'];
                  dish.total_discount_v2 += discountCalculations_v2['dis_amoount'];
                  // ------

                  dish.discounted_amount = dish.net_total - discountCalculations['dis_amoount'];

                  // dishNetTotalWithDiscount += dish.discounted_amount;
                  finalDishNetTotalWithDiscount = dish.discounted_amount;

                  // if the menu category has excluded from getting calculated for min order amount, this won't calculate for min order amount check
                  // if (dishesCategory.exclude_min_order_amount_cal !== 'true') totalForMinOrderAmount += dish.discounted_amount;
                  minOrderTotalClaAmountForThisDish = dish.discounted_amount;

                  disc['discount_amount'] = discountCalculations['dis_amoount'];

                  dish.total_discount += discountCalculations['dis_amoount'];
                  dishTotalDiscount += discountCalculations['dis_amoount'];
                  dish.discount.push({
                    'discount_id': disc['discount_id'],
                    'amount': disc['amount'],
                    'type': disc['type'],
                    'name': disc['name'],
                    'discount_amount': disc['discount_amount']
                  });

                  appliedAnyDiscount = true;
                } else {
                  minOrderTotalClaAmountForThisDish = dish.net_total;

                  finalDishNetTotalWithDiscount = dish.net_total;
                }

              }
            }

            // daily special discounts
            if (!appliedAnyDiscount && this.appObject.selectedRestaurantDetails && this.appObject.selectedRestaurantDetails.discountRestaurant && this.appObject.selectedRestaurantDetails.discountRestaurant.isActive && this.appObject.selectedRestaurantDetails.discountRestaurant.isActive == "true") {
              let apply = dailySpecialAvailability.apply;
              let amount = dailySpecialAvailability.amount;
              // console.log('amount : ' , amount && apply , ' | ' , amount);
              if (amount > 0 && apply) {
                //check is reached minimum details
                // if (this.appObject.selectedRestaurantDetails && this.appObject.selectedRestaurantDetails.discountRestaurant.minimumDishes <= totalDishCount && dish.net_total >= this.appObject.selectedRestaurantDetails.discountRestaurant.minimumAmount) {
                if (dailySpecialAvailability.eligible) {
                  let olddis = [];
                  olddis['amount'] = amount;
                  olddis['type'] = 'percentage';
                  olddis['name'] = this.appObject.selectedRestaurantDetails.discountRestaurant.displayText;

                  let ldiscountCalculations = await this.getDiscount(dish.net_total, olddis['type'], olddis['amount']);
                  olddis['discount_amount'] = ldiscountCalculations['dis_amoount'];
                  // console.log('Toda : ' , olddis);
                  // _ototal_discount += olddis['discount_amount'];
                  dish.total_discount += olddis['discount_amount'];
                  dishTotalDiscount += olddis['discount_amount'];
                  dish.discounted_amount = dish.net_total - olddis['discount_amount'];

                  // implemented v2 with topping tax calculation feature
                  let discountCalculations_v2 = await this.getDiscount(dish.net_total_v2, olddis['type'], olddis['amount']);
                  dish.discounted_amount_v2 = dish.net_total_v2 - discountCalculations_v2['dis_amoount'];
                  dishTotalDiscount_v2 += discountCalculations_v2['dis_amoount'];
                  dish.total_discount_v2 += discountCalculations_v2['dis_amoount'];
                  // ------
                  
                  // dishNetTotalWithDiscount += dish.discounted_amount;
                  finalDishNetTotalWithDiscount = dish.discounted_amount;

                  // if the menu category has excluded from getting calculated for min order amount, this won't calculate for min order amount check
                  // if (dishesCategory.exclude_min_order_amount_cal !== 'true') totalForMinOrderAmount += dish.discounted_amount;
                  minOrderTotalClaAmountForThisDish = dish.discounted_amount;

                  // this.appObject.orderObject.discounts.push({
                  //   'amount': olddis['amount'],
                  //   'type': olddis['type'],
                  //   'name': olddis['name'],
                  //   'discount_amount': olddis['discount_amount']
                  // });

                  let discount = {
                    'amount': olddis['amount'],
                    'type': olddis['type'],
                    'name': olddis['name'],
                    'discount_amount': olddis['discount_amount'],
                    'is_daily_discount': 'true'
                  };

                  dish.discount.push(discount);

                  orderMainDiscountOb = discount;
                  appliedAnyDiscount = true;
                } else {
                  // if the menu category has excluded from getting calculated for min order amount, this won't calculate for min order amount check
                  // if (dishesCategory.exclude_min_order_amount_cal !== 'true') totalForMinOrderAmount += dish.net_total;
                  minOrderTotalClaAmountForThisDish = dish.net_total;

                  finalDishNetTotalWithDiscount = dish.net_total;
                }
              }
            }

            // calculate restaurant anytime discounts for the item
            if (!appliedAnyDiscount) {
              let delm = 'deliver';

              switch (this.appObject.selectedDeliveryMethod) {
                case 'pickup':
                  delm = 'pickup';
                  break;
                case 'dine_in':
                  delm = 'dinein';
                  break;
              }

              if (this.appObject.selectedRestaurantDetails && this.appObject.selectedRestaurantDetails[delm + '_discount']) {
                let oddis = [];
                oddis['amount'] = this.appObject.selectedRestaurantDetails[delm + '_discount'];
                oddis['type'] = 'percentage';
                oddis['name'] = 'anytime ' + oddis['amount'] + '%';

                let discountCalculations = await this.getDiscount(dish.net_total, oddis['type'], oddis['amount']);
                dishTotalDiscount += discountCalculations['dis_amoount'];
                dish.discounted_amount = dish.net_total - discountCalculations['dis_amoount'];
                dish.total_discount = discountCalculations['dis_amoount'];

                // dishNetTotalWithDiscount += dish.discounted_amount;
                finalDishNetTotalWithDiscount = dish.discounted_amount;

                // if the menu category has excluded from getting calculated for min order amount, this won't calculate for min order amount check
                // if (dishesCategory.exclude_min_order_amount_cal !== 'true') totalForMinOrderAmount += dish.discounted_amount;
                minOrderTotalClaAmountForThisDish = dish.discounted_amount;

                // implemented v2 with topping tax calculation feature
                let discountCalculations_v2 = await this.getDiscount(dish.net_total_v2, oddis['type'], oddis['amount']);
                dish.discounted_amount_v2 = dish.net_total_v2 - discountCalculations_v2['dis_amoount'];
                dishTotalDiscount_v2 += discountCalculations_v2['dis_amoount'];
                dish.total_discount_v2 = discountCalculations_v2['dis_amoount'];
                // ------

                // console.log('oddis : ' , oddis);

                dish.discount.push({
                  'amount': oddis['amount'],
                  'type': oddis['type'],
                  'name': oddis['name'],
                  'discount_amount': discountCalculations['dis_amoount'],
                  'is_anytime_discount': 'true'
                });

                appliedAnyDiscount = true;
              } else {
                // if the menu category has excluded from getting calculated for min order amount, this won't calculate for min order amount check
                // if (dishesCategory.exclude_min_order_amount_cal !== 'true') totalForMinOrderAmount += dish.net_total;
                minOrderTotalClaAmountForThisDish = dish.net_total;


                // dishNetTotalWithDiscount += dish.net_total;
                finalDishNetTotalWithDiscount = dish.net_total;
              }
            }
          } else {
            minOrderTotalClaAmountForThisDish = dish.net_total;

            finalDishNetTotalWithDiscount = dish.net_total;
          }

          dishNetTotal += dish.net_total;
          // for topping tax
          dishNetTotal_v2 += dish.net_total_v2;
          // multiply topping total by dish count
          dishToppingNetTotal_v2 += dish.net_topping_total_v2 * dish.count;

          // get final value and add it to dishNetTotalWithDiscount
          dishNetTotalWithDiscount += finalDishNetTotalWithDiscount;

          // add value for min order amount check value 
          if (dishesCategory.exclude_min_order_amount_cal !== 'true') totalForMinOrderAmount += minOrderTotalClaAmountForThisDish;


          // calculate menu category tax for selected dish 
          let taxOb = this.appObject.orderObjectDup.cart[index].tax ? this.appObject.orderObjectDup.cart[index].tax : null;
          // console.log('this.appObject.orderObjectDup.cart[index]  : ' , this.appObject.orderObjectDup.cart[index]);

          // get dish menu category id
          if (dishesCategory) dish.menu_category_id = dishesCategory.id;

          if (dishesCategory && dishesCategory.tax && dishesCategory.categoryTaxDetails) {
            taxOb = dishesCategory.categoryTaxDetails;
            // delivery tax calculations
            if (deliveryTaxHelper.maximumAmountDish < dish.net_total) {
              deliveryTaxHelper.maximumAmountDish = dish.net_total;
              deliveryTaxHelper.tax = taxOb;
            }
          }

          dish.total_tax_exclusive = 0;
          dish.total_tax_inclusive = 0;

          // for topping tax
          dish.total_topping_tax_exclusive_v2 = 0;
          dish.total_topping_tax_inclusive_v2 = 0;
          dish.topping_discounted_total_v2 = 0;
          dish.total_topping_discount_v2 = 0;
          // calculate the discounts and taxes for toppings
          // this part of the code is realted with topping tax feature implementation
          if(dish.size_info && dish.size_info && dish.size_info.topping_info){
            for (let tIndex = 0; tIndex < dish.size_info.topping_info.length; tIndex++) {
              const topping = dish.size_info.topping_info[tIndex];
              
              // assign total amount by default into discounted_price
              topping.discounted_total = topping.total;
              topping.discount_amount = 0;
              // get the topping total amount by multiply from dish count
              topping.total_v2 = topping.total * dish.count


              // if discounts available in the dish, apply them to toppings as well
              // CONSIDER ONLY ONE DISCOUNT APPLIED TO THE DISH
              if(dish.discount && dish.discount.length){
                for (let tDIndex = 0; tDIndex < dish.discount.length; tDIndex++) {
                  const tDiscount = dish.discount[tDIndex];
                  let toppingDiscount = await this.getDiscount(topping.total_v2, tDiscount['type'], tDiscount['amount']);
                  
                  if(toppingDiscount){
                    topping.discounted_total = topping.total_v2 - toppingDiscount['dis_amoount'];
                    topping.discount_amount = toppingDiscount['dis_amoount'];
                    dishToppingTotalDiscount_v2 += topping.discount_amount;
                    dish.total_topping_discount_v2 += topping.discount_amount;

                    // store discount details inside topping
                    let discountOb = JSON.parse(JSON.stringify(tDiscount));
                    delete discountOb.discount_amount;
                    topping.discount_details = JSON.stringify(discountOb);
                    
                  }
                }
              }

              dish.topping_discounted_total_v2 += topping.discounted_total;

              if(topping.topping_tax){
                let toppingTax = await this.getTax(topping.discounted_total, topping.topping_tax.type, topping.topping_tax.apply_as, topping.topping_tax[this.appObject.selectedDeliveryMethod]);
                
                if (topping.topping_tax['type'] == 'excluded') {
                  dish.total_topping_tax_exclusive_v2 += toppingTax['tax_amoount'];
                  dishTotalTaxExclusive_v2 += toppingTax['tax_amoount'];
                  dishToppingTotalTaxExclusive_v2 += toppingTax['tax_amoount'];
                } else {
                  dish.total_topping_tax_inclusive_v2 += toppingTax['tax_amoount'];
                  dishTotalTaxInclusive_v2 += toppingTax['tax_amoount'];
                  dishToppingTotalTaxInclusive_v2 += toppingTax['tax_amoount'];
                }
                topping.tax_amount = toppingTax['tax_amoount'];
                
                let foundExistingTax_v2 = taxes_v2.find((taxEx) => taxEx['tax_id'] === topping.topping_tax.id);
                // console.log(topping.topping_tax);
                
                if (foundExistingTax_v2) {
                  foundExistingTax_v2['tax_amount_v2'] += toppingTax['tax_amoount'];
                } else {
                  let tax = {};
                  tax['tax_id'] = topping.topping_tax.id;
                  tax['amount'] = topping.topping_tax[this.appObject.selectedDeliveryMethod];
                  tax['type'] = topping.topping_tax.apply_as;
                  tax['tax_type'] = topping.topping_tax.type;
                  tax['name'] = topping.topping_tax.title;
                  tax['tax_amount_v2'] = toppingTax['tax_amoount'];
                  taxes_v2.push(tax);
                }

              }
            }
          }
          // ------
          
          if (taxOb) {
            let tax = {};
            // I had to put this isArray condition
            // I have no idea how this happend, but sometimes taxOb return as an array
            // didn't notice in the last developments before 2023-04-25
            // Applied by Pumayk26
            if (Array.isArray(taxOb)) {
              tax['tax_id'] = taxOb[0].id;
              tax['amount'] = taxOb[0][this.appObject.selectedDeliveryMethod];
              tax['type'] = taxOb[0].apply_as;
              tax['tax_type'] = taxOb[0].type;
              tax['name'] = taxOb[0].title;
            } else {
              tax['tax_id'] = taxOb.id;
              tax['amount'] = taxOb[this.appObject.selectedDeliveryMethod];
              tax['type'] = taxOb.apply_as;
              tax['tax_type'] = taxOb.type;
              tax['name'] = taxOb.title;
            }
            // console.log('(dish.net_total - dish.total_discount) : ' , (dish.net_total - dish.total_discount));
            let taxCalculations = await this.getTax((dish.net_total - dish.total_discount), tax['tax_type'], tax['type'], tax['amount']);

            if (tax['type'] == "fixed") {
              taxCalculations['tax_amoount'] = taxCalculations['tax_amoount'] * dish['count'];
            }

            if (tax['tax_type'] == 'excluded') {
              dish.total_tax_exclusive = taxCalculations['tax_amoount'];
              dishTotalTaxExclusive += dish.total_tax_exclusive
            } else {
              dish.total_tax_inclusive = taxCalculations['tax_amoount'];
              dishTotalTaxInclusive += dish.total_tax_inclusive;
            }

            tax['tax_amount'] = taxCalculations['tax_amoount'];

            dish.tax.push({
              'tax_id': tax['tax_id'],
              'amount': tax['amount'],
              'type': tax['type'],
              'tax_type': tax['tax_type'],
              'name': tax['tax_id'],
              'tax_amount': tax['tax_amount']
            });

            ///------ START ---------- Calculate taxes _v2
            // console.log('(dish.net_total_v2 - dish.total_discount_v2) : ' , (dish.net_total_v2 - dish.total_discount_v2));
            let taxCalculations_v2 = await this.getTax((dish.net_total_v2 - dish.total_discount_v2), tax['tax_type'], tax['type'], tax['amount']);

            if (tax['type'] == "fixed") {
              taxCalculations_v2['tax_amoount'] = taxCalculations_v2['tax_amoount'] * dish['count'];
            }

            dish.total_tax_exclusive_v2 = 0;
            dish.total_tax_inclusive_v2 = 0;
            dish.dish_total_tax_amount_v2 = 0;

            if (tax['tax_type'] == 'excluded') {
              dish.total_tax_exclusive_v2 = taxCalculations_v2['tax_amoount'];
              dishTotalTaxExclusive_v2 += dish.total_tax_exclusive_v2
              dish.dish_total_tax_exclusive_amount_v2 = taxCalculations_v2['tax_amoount'];
            } else {
              dish.total_tax_inclusive_v2 = taxCalculations_v2['tax_amoount'];
              dish.dish_total_tax_inclusive_amount_v2 = taxCalculations_v2['tax_amoount'];
              dishTotalTaxInclusive_v2 += dish.total_tax_inclusive_v2;
            }
            
            tax['tax_amount_v2'] = taxCalculations_v2['tax_amoount'];

            dish.tax_v2.push({
              'tax_id': tax['tax_id'],
              'amount': tax['amount'],
              'type': tax['type'],
              'tax_type': tax['tax_type'],
              'name': tax['tax_id'],
              'tax_amount': tax['tax_amount_v2']
            });
            ///------ END ---------- Calculate taxes _v2

            // gather tax information to calculate and check in the backend
            let foundExistingTax = taxes.find((taxEx) => taxEx['tax_id'] === tax['tax_id']);

            if (foundExistingTax) {
              foundExistingTax['tax_amount'] += tax['tax_amount'];
            } else {
              taxes.push(tax);
            }

            let foundExistingTax_v2 = taxes_v2.find((taxEx) => taxEx['tax_id'] === tax['tax_id']);

            if (foundExistingTax_v2) {
              foundExistingTax_v2['tax_amount_v2'] += tax['tax_amount_v2'];
            } else {
              taxes_v2.push(tax);
            }

            // }
            // console.log('dish : ', this.appObject.orderObject.cart[index]);
            // console.log('Tax : ', this.appObject.orderObject.cart[index].tax);
          }

          dish.gross_without_tax_price = dish.discounted_amount - dish.total_tax_inclusive;

          // console.log('dish.total_tax_inclusive :', dish.total_tax_inclusive);
          // console.log('dish.total_tax_inclusive_v2 :', (dish.total_tax_inclusive_v2 + dish.total_topping_tax_inclusive_v2));
          // console.log('Total Discounted Amount :', dish.discounted_amount_v2 + dish.topping_discounted_total_v2);
          // console.log('Total Tax Inclusive :', dish.total_tax_inclusive_v2 + dish.total_topping_tax_inclusive_v2);
          
          
          
          // for topping tax 
          dish.gross_without_tax_price_v2 = dish.discounted_amount_v2 - dish.total_tax_inclusive_v2;
          dish.gross_without_tax_price_topping_v2 = dish.topping_discounted_total_v2 - dish.total_topping_tax_inclusive_v2;
          dish.gross_without_tax_price_v2 += dish.gross_without_tax_price_topping_v2;

          // console.log('dish.gross_without_tax_price : ' , dish.gross_without_tax_price);
          // console.log('dish.total_tax_inclusive : ' , dish.total_tax_inclusive);
          // console.log('dish.total_tax_exclusive : ' , dish.total_tax_exclusive);
          // dish.gross_total = dish.gross_without_tax_price + dish.total_tax_inclusive + dish.total_tax_exclusive;
          dish.gross_total_with_discount = dish.discounted_amount; // dish.gross_total - this.appObject.orderObject.cart[index].total_discount;
          dish.gross_total_with_discount_v2 = dish.discounted_amount_v2 + dish.topping_discounted_total_v2; 
          dish.gross_total = dish.gross_without_tax_price + dish.total_tax_inclusive + dish.total_tax_exclusive;
          // for topping tax
          dish.total_tax_exclusive_v2 += dish.total_topping_tax_exclusive_v2;
          dish.total_tax_inclusive_v2 += dish.total_topping_tax_inclusive_v2;
          dish.gross_total_v2 = dish.gross_without_tax_price_v2 + dish.total_tax_inclusive_v2 + dish.total_tax_exclusive_v2;

          // this.appObject.orderObject.cart[index].gross_without_tax_price = this.appObject.orderObject.cart[index].net_total - this.appObject.orderObject.cart[index].total_tax_inclusive;
          // this.appObject.orderObject.cart[index].gross_total = this.appObject.orderObject.cart[index].gross_without_tax_price + this.appObject.orderObject.cart[index].total_tax_inclusive + this.appObject.orderObject.cart[index].total_tax_exclusive;

          dish.comment = !dish.comment ? "" : dish.comment;

          // console.log('dish : ' , dish);
        }
      }

      // order taxes
      this.appObject.orderObject.order_tax = taxes;
      this.appObject.orderObject.order_tax_v2 = taxes_v2;

      // calculate discount object again for all the order
      // this is anytime discounts, just to display in cart section
      if (orderMainDiscountOb) this.appObject.orderObject.discounts = [orderMainDiscountOb];
      // let delm = 'deliver';

      // switch (this.appObject.selectedDeliveryMethod) {
      //   case 'pickup':
      //     delm = 'pickup';
      //     break;
      //   case 'dine_in':
      //     delm = 'dinein';
      //     break;
      // }

      // if (this.appObject.selectedRestaurantDetails && this.appObject.selectedRestaurantDetails[delm + '_discount']) {
      //   let oddis = [];
      //   oddis['amount'] = this.appObject.selectedRestaurantDetails[delm + '_discount'];
      //   oddis['type'] = 'percentage';
      //   oddis['name'] = 'anytime ' + oddis['amount'] + '%';

      //   let discountCalculations = await this.getDiscount(dishNetTotal, oddis['type'], oddis['amount']);
      //   oddis['discount_amount'] = discountCalculations['dis_amoount'];

      //   // dishTotalDiscount += oddis['discount_amount'];

      //   this.appObject.orderObject.discounts.push({
      //     'amount': oddis['amount'],
      //     'type': oddis['type'],
      //     'name': oddis['name'],
      //     'discount_amount': oddis['discount_amount']
      //   });

      // }

      // calculate delivery cost
      let delivery_cost = 0;
      //by distance
      let distance = Math.round(this.appObject.selectedRestaurantDetails && this.appObject.selectedRestaurantDetails.distance_to_location ? this.appObject.selectedRestaurantDetails.distance_to_location : 0);
      if (distance == 0) {
        distance = 1;
      }

      this.appObject.orderObject.cart_summery.min_amount = 0;

      if (this.appObject.selectedDeliveryMethod == 'delivery') {
        // new IF came with postal code feature 
        if (this.appObject.settings.is_postalcode === 'true') {
          if (this.appObject.postalCodeDetails) {
            let deliveryCost = this.appObject.postalCodeDetails.price.find((prices) => prices.restaurantId === this.appObject.selectedRestaurantDetails.id);
            if (deliveryCost) {
              // get selected restaurant refreshed postal code details
              // let selectedRestaurantPostalDetails = JSON.parse(this.appObject.selectedRestaurantDetails.postal_code_details);
              let selectedRestaurantPostalDetails = this.appObject.selectedRestaurantDetails.postal_code_details_v2;
              if (selectedRestaurantPostalDetails) {
                // find selected postal code data in selected restaurant postal code details
                let postalCodeFound = selectedRestaurantPostalDetails.find((postal) => postal.code === this.appObject.postalCodeDetails.code && postal.name === this.appObject.postalCodeDetails.name)
                if (postalCodeFound) {
                  // if found, then apply the latest changes to currently selected postal code details
                  let postalChargesOfSelectedRestaurant = this.appObject.postalCodeDetails.price.find((postalCharges) => postalCharges.restaurantId === this.appObject.selectedRestaurantDetails.id);
                  if (postalChargesOfSelectedRestaurant) {
                    // apply latest postal code detail changes to selected postal code object
                    // postalChargesOfSelectedRestaurant.cost = postalCodeFound.cost;
                    // postalChargesOfSelectedRestaurant.minOrderAmount = postalCodeFound.min_order;

                    let currentPrices = this.getGivenPostalCodeCurrentPriceAndMinAmount(postalChargesOfSelectedRestaurant, this.appObject.selectedTimeSlot);
                    
                    if(currentPrices.id) this.appObject.orderObject.selected_postal_code_slot_id = currentPrices.id;
                    else this.appObject.orderObject.selected_postal_code_slot_id = null;
                    // console.log('this.appObject.orderObject.selected_postal_code_slot_id :' ,this.appObject.orderObject.selected_postal_code_slot_id);
                    
                    postalChargesOfSelectedRestaurant.cost = currentPrices.cost;
                    postalChargesOfSelectedRestaurant.minOrderAmount = currentPrices.min_order;
                  }
                } else {
                  // if there is no postal code details in restaurant postal code details as the selected one,
                  // force user to start over the ordering process
                  this.appObject.postalCodeDetails = null;
                  this.appObject.selectedDeliveryMethod = 'pickup';
                  await this.updateAppObject();
                  // show error message and redirect into home page
                  this.showToast('Unable to validate postal area details, Please start over ordering process', 'danger');
                  this.router.navigate(['home'], { replaceUrl: true, queryParams: { calculationFailed: true } });
                  return resolve({ calculationFailed: true });
                }
                // console.log('postalCodeFound :' , postalCodeFound);
              }

              this.appObject.orderObject.cart_summery.min_amount = +deliveryCost.minOrderAmount
              delivery_cost = +deliveryCost.cost;
            } else {
              console.log('something went wrong when finding delivery cost for selected restaurant');
            }
          } else {
            console.log('something went wrong when calculate delivery cost');
          }
        } else {
          // moved from outside of the IF, because this value only use when selected delivery method is 'delivery'
          let minOrderAmounts = JSON.parse(this.appObject.selectedRestaurantDetails.min_order_amounts);

          if (this.appObject.selectedRestaurantDetails.delivery_charge_type == "fixed_amount") {
            if (this.appObject.selectedRestaurantDetails.fixed_delivery_amount != null && this.appObject.selectedRestaurantDetails.fixed_delivery_amount_apply_as != null) {
              let dcharge = await this.getDeliveryCost(dishNetTotal, this.appObject.selectedRestaurantDetails.fixed_delivery_amount_apply_as, this.appObject.selectedRestaurantDetails.fixed_delivery_amount);
              delivery_cost = await this.getNumberValue(dcharge['del_amoount']);
            }
          } else {

            let byDistance = JSON.parse(this.appObject.selectedRestaurantDetails.delivery_cost);

            if (byDistance.length >= distance) {
              if (byDistance[distance - 1].cost) {
                delivery_cost = await this.getNumberValue(byDistance[distance - 1].cost);
              }
            }

          }

          this.appObject.orderObject.cart_summery.min_amount = await this.getNumberValue(minOrderAmounts[distance - 1].amount);
          if (Number.isNaN(this.appObject.orderObject.cart_summery.min_amount)) {
            this.appObject.orderObject.cart_summery.min_amount = 0;
          }

        }
        //check min order amount is reached
        if (this.appObject.selectedRestaurantDetails.free_delivery_amount_status !== 'true' && this.appObject.selectedRestaurantDetails.is_free_delivery == 'true') {
          if (this.appObject.orderObject.cart_summery.min_reached) {
            delivery_cost = 0;
          }
        }

        // new feature, this will overwrite above feature... Added new option to add minimum order amount for free delivery
        if (this.appObject.selectedRestaurantDetails.free_delivery_amount_status && this.appObject.selectedRestaurantDetails.free_delivery_amount_status === 'true' && this.appObject.selectedRestaurantDetails.free_delivery_amount) {
          if (this.appObject.selectedRestaurantDetails.free_delivery_amount <= totalForMinOrderAmount) {
            // to display savings
            this.appObject.orderObject.deliveryCostWas = delivery_cost;

            delivery_cost = 0;
            this.appObject.orderObject.specialFreeDeliveryApplied = true;
          } else this.appObject.orderObject.specialFreeDeliveryApplied = false;
        } else this.appObject.orderObject.specialFreeDeliveryApplied = false;

        // if there is a delivery cost,
        // let's calculate the tax for delivery cost
        // delivery cost tax values will be the most valuable item's tax in the cart
        this.appObject.orderObject.delivery_tax = [];

        if (this.appObject.selectedDeliveryMethod == 'delivery' && delivery_cost > 0 && deliveryTaxHelper.tax) {

          let dtax = [];
          dtax['tax_id'] = deliveryTaxHelper.tax.id;
          dtax['amount'] = deliveryTaxHelper.tax[this.appObject.selectedDeliveryMethod];
          dtax['type'] = deliveryTaxHelper.tax.apply_as;
          dtax['tax_type'] = 'included';
          dtax['name'] = deliveryTaxHelper.tax.title;


          let ftaxCalculations = await this.getTax(delivery_cost, dtax['tax_type'], dtax['type'], dtax['amount']);
          dtax['tax_amount'] = ftaxCalculations['tax_amoount'];

          if (dtax['tax_type'] == 'excluded') {
            deliveryTaxExclusive += dtax['tax_amount'];
            // add to dish total tax exclusive
            dishTotalTaxExclusive += deliveryTaxExclusive;
            dishTotalTaxExclusive_v2 += deliveryTaxExclusive;

            orderDeliveryTaxExclusive_v2 = deliveryTaxExclusive;
          } else {
            deliveryTaxInclusive += dtax['tax_amount'];
            // add to dish total tax inclusinve
            dishTotalTaxInclusive += deliveryTaxInclusive;
            dishTotalTaxInclusive_v2 += deliveryTaxInclusive;

            orderDeliveryTaxInclusive_v2 = deliveryTaxInclusive;
          }

          this.appObject.orderObject.delivery_tax.push({
            'tax_id': dtax['tax_id'],
            'amount': dtax['amount'],
            'type': dtax['type'],
            'tax_type': dtax['tax_type'],
            'name': dtax['name'],
            'tax_amount': dtax['tax_amount']
          });

        }

      }

      // this.appObject.orderObject.cart_summery.total_tax_inclusive = _dtotal_tax_inclusive;
      // this.appObject.orderObject.cart_summery.total_tax_exclusive = _dtotal_tax_exclusive;
      // this.appObject.orderObject.cart_summery.net_total_without_tax = _dnet_total_without_tax;
      this.appObject.orderObject.cart_summery.total_tax_inclusive = dishTotalTaxInclusive; //_dtotal_tax_inclusive;
      this.appObject.orderObject.cart_summery.total_tax_exclusive = dishTotalTaxExclusive; //_dtotal_tax_exclusive;
      this.appObject.orderObject.cart_summery.net_total = dishNetTotal; // _ot_net_total;
      this.appObject.orderObject.cart_summery.total_discount = dishTotalDiscount; // _ototal_discount;
      this.appObject.orderObject.cart_summery.total_dish_count = totalDishCount;
      this.appObject.orderObject.cart_summery.delivery_cost = delivery_cost; //  + _dt_total_tax_exclusive;

      // Topping tax feature 
      // ------------
      this.appObject.orderObject.cart_summery.total_tax_inclusive_v2 = dishTotalTaxInclusive_v2; //_dtotal_tax_inclusive;
      this.appObject.orderObject.cart_summery.total_tax_exclusive_v2 = dishTotalTaxExclusive_v2; //_dtotal_tax_exclusive;
      this.appObject.orderObject.cart_summery.total_topping_tax_inclusive_v2 = dishToppingTotalTaxInclusive_v2; //_dtotal_tax_inclusive;
      this.appObject.orderObject.cart_summery.total_topping_tax_exclusive_v2 = dishToppingTotalTaxExclusive_v2; //_dtotal_tax_exclusive;
      
      this.appObject.orderObject.cart_summery.net_total_v2 = dishNetTotal_v2 + dishToppingNetTotal_v2; // _ot_net_total;
      this.appObject.orderObject.cart_summery.total_discount_v2 = dishTotalDiscount_v2 + dishToppingTotalDiscount_v2; // _ototal_discount;

      this.appObject.orderObject.cart_summery.order_delivery_tax_exclusive_v2 = orderDeliveryTaxExclusive_v2;
      this.appObject.orderObject.cart_summery.order_delivery_tax_inclusive_v2 = orderDeliveryTaxInclusive_v2;
      // ------------

      this.appObject.orderObject.cart_summery.total_with_discount_price = this.appObject.orderObject.cart_summery.net_total - this.appObject.orderObject.cart_summery.total_discount;
      this.appObject.orderObject.cart_summery.gross_total = this.appObject.orderObject.cart_summery.total_with_discount_price + this.appObject.orderObject.cart_summery.delivery_cost;

      this.appObject.orderObject.cart_summery.dish_net_total_with_discount = dishNetTotalWithDiscount;

      this.appObject.orderObject.cart_summery.total_with_discount_price_v2 = this.appObject.orderObject.cart_summery.net_total_v2 - this.appObject.orderObject.cart_summery.total_discount_v2;
      this.appObject.orderObject.cart_summery.gross_total_v2 = this.appObject.orderObject.cart_summery.total_with_discount_price_v2 + this.appObject.orderObject.cart_summery.delivery_cost;


      // console.log('this.appObject.orderObject.cart :', this.appObject.orderObject.cart);
      // console.log('this.appObject.orderObject.cart_summery :', this.appObject.orderObject);
      

      //////// OLD VALUES 

      // this.appObject.orderObject.cart_summery.total_tax_inclusive = _dtotal_tax_inclusive;
      // this.appObject.orderObject.cart_summery.total_tax_exclusive = _dtotal_tax_exclusive;
      // this.appObject.orderObject.cart_summery.net_total_without_tax = _dnet_total_without_tax;
      // this.appObject.orderObject.cart_summery.net_total = _ot_net_total;
      // this.appObject.orderObject.cart_summery.total_discount = _ototal_discount;


      // this.appObject.orderObject.cart_summery.total_with_discount_price = this.appObject.orderObject.cart_summery.net_total - this.appObject.orderObject.cart_summery.total_discount;
      // this.appObject.orderObject.cart_summery.delivery_cost = delivery_cost + _dt_total_tax_exclusive;
      // this.appObject.orderObject.cart_summery.gross_total = this.appObject.orderObject.cart_summery.total_with_discount_price + this.appObject.orderObject.cart_summery.delivery_cost;
      // this.appObject.orderObject.cart_summery.total_dish_count = _total_dish_count;

      // Payment method transaction fee calculation
      let selectedPaymentConfig = this.appObject.selectedRestaurantDetails.payment_methods.find((method) => method.id === this.appObject.orderObject.selectedPaymentMethod);
      let feeAmount = 0;
      let fixedFeeAmount = 0;
      // if transaction fee enabled for selected payment option
      if (selectedPaymentConfig && selectedPaymentConfig.config && (selectedPaymentConfig.config.transactionFeeStatus === 'true' || selectedPaymentConfig.config.transactionFeeStatus === true)) {
        if (selectedPaymentConfig.config.feeType === 'percentage') {
          feeAmount = (this.appObject.orderObject.cart_summery.gross_total / 100) * (+selectedPaymentConfig.config.feeAmount);
        } else if (selectedPaymentConfig.config.feeType === 'fixed') {
          feeAmount = (+selectedPaymentConfig.config.feeAmount);
        }

        if(selectedPaymentConfig.config.fixedFeeStatus 
          && (selectedPaymentConfig.config.fixedFeeStatus === 'true' || selectedPaymentConfig.config.fixedFeeStatus === true)
          && selectedPaymentConfig.config.fixedFee && !isNaN(+selectedPaymentConfig.config.fixedFee)){
            fixedFeeAmount = +selectedPaymentConfig.config.fixedFee;
        }
      }
      // console.log('feeAmount : ' ,feeAmount);
      
      this.appObject.orderObject.cart_summery.transactionFee = feeAmount;
      this.appObject.orderObject.cart_summery.fixedTransactionFee = fixedFeeAmount;
      // this.appObject.orderObject.cart_summery.gross_total += feeAmount;

      // // moved from top to bottom
      // // and changed the value checking minimum order amount to total_with_discount_price instead of gross_total
      // was
      // if (this.appObject.orderObject.cart_summery.min_amount <= this.appObject.orderObject.cart_summery.total_with_discount_price) {if (this.appObject.orderObject.cart_summery.min_amount <= this.appObject.orderObject.cart_summery.total_with_discount_price) {
      if (this.appObject.orderObject.cart_summery.min_amount <= totalForMinOrderAmount) {
        this.appObject.orderObject.cart_summery.min_reached = true;
      } else {
        this.appObject.orderObject.cart_summery.min_reached = false;
      }

      console.log('this.appObject.orderObject.cart_summery.min_reached :', this.appObject.orderObject.cart_summery.min_reached);
      

      //apply coupon if available
      if (this.appObject.selectedCoupon.length) {
        let disc = {};
        disc['discount_id'] = this.appObject.selectedCoupon[0].id;
        disc['amount'] = this.appObject.selectedCoupon[0].amount;
        disc['type'] = this.appObject.selectedCoupon[0].apply_as;
        disc['name'] = this.appObject.selectedCoupon[0].title;
        disc['coupon'] = this.appObject.selectedCoupon[0].coupon_code;

        let discountCalculations = await this.getDiscountForCoupon(this.appObject?.orderObject.cart_summery.gross_total, disc['type'], disc['amount']);
        // console.log('discountCalculations:', discountCalculations);
        // console.log('disc:', disc);
        // console.log('cart_summery:', this.appObject.orderObject.cart_summery);
        this.appObject.orderObject.cart_summery['coupon_discount'] = discountCalculations['dis_amoount'];
        this.appObject.orderObject.cart_summery['gross_total'] = discountCalculations['amount_after_dis'];
        this.appObject.orderObject.cart_summery['coupon_details'] = disc;
      } else {
        this.appObject.orderObject.cart_summery.coupon_details = null;

      }

      //console.log('Order Object : ', this.appObject?.orderObject);

      this.updateAppObject();

      return resolve('calculation is done');
    })
  }

  /**
   * Check if the cart items are elegible for daily special discount
   * @param cart cart items array
   * @returns Object special discount details
   */
  checkDailySpecialDiscountEligibility(cart) {
    let totalAmount = 0;
    let totalDishCount = 0;
    for (let index = 0; index < cart.length; index++) {
      let dish = cart[index];

      totalDishCount += dish.count;

      // calculate basic dish amounts
      if (this.appObject.orderObjectDup.cart[index].is_customise == 'false' && this.appObject.orderObjectDup.cart[index].is_size == 'false') {
        dish.dish_price = this.appObject.orderObjectDup.cart[index][this.appObject.selectedDeliveryMethod];
        dish.total = dish.dish_price;
        dish.net_total = dish.dish_price * dish.count;
      }

      // calculations for toppings / if the dish is customizable
      if (this.appObject.orderObjectDup.cart[index].is_customise == 'true' && dish.size_info) {
        dish.dish_price = this.appObject.orderObjectDup.cart[index][this.appObject.selectedDeliveryMethod];

        //TOPING FOREACH // Topping calculatons 
        let sctoping_total = 0;
        for (let stindex = 0; stindex < dish.size_info.topping_info.length; stindex++) {

          dish.size_info.topping_info[stindex]['price'] = dish.size_info.topping_info[stindex][this.appObject.selectedDeliveryMethod];
          dish.size_info.topping_info[stindex]['total'] = dish.size_info.topping_info[stindex]['price'] * dish.size_info.topping_info[stindex]['count'];

          sctoping_total += dish.size_info.topping_info[stindex]['total'];

        }

        dish['topping_price'] = sctoping_total;
        dish['total'] = dish['dish_price'] + dish['topping_price'];
        dish.net_total = dish.total * dish['count'];

      }

      // Check if the dish has sizes enabled, diffrent sizes has diffrent prices..
      // sizes calculations goes here
      if (this.appObject.orderObjectDup.cart[index].is_size == 'true') {

        //for each to find correct size values
        for (let sindex = 0; sindex < this.appObject.orderObjectDup.cart[index].sizes.length; sindex++) {
          if (dish.size_info && dish.size_info.size_id == this.appObject.orderObjectDup.cart[index].sizes[sindex].id) {
            //this is correct size
            //re get size value
            dish['dish_price'] = this.appObject.orderObjectDup.cart[index].sizes[sindex][this.appObject.selectedDeliveryMethod];

            //TOPING FOREACH
            let toping_total = 0;
            for (let stindex = 0; stindex < dish.size_info.topping_info.length; stindex++) {

              dish.size_info.topping_info[stindex]['price'] = dish.size_info.topping_info[stindex][this.appObject.selectedDeliveryMethod];
              dish.size_info.topping_info[stindex]['total'] = dish.size_info.topping_info[stindex]['price'] * dish.size_info.topping_info[stindex]['count'];

              toping_total += dish.size_info.topping_info[stindex]['total'];

            }

            //END TOPING
            dish['topping_price'] = toping_total;
            dish['total'] = dish['dish_price'] + dish['topping_price'];
            dish['net_total'] = dish['total'] * dish['count'];

          }
        }

      }
      totalAmount += dish.net_total;
    }

    let details = {
      apply: false,
      amount: 0,
      method: '',
      eligible: false
    }

    switch (this.appObject.selectedDeliveryMethod) {
      case 'delivery':
        if (this.appObject.selectedRestaurantDetails.discountRestaurant.isDeliveryAvailable == 'true') {
          details.apply = true;
          details.amount = this.appObject.selectedRestaurantDetails.discountRestaurant.deliveryCost;
          details.method = 'delivery';
        }
        break;

      case 'pickup':
        if (this.appObject.selectedRestaurantDetails.discountRestaurant.isPickUpAvailable == 'true') {
          details.apply = true;
          details.amount = this.appObject.selectedRestaurantDetails.discountRestaurant.pickUpCost;
          details.method = 'pickup';
        }
        break;
      case 'dine_in':
        if (this.appObject.selectedRestaurantDetails.discountRestaurant.isDineInAvailable == 'true') {
          details.apply = true;
          details.amount = this.appObject.selectedRestaurantDetails.discountRestaurant.dineInCost;
          details.method = 'dine_in';
        }
        break;
    }

    if (details.amount > 0 && details.apply) {
      //check is reached minimum details
      if (this.appObject.selectedRestaurantDetails && this.appObject.selectedRestaurantDetails.discountRestaurant.minimumDishes <= totalDishCount && totalAmount >= this.appObject.selectedRestaurantDetails.discountRestaurant.minimumAmount) {
        details.eligible = true;
      }
    }

    // console.log('Daily Special  : ', details);
    return details;
  }

  async calculateCartAmounts() {

    return this.calculateCartAmountsV2();
    // return;
    let _dtotal_tax_exclusive = 0;
    let _dtotal_tax_inclusive = 0;
    let _dnet_total_without_tax = 0;
    let _dnet_total = 0;
    let _dtotal_discount = 0;

    let _total_dish_count = 0;
    let _dgross_total_with_discount = 0;

    // these deliveryTaxHelper is to calculate tax for the delivery cost
    // will be used in few places,
    // the new way to calculate this delivery tax is as below,
    // Find the most valuable item in the cart (x cost 10 and qty is 1, y cost 5 and qty is 3, so the most valuble one is y because qty is 3 and it cost 15 so we take the tax of dish y)
    let deliveryCostAvailable = false;
    if (this.appObject?.orderObject?.cart_summery?.delivery_cost > 0) deliveryCostAvailable = true;

    let deliveryTaxHelper = {
      maximumAmountDish: null,
      tax: null,
      deliveryTax: 0
    };

    //////////////////////////////GO EACH DISHES
    if (this.appObject?.orderObject?.cart.length > 0) {
      for (let index = 0; index < this.appObject.orderObject.cart.length; index++) {

        //////////////////////////////CLEAR PREVIOUS TAX AND DISCOUNTS
        this.appObject.orderObject.cart[index].tax = [];
        this.appObject.orderObject.cart[index].discount = [];
        //////////////////////////////END CLEAR

        //////////////////////////////COUNT += DISH COUNT
        _total_dish_count += this.appObject.orderObject.cart[index]['count'];
        //////////////////////////////END COUNT

        //////////////////////////////CHECK DISH IS NORMAL / COMBO
        // console.log('its mee called : ' , this.appObject.orderObjectDup.cart);
        if (this.appObject.orderObjectDup.cart[index].is_customise == 'false' && this.appObject.orderObjectDup.cart[index].is_size == 'false') {
          this.appObject.orderObject.cart[index].dish_price = this.appObject.orderObjectDup.cart[index][this.appObject.selectedDeliveryMethod];
          this.appObject.orderObject.cart[index].total = this.appObject.orderObject.cart[index].dish_price;
          this.appObject.orderObject.cart[index].net_total = this.appObject.orderObject.cart[index].dish_price * this.appObject.orderObject.cart[index]['count'];

        }
        //////////////////////////////END CHECKING


        //////////////////////////////CHECK IS CUSTOMIZE
        if (this.appObject.orderObjectDup.cart[index].is_customise == 'true' && this.appObject.orderObject.cart[index].size_info) {
          this.appObject.orderObject.cart[index].dish_price = this.appObject.orderObjectDup.cart[index][this.appObject.selectedDeliveryMethod];

          //TOPING FOREACH
          let sctoping_total = 0;
          for (let stindex = 0; stindex < this.appObject.orderObject.cart[index].size_info.topping_info.length; stindex++) {

            this.appObject.orderObject.cart[index].size_info.topping_info[stindex]['price'] = this.appObject.orderObject.cart[index].size_info.topping_info[stindex][this.appObject.selectedDeliveryMethod];
            this.appObject.orderObject.cart[index].size_info.topping_info[stindex]['total'] = this.appObject.orderObject.cart[index].size_info.topping_info[stindex]['price'] * this.appObject.orderObject.cart[index].size_info.topping_info[stindex]['count'];

            sctoping_total += this.appObject.orderObject.cart[index].size_info.topping_info[stindex]['total'];

          }

          //END TOPING

          // console.log('------------------------------------------------------');
          // console.log('this.appObject.orderObject.cart[index] : ' , this.appObject.orderObject.cart[index]);
          // console.log('this.appObject.orderObject.cart[index].dish_price : ' , this.appObject.orderObject.cart[index]['dish_price']);
          // console.log('this.appObject.orderObject.cart[index][topping_price] : ' , this.appObject.orderObject.cart[index]['topping_price']);
          // console.log('------------------------------------------------------');
          // console.log('');

          this.appObject.orderObject.cart[index]['topping_price'] = sctoping_total;
          this.appObject.orderObject.cart[index]['total'] = this.appObject.orderObject.cart[index]['dish_price'] + this.appObject.orderObject.cart[index]['topping_price'];
          this.appObject.orderObject.cart[index].net_total = this.appObject.orderObject.cart[index].total * this.appObject.orderObject.cart[index]['count'];

        }
        //////////////////////////////END CHECK CUSTOMIZE

        //////////////////////////////CHECK IS SIZE AVAILABLE
        if (this.appObject.orderObjectDup.cart[index].is_size == 'true') {

          //for each to find correct size values
          for (let sindex = 0; sindex < this.appObject.orderObjectDup.cart[index].sizes.length; sindex++) {
            if (this.appObject.orderObject.cart[index].size_info && this.appObject.orderObject.cart[index].size_info.size_id == this.appObject.orderObjectDup.cart[index].sizes[sindex].id) {
              //this is correct size
              //re get size value
              this.appObject.orderObject.cart[index]['dish_price'] = this.appObject.orderObjectDup.cart[index].sizes[sindex][this.appObject.selectedDeliveryMethod];

              //TOPING FOREACH
              let toping_total = 0;
              for (let stindex = 0; stindex < this.appObject.orderObject.cart[index].size_info.topping_info.length; stindex++) {

                this.appObject.orderObject.cart[index].size_info.topping_info[stindex]['price'] = this.appObject.orderObject.cart[index].size_info.topping_info[stindex][this.appObject.selectedDeliveryMethod];
                this.appObject.orderObject.cart[index].size_info.topping_info[stindex]['total'] = this.appObject.orderObject.cart[index].size_info.topping_info[stindex]['price'] * this.appObject.orderObject.cart[index].size_info.topping_info[stindex]['count'];

                toping_total += this.appObject.orderObject.cart[index].size_info.topping_info[stindex]['total'];

              }

              //END TOPING
              this.appObject.orderObject.cart[index]['topping_price'] = toping_total;
              this.appObject.orderObject.cart[index]['total'] = this.appObject.orderObject.cart[index]['dish_price'] + this.appObject.orderObject.cart[index]['topping_price'];
              this.appObject.orderObject.cart[index]['net_total'] = this.appObject.orderObject.cart[index]['total'] * this.appObject.orderObject.cart[index]['count'];



            }
          }

        }


        //////////////////////////////END CHECK SIZE AVAILABLE


        //////////////////////////////CLEAR PREVOUS VARIABLES BEFORE CALCULATE
        this.appObject.orderObject.cart[index].total_tax_inclusive = 0;
        this.appObject.orderObject.cart[index].total_tax_exclusive = 0;
        this.appObject.orderObject.cart[index].gross_without_tax_price = 0;
        this.appObject.orderObject.cart[index].gross_total = 0;
        //////////////////////////////END CLEAR


        //////////////////////////////CHECK TAX IS AVAILABLE FOR THIS DISH
        // if (this.appObject.orderObjectDup.cart[index]?.tax?.length > 0) {
        //   for (let oindex = 0; oindex < this.appObject.orderObjectDup.cart[index].tax.length; oindex++) {

        //     let tax = [];
        //     tax['tax_id'] = this.appObject.orderObjectDup.cart[index].tax[oindex].id;
        //     tax['amount'] = this.appObject.orderObjectDup.cart[index].tax[oindex][this.appObject.selectedDeliveryMethod];
        //     tax['type'] = this.appObject.orderObjectDup.cart[index].tax[oindex].apply_as;
        //     tax['tax_type'] = this.appObject.orderObjectDup.cart[index].tax[oindex].type;
        //     tax['name'] = this.appObject.orderObjectDup.cart[index].tax[oindex].title;


        //     let taxCalculations = await this.getTax(this.appObject.orderObject.cart[index].net_total, tax['tax_type'], tax['type'], tax['amount']);

        //     if (tax['type'] == "fixed") {
        //       taxCalculations['tax_amoount'] = taxCalculations['tax_amoount'] * this.appObject.orderObject.cart[index]['count'];
        //     }

        //     if (tax['tax_type'] == 'excluded') {
        //       this.appObject.orderObject.cart[index].total_tax_exclusive += taxCalculations['tax_amoount'];
        //     } else {
        //       this.appObject.orderObject.cart[index].total_tax_inclusive += taxCalculations['tax_amoount'];
        //     }

        //     tax['tax_amount'] = taxCalculations['tax_amoount'];

        //     this.appObject.orderObject.cart[index].tax.push({
        //       'tax_id': tax['tax_id'],
        //       'amount': tax['amount'],
        //       'type': tax['type'],
        //       'tax_type': tax['tax_type'],
        //       'name': tax['tax_id'],
        //       'tax_amount': tax['tax_amount']
        //     });

        //   }

        // }

        // ------------------------ Dish tax calculation with menu category tax

        let taxOb = this.appObject.orderObjectDup.cart[index].tax ? this.appObject.orderObjectDup.cart[index].tax : null;

        let dishesCategory = this.selectedRestaurantCategories.categories.find((category) => category.id === this.appObject.orderObjectDup.cart[index].menu_categories_id.id);

        if (dishesCategory && dishesCategory.tax && dishesCategory.categoryTaxDetails) {
          taxOb = dishesCategory.categoryTaxDetails;
          // delivery tax calculations
          if (deliveryTaxHelper.maximumAmountDish < this.appObject.orderObject.cart[index].net_total) {
            deliveryTaxHelper.maximumAmountDish = this.appObject.orderObject.cart[index].net_total;
            deliveryTaxHelper.tax = taxOb;
          }
        }

        if (taxOb) {
          let tax = [];
          tax['tax_id'] = taxOb.id;
          tax['amount'] = taxOb[this.appObject.selectedDeliveryMethod];
          tax['type'] = taxOb.apply_as;
          tax['tax_type'] = taxOb.type;
          tax['name'] = taxOb.title;


          let taxCalculations = await this.getTax(this.appObject.orderObject.cart[index].net_total, tax['tax_type'], tax['type'], tax['amount']);

          if (tax['type'] == "fixed") {
            taxCalculations['tax_amoount'] = taxCalculations['tax_amoount'] * this.appObject.orderObject.cart[index]['count'];
          }

          if (tax['tax_type'] == 'excluded') {
            this.appObject.orderObject.cart[index].total_tax_exclusive += taxCalculations['tax_amoount'];
          } else {
            this.appObject.orderObject.cart[index].total_tax_inclusive += taxCalculations['tax_amoount'];
          }

          tax['tax_amount'] = taxCalculations['tax_amoount'];

          this.appObject.orderObject.cart[index].tax.push({
            'tax_id': tax['tax_id'],
            'amount': tax['amount'],
            'type': tax['type'],
            'tax_type': tax['tax_type'],
            'name': tax['tax_id'],
            'tax_amount': tax['tax_amount']
          });

          // }
          // console.log('dish : ', this.appObject.orderObject.cart[index]);
          // console.log('Tax : ', this.appObject.orderObject.cart[index].tax);
        }

        // console.log('this.appObject.orderObject.cart[index].net_total : ' , this.appObject.orderObject.cart[index].net_total);
        // console.log('this.appObject.orderObject.cart[index].total_tax_inclusive : ' , this.appObject.orderObject.cart[index].total_tax_inclusive);
        // console.log('');
        this.appObject.orderObject.cart[index].gross_without_tax_price = this.appObject.orderObject.cart[index].net_total - this.appObject.orderObject.cart[index].total_tax_inclusive;
        // console.log('this.appObject.orderObject.cart[index].gross_without_tax_price : ' , this.appObject.orderObject.cart[index].gross_without_tax_price);
        // console.log('this.appObject.orderObject.cart[index].total_tax_inclusive : ' , this.appObject.orderObject.cart[index].total_tax_inclusive);
        // console.log('this.appObject.orderObject.cart[index].total_tax_exclusive : ' , this.appObject.orderObject.cart[index].total_tax_exclusive);
        // console.log('Total : ' , (this.appObject.orderObject.cart[index].gross_without_tax_price + this.appObject.orderObject.cart[index].total_tax_inclusive + this.appObject.orderObject.cart[index].total_tax_exclusive));
        // console.log('');


        this.appObject.orderObject.cart[index].gross_total = this.appObject.orderObject.cart[index].gross_without_tax_price + this.appObject.orderObject.cart[index].total_tax_inclusive + this.appObject.orderObject.cart[index].total_tax_exclusive;
        //////////////////////////////END CHECK TAX FOR DISH


        //////////////////////////////DISH TAXES +=
        _dtotal_tax_exclusive += this.appObject.orderObject.cart[index].total_tax_exclusive;
        _dtotal_tax_inclusive += this.appObject.orderObject.cart[index].total_tax_inclusive;
        _dnet_total_without_tax += this.appObject.orderObject.cart[index].gross_without_tax_price;
        _dnet_total += this.appObject.orderObject.cart[index].gross_total;

        // console.log('_dtotal_tax_exclusive : ' , _dtotal_tax_exclusive);
        // console.log('_dtotal_tax_inclusive : ' , _dtotal_tax_inclusive);
        //////////////////////////////END DISH TAXES +=


        //////////////////////////////DISCOUNT VARIABLES
        this.appObject.orderObject.cart[index].total_discount = 0;
        this.appObject.orderObject.cart[index].gross_total_with_discount = 0;
        // was : "this.appObject.orderObject.cart[index].comment = ''", have no idea why....
        // this is changed when adding dish note in web version
        this.appObject.orderObject.cart[index].comment = !this.appObject.orderObject.cart[index].comment ? "" : this.appObject.orderObject.cart[index].comment;


        //////////////////////////////CHECK DISCOUNT AVAILABILITY
        if (this.appObject.orderObjectDup.cart[index]?.discounts?.length > 0) {
          for (let dindex = 0; dindex < this.appObject.orderObjectDup.cart[index].discounts.length; dindex++) {

            if (this.appObject.orderObjectDup.cart[index].discounts[dindex].apply_platform == 'both' || this.appObject.orderObjectDup.cart[index].discounts[dindex].apply_platform == config.plf) {
              let disc = [];
              disc['discount_id'] = this.appObject.orderObjectDup.cart[index].discounts[dindex].id;
              disc['amount'] = this.appObject.orderObjectDup.cart[index].discounts[dindex].amount;
              disc['type'] = this.appObject.orderObjectDup.cart[index].discounts[dindex].apply_as;
              disc['name'] = this.appObject.orderObjectDup.cart[index].discounts[dindex].title;


              let discountCalculations = await this.getDiscount(this.appObject.orderObject.cart[index].gross_total, disc['type'], disc['amount']);

              if (disc['type'] == "fixed") {
                discountCalculations['dis_amoount'] = discountCalculations['dis_amoount'] * this.appObject.orderObject.cart[index]['count'];
              }

              disc['discount_amount'] = discountCalculations['dis_amoount'];

              this.appObject.orderObject.cart[index].total_discount += discountCalculations['dis_amoount'];
              this.appObject.orderObject.cart[index].discount.push({
                'discount_id': disc['discount_id'],
                'amount': disc['amount'],
                'type': disc['type'],
                'name': disc['name'],
                'discount_amount': disc['discount_amount']

              });
            }

          }
        }

        this.appObject.orderObject.cart[index].gross_total_with_discount = this.appObject.orderObject.cart[index].gross_total - this.appObject.orderObject.cart[index].total_discount;
        // console.log('this.appObject.orderObject.cart[index].gross_total_with_discount : (' , index , ')   ', this.appObject.orderObject.cart[index].gross_total_with_discount);
        _dgross_total_with_discount += this.appObject.orderObject.cart[index].gross_total_with_discount;
        //////////////////////////////END CHECK DISCOUNT AVAILABILITY
        _dtotal_discount += this.appObject.orderObject.cart[index].total_discount;

      }
    }

    if (deliveryCostAvailable) {
      // console.log('this.appObject?.orderObject?.cart_summery?.delivery_cost : ', deliveryTaxHelper);
      // let dCost = this.appObject?.orderObject?.cart_summery?.delivery_cost;
      // let type = deliveryTaxHelper.tax.type;
      // let apply_as = deliveryTaxHelper.tax.apply_as;
      // let taxAmount = deliveryTaxHelper.tax.delivery;

      // this.appObject.selectedRestaurantDetails.resturent_delivery_tax_ids = [deliveryTaxHelper.tax];

      // console.log('dCost : ' ,dCost,' | type: ', type, ' | apply_as : ' ,apply_as, ' | taxAmount : ', taxAmount);
      // let tax = await this.getTax(dCost,type,apply_as,taxAmount);
      // console.log('Tax : ' , tax);
    }
    console.log('deliveryTaxHelper : ', deliveryTaxHelper);

    //////////////////////////////END GO EACH DISHES


    //////////////////////////////ORDER TAX VARIABLES
    let _ot_total_tax_exclusive = 0;
    let _ot_total_tax_inclusive = 0;
    let _ot_net_total_without_tax = 0;
    let _ot_net_total = 0;


    //////////////////////////////ORDER TAX CHECK
    this.appObject.orderObject.order_tax = [];

    // if (this.appObject.selectedRestaurantDetails?.resturent_order_tax_ids?.length > 0) {
    //   for (let otindex = 0; otindex < this.appObject.selectedRestaurantDetails.resturent_order_tax_ids.length; otindex++) {
    //     let otax = [];
    //     otax['tax_id'] = this.appObject.selectedRestaurantDetails.resturent_order_tax_ids[otindex].id;
    //     otax['amount'] = this.appObject.selectedRestaurantDetails.resturent_order_tax_ids[otindex][this.appObject.selectedDeliveryMethod];
    //     otax['type'] = this.appObject.selectedRestaurantDetails.resturent_order_tax_ids[otindex].apply_as;
    //     otax['tax_type'] = this.appObject.selectedRestaurantDetails.resturent_order_tax_ids[otindex].type;
    //     otax['name'] = this.appObject.selectedRestaurantDetails.resturent_order_tax_ids[otindex].title;


    //     let ftaxCalculations = await this.getTax(_dgross_total_with_discount, otax['tax_type'], otax['type'], otax['amount']);
    //     otax['tax_amount'] = ftaxCalculations['tax_amoount'];

    //     if (otax['tax_type'] == 'excluded') {
    //       _ot_total_tax_exclusive += otax['tax_amount'];
    //     } else {
    //       _ot_total_tax_inclusive += otax['tax_amount'];
    //     }

    //     this.appObject.orderObject.order_tax.push({
    //       'tax_id': otax['tax_id'],
    //       'amount': otax['amount'],
    //       'type': otax['type'],
    //       'tax_type': otax['tax_type'],
    //       'name': otax['name'],
    //       'tax_amount': otax['tax_amount']
    //     });
    //   }
    // }

    // console.log('_dgross_total_with_discount : ' , _dgross_total_with_discount);
    // console.log('_ot_total_tax_inclusive : ' , _ot_total_tax_inclusive);

    // console.log('_ot_total_tax_inclusive : ' , _ot_total_tax_inclusive);
    // console.log('_ot_total_tax_exclusive : ' , _ot_total_tax_exclusive);

    // _dtotal_tax_exclusive
    // _dtotal_tax_inclusive

    _ot_net_total_without_tax = _dgross_total_with_discount - _dtotal_tax_inclusive;
    _ot_net_total = _dnet_total_without_tax + _dtotal_tax_inclusive + _dtotal_tax_exclusive;
    //////////////////////////////END ORDER TAX CHECK

    // console.log('_ot_net_total : ' , _ot_net_total);
    // console.log('_ot_net_total_without_tax : ' , _ot_net_total_without_tax);
    // console.log('_ot_total_tax_inclusive : ' , _ot_total_tax_inclusive);
    // console.log('_ot_total_tax_exclusive : ' , _ot_total_tax_exclusive);



    //////////////////////////////DELIVERY  COST
    let _ototal_discount = 0;

    //////////////////////////////ORDER DEFAULT DISCOUNT CHECK
    this.appObject.orderObject.discounts = [];
    let delm = 'deliver';

    switch (this.appObject.selectedDeliveryMethod) {
      case 'pickup':
        delm = 'pickup';
        break;
      case 'dine_in':
        delm = 'dinein';
        break;
    }
    if (this.appObject.selectedRestaurantDetails && this.appObject.selectedRestaurantDetails[delm + '_discount']) {
      let oddis = [];
      oddis['amount'] = this.appObject.selectedRestaurantDetails[delm + '_discount'];
      oddis['type'] = 'percentage';
      oddis['name'] = 'anytime ' + oddis['amount'] + '%';


      let discountCalculations = await this.getDiscount(_ot_net_total, oddis['type'], oddis['amount']);
      oddis['discount_amount'] = discountCalculations['dis_amoount'];

      _ototal_discount += oddis['discount_amount'];

      this.appObject.orderObject.discounts.push({
        'amount': oddis['amount'],
        'type': oddis['type'],
        'name': oddis['name'],
        'discount_amount': oddis['discount_amount']
      });

    }

    //////////////////////////////END ORDER DEFAULT DISCOUNT CHECK

    //////////////////////////////TIME LIMIT DISCOUNT
    if (this.appObject.selectedRestaurantDetails && this.appObject.selectedRestaurantDetails.discountRestaurant && this.appObject.selectedRestaurantDetails.discountRestaurant.isActive && this.appObject.selectedRestaurantDetails.discountRestaurant.isActive == "true") {
      let apply = false;
      let amount = 0;
      switch (this.appObject.selectedDeliveryMethod) {
        case 'delivery':
          if (this.appObject.selectedRestaurantDetails.discountRestaurant.isDeliveryAvailable == 'true') {
            apply = true;
            amount = this.appObject.selectedRestaurantDetails.discountRestaurant.deliveryCost;
          }
          break;

        case 'pickup':
          if (this.appObject.selectedRestaurantDetails.discountRestaurant.isPickUpAvailable == 'true') {
            apply = true;
            amount = this.appObject.selectedRestaurantDetails.discountRestaurant.pickUpCost;

          }
          break;
        case 'dine_in':
          if (this.appObject.selectedRestaurantDetails.discountRestaurant.isDineInAvailable == 'true') {
            apply = true;
            amount = this.appObject.selectedRestaurantDetails.discountRestaurant.dineInCost;

          }
          break;
      }

      if (apply) {
        //check is reached minimum details
        if (this.appObject.selectedRestaurantDetails && this.appObject.selectedRestaurantDetails.discountRestaurant.minimumDishes <= _total_dish_count && _dnet_total >= this.appObject.selectedRestaurantDetails.discountRestaurant.minimumAmount) {
          let olddis = [];
          olddis['amount'] = amount;
          olddis['type'] = 'percentage';
          olddis['name'] = this.appObject.selectedRestaurantDetails.discountRestaurant.displayText;


          let ldiscountCalculations = await this.getDiscount(_ot_net_total, olddis['type'], olddis['amount']);
          olddis['discount_amount'] = ldiscountCalculations['dis_amoount'];

          _ototal_discount += olddis['discount_amount'];

          this.appObject.orderObject.discounts.push({
            'amount': olddis['amount'],
            'type': olddis['type'],
            'name': olddis['name'],
            'discount_amount': olddis['discount_amount']
          });

        }
      }

    }

    //////////////////////////////END TIME LIMIT DISCOUNT


    //////////////////////////////CHECK TIME LIMIT DISCOUNT

    if (this.appObject.selectedRestaurantDetails && this.appObject.selectedRestaurantDetails.discountList && this.appObject.selectedRestaurantDetails.discountList.length > 0) {
      for (let tlindex = 0; tlindex < this.appObject.selectedRestaurantDetails.discountList.length; tlindex++) {
        let tld = [];
        tld['discount_id'] = this.appObject.selectedRestaurantDetails.discountList[tlindex].id;
        tld['amount'] = this.appObject.selectedRestaurantDetails.discountList[tlindex].amount;
        tld['type'] = this.appObject.selectedRestaurantDetails.discountList[tlindex].apply_as;
        tld['name'] = this.appObject.selectedRestaurantDetails.discountList[tlindex].title;


        let tldiscountCalculations = await this.getDiscount(_ot_net_total, tld['type'], tld['amount']);
        tld['discount_amount'] = tldiscountCalculations['dis_amoount'];

        _ototal_discount += tld['discount_amount'];

        this.appObject.orderObject.discounts.push({
          'discount_id': tld['discount_id'],
          'amount': tld['amount'],
          'type': tld['type'],
          'name': tld['name'],
          'discount_amount': tld['discount_amount']
        });

      }
    }

    //////////////////////////////END CHECK TIME LIMIT DISCOUNT

    //selected coupon
    if (typeof this.appObject.selectedCoupon.length !== 'undefined' && this.appObject.selectedCoupon.length > 0) {
      let oscoup = [];
      oscoup['discount_id'] = this.appObject.selectedCoupon[0].id;
      oscoup['amount'] = this.appObject.selectedCoupon[0].amount;
      oscoup['type'] = this.appObject.selectedCoupon[0].apply_as;
      oscoup['coupon_code'] = this.appObject.selectedCoupon[0].coupon_code;
      oscoup['name'] = this.appObject.selectedCoupon[0].title;


      let couponCalculations = await this.getDiscount(_ot_net_total, oscoup['type'], oscoup['amount']);
      oscoup['discount_amount'] = couponCalculations['dis_amoount'];

      _ototal_discount += oscoup['discount_amount'];

      this.appObject.orderObject.discounts.push({
        'discount_id': oscoup['discount_id'],
        'amount': oscoup['amount'],
        'type': oscoup['type'],
        'coupon_code': oscoup['coupon_code'],
        'name': oscoup['name'],
        'discount_amount': oscoup['discount_amount']
      });

    }



    this.appObject.orderObject.cart_summery.min_reached = true;


    //delivery charge
    let delivery_cost = 0;
    //by distance
    let distance = Math.round(this.appObject.selectedRestaurantDetails && this.appObject.selectedRestaurantDetails.distance_to_location ? this.appObject.selectedRestaurantDetails.distance_to_location : 0);
    if (distance == 0) {
      distance = 1;
    }

    this.appObject.orderObject.cart_summery.min_amount = 0;

    if (this.appObject.selectedDeliveryMethod == 'delivery') {
      // new IF came with postal code feature 
      if (this.appObject.settings.is_postalcode === 'true') {
        if (this.appObject.postalCodeDetails) {
          let deliveryCost = this.appObject.postalCodeDetails.price.find((prices) => prices.restaurantId === this.appObject.selectedRestaurantDetails.id);
          if (deliveryCost) {
            this.appObject.orderObject.cart_summery.min_amount = +deliveryCost.minOrderAmount;
            delivery_cost = +deliveryCost.cost;
          } else {
            console.log('something went wrong when finding delivery cost for selected restaurant');
          }
        } else {
          console.log('something went wrong when calculate delivery cost');
        }
      } else {
        // moved from outside of the IF, because this value only use when selected delivery method is 'delivery'
        let minOrderAmounts = JSON.parse(this.appObject.selectedRestaurantDetails.min_order_amounts);

        if (this.appObject.selectedRestaurantDetails.delivery_charge_type == "fixed_amount") {
          if (this.appObject.selectedRestaurantDetails.fixed_delivery_amount != null && this.appObject.selectedRestaurantDetails.fixed_delivery_amount_apply_as != null) {
            let dcharge = await this.getDeliveryCost(_ot_net_total, this.appObject.selectedRestaurantDetails.fixed_delivery_amount_apply_as, this.appObject.selectedRestaurantDetails.fixed_delivery_amount);
            delivery_cost = await this.getNumberValue(dcharge['del_amoount']);
          }
        } else {

          let byDistance = JSON.parse(this.appObject.selectedRestaurantDetails.delivery_cost);

          if (byDistance.length >= distance) {
            if (byDistance[distance - 1].cost) {
              delivery_cost = await this.getNumberValue(byDistance[distance - 1].cost);
            }
          }

        }

        this.appObject.orderObject.cart_summery.min_amount = await this.getNumberValue(minOrderAmounts[distance - 1].amount);
        if (Number.isNaN(this.appObject.orderObject.cart_summery.min_amount)) {
          this.appObject.orderObject.cart_summery.min_amount = 0;
        }

        // Moved to below
        // if (this.appObject.orderObject.cart_summery.min_amount <= _ot_net_total) {
        //   this.appObject.orderObject.cart_summery.min_reached = true;
        // } else {
        //   this.appObject.orderObject.cart_summery.min_reached = false;
        // }

      }
      //check min order amount is reached
      if (this.appObject.selectedRestaurantDetails.is_free_delivery == 'true') {
        if (this.appObject.orderObject.cart_summery.min_reached) {
          delivery_cost = 0;
        }
      }

    }


    //////////////////////////////ORDER DELEVERY TAX VARIABLES
    let _dt_total_tax_exclusive = 0;
    let _dt_total_tax_inclusive = 0;
    let _dt_net_total_without_tax = 0;
    let _dt_net_total = 0;

    //////////////////////////////ORDER DELEVERY TAX CHECK
    this.appObject.orderObject.delivery_tax = [];

    if (this.appObject.selectedDeliveryMethod == 'delivery' && delivery_cost > 0 && deliveryTaxHelper.tax) {

      // if (this.appObject.selectedRestaurantDetails?.resturent_delivery_tax_ids?.length > 0) {

      let dtax = [];
      dtax['tax_id'] = deliveryTaxHelper.tax.id;
      dtax['amount'] = deliveryTaxHelper.tax[this.appObject.selectedDeliveryMethod];
      dtax['type'] = deliveryTaxHelper.tax.apply_as;
      dtax['tax_type'] = 'included';
      dtax['name'] = deliveryTaxHelper.tax.title;


      let ftaxCalculations = await this.getTax(delivery_cost, dtax['tax_type'], dtax['type'], dtax['amount']);
      dtax['tax_amount'] = ftaxCalculations['tax_amoount'];

      if (dtax['tax_type'] == 'excluded') {
        _dt_total_tax_exclusive += dtax['tax_amount'];
      } else {
        _dt_total_tax_inclusive += dtax['tax_amount'];
      }

      this.appObject.orderObject.delivery_tax.push({
        'tax_id': dtax['tax_id'],
        'amount': dtax['amount'],
        'type': dtax['type'],
        'tax_type': dtax['tax_type'],
        'name': dtax['name'],
        'tax_amount': dtax['tax_amount']
      });

    }

    //_dt_net_total_without_tax = _dnet_total_without_tax - _dt_total_tax_inclusive;
    // _dt_net_total += (_dnet_total_without_tax - _dt_total_tax_inclusive) + _dt_total_tax_exclusive;

    //////////////////////////////END ORDER DELEVERY TAX CHECK

    //summery net_total
    // this.appObject.orderObject.cart_summery.total_tax_inclusive = _ot_total_tax_inclusive;
    // this.appObject.orderObject.cart_summery.total_tax_exclusive = _ot_total_tax_exclusive;
    // this.appObject.orderObject.cart_summery.net_total_without_tax = _ot_net_total_without_tax;
    this.appObject.orderObject.cart_summery.total_tax_inclusive = _dtotal_tax_inclusive;
    this.appObject.orderObject.cart_summery.total_tax_exclusive = _dtotal_tax_exclusive;
    this.appObject.orderObject.cart_summery.net_total_without_tax = _dnet_total_without_tax;
    this.appObject.orderObject.cart_summery.net_total = _ot_net_total;
    this.appObject.orderObject.cart_summery.total_discount = _ototal_discount;


    this.appObject.orderObject.cart_summery.total_with_discount_price = this.appObject.orderObject.cart_summery.net_total - this.appObject.orderObject.cart_summery.total_discount;
    this.appObject.orderObject.cart_summery.delivery_cost = delivery_cost + _dt_total_tax_exclusive;
    this.appObject.orderObject.cart_summery.gross_total = this.appObject.orderObject.cart_summery.total_with_discount_price + this.appObject.orderObject.cart_summery.delivery_cost;
    this.appObject.orderObject.cart_summery.total_dish_count = _total_dish_count;

    // moved from top to bottom
    // and changed the value checking minimum order amount to total_with_discount_price instead of gross_total
    if (this.appObject.orderObject.cart_summery.min_amount <= this.appObject.orderObject.cart_summery.total_with_discount_price) {
      this.appObject.orderObject.cart_summery.min_reached = true;
    } else {
      this.appObject.orderObject.cart_summery.min_reached = false;
    }


    this.updateAppObject();

  }

  async getTax(amount, type, apply_as, tax_value) {
    let tax = [];
    // console.log('amount : ' , amount);
    if (apply_as == 'percentage') {
      if (type == 'excluded') {
        tax['tax_amoount'] = (amount / 100) * tax_value;
        tax['amount_before_tax'] = amount;
        tax['amount_after_tax'] = amount + (amount / 100) * tax_value;

      } else {
        tax['tax_amoount'] = amount - (amount * 100) / (100 + tax_value);
        tax['amount_before_tax'] = amount - tax['tax_amoount'];
        tax['amount_after_tax'] = amount;
      }
    } else {
      if (type == 'excluded') {
        tax['tax_amoount'] = tax_value;
        tax['amount_before_tax'] = amount;
        tax['amount_after_tax'] = amount + tax_value;
      } else {
        tax['tax_amoount'] = tax_value;
        tax['amount_before_tax'] = amount - tax_value;
        tax['amount_after_tax'] = amount;
      }
    }

    return tax;
  }

  async getDiscount(amount, apply_as, dis_value) {
    let dis = [];

    if (apply_as == 'percentage') {
      dis['dis_amoount'] = (amount / 100) * dis_value;
      dis['amount_before_dis'] = amount;
      dis['amount_after_dis'] = amount + (amount / 100) * dis_value;

    } else {
      dis['dis_amoount'] = dis_value;
      dis['amount_before_dis'] = amount;
      dis['amount_after_dis'] = amount + dis_value;
    }

    return dis;
  }

  async getDiscountForCoupon(amount, apply_as, dis_value) {

    let dis = [];
    if (apply_as == 'percentage') {
      dis['dis_amoount'] = (amount / 100) * dis_value;
      dis['amount_after_dis'] = amount - dis['dis_amoount'];
    } else {
      if(amount < dis_value){
        dis['dis_amoount'] = amount;
        dis['amount_after_dis'] = 0; 
      }else{ 
        dis['dis_amoount'] = dis_value;
        dis['amount_after_dis'] = amount - dis_value; 
      } 
    }

    return dis;

  }

  async getDeliveryCost(amount, apply_as, del_value) {
    let del = [];

    if (apply_as == 'percentage') {
      del['del_amoount'] = (amount / 100) * del_value;
      del['amount_before_del'] = amount;
      del['amount_after_del'] = amount + (amount / 100) * del_value;

    } else {
      del['del_amoount'] = del_value;
      del['amount_before_del'] = amount;
      del['amount_after_del'] = amount + del_value;
    }

    return del;
  }

  async changeAddress() {
    this.routeToRestaurantList = true;
    this.router.navigate(['/find-near-by-me'], { replaceUrl: true });
  }

  async getLanguages() {
    await this.requestService.post('language/list', {
    }).then(data => {
      if (data.status) {
        this.appObject.langList = data.lang;
      }
    }, error => {
      this.appObject.isLoading = false;
    });
  }

  async addCoupon(code) {
    return await this.requestService.post('coupon/status', {
      'code': code,
      'restaurant_id': this.appObject.selectedRestaurantId,
      'customer_id': this.appObject.userId,
      'device_type': config.plf,
      'device_id': this.appObject.deviceUUID,
      'discounts': this.appObject.orderObject.discounts,
      'language': this.appObject.currentLang,
    });
  }

  async changeLanguage(val) {
    this.currentLang = val.detail.value;
    this.translate.setDefaultLang(this.currentLang);
    this.translate.use(this.currentLang);
    await this.storage.set('lang', this.currentLang);
    await this.getSettings();
    this.updateAppObject();
  }



  async getSettings() {

    await this.requestService.post('settings', {
      'device_id': this.appObject.deviceUUID,
      'language': this.appObject.currentLang,
      'device_type': config.plf,
      'app_version': environment.APP_VERSION
    }).then(async data => {
      if (data.status) {

        let pages_in_side_menu = [];

        data.data.pages.forEach(element => {
          if (element.is_show_on_side_menu == "true") {
            pages_in_side_menu.push(element);
          }
        });

        this.appObject.settings = data.data;
        this.appObject.sideMenuPages = pages_in_side_menu;

        if (this.appObject.settings.app_configuration) {
          this.appObject.settings.app_configuration = JSON.parse(this.appObject.settings.app_configuration);
        }


        // get pages details , extract data that really need and set to the localstorage
        let pageDetails = data.data && data.data.pages && data.data.pages.length ? data.data.pages.reduce(
          (a, o) => (a.push({ id: o.id, slug: o.slug, name: o.name, content: o.content }), a),
          []
        ) : [];
        this.storage.set(this.STORAGE_KEYS.PAGES_KEY, JSON.stringify(pageDetails));

        // if web version, then get default restaurant details
        if (this.isWebVersion && this.appObject.settings && this.appObject.settings.main_restaurant && this.appObject.settings.main_restaurant.slug) {
          if (!this.doNotFetchMain) {
            await this.getRestaurantBySlug(this.appObject.settings.main_restaurant.slug);

            // get available discounts to display in web app landing page
            this.getDiscounts();
          }
        }

        // If multiple restaurants has then get delivery options from settings object,
        // otherwise get options from main restaurant open details
        this.findSelectedRestaurantOrderTypes(
          this.appObject.settings.app_configuration
            && this.appObject.settings.app_configuration.has_multiple_restaurants
            ? this.appObject.settings
            : this.appObject.settings.main_restaurant.openDetails
        );

        // set favicon from main restaurant 
        this.appObject.favIcon = environment.BASE_URL + '/images/restaurant-favicon/' + this.appObject.settings.main_restaurant.id;
        let favIcon: any = document.getElementById('appFavIcon');
        if (favIcon) favIcon.href = this.appObject.favIcon;
        if (this.appObject.settings.title !== "") {
          this.titleService.setTitle(this.appObject.settings.title);
        }

        if (this.appObject.settings.main_restaurant && this.appObject.settings.main_restaurant.color_configuration) {
          let theme = JSON.parse(this.appObject.settings.main_restaurant.color_configuration);
          this.appObject.theme = theme;
          // this is just for randomize the background image, otherwise it won't change event image has been changed from the backend
          if (this.appObject.theme[this.themeConfigFor] && this.appObject.theme[this.themeConfigFor].home)
            this.appObject.theme[this.themeConfigFor].home.imageURL = new Date().getTime();

          this.themeOb = this.appObject.theme;
          this.isWebVersion ? this.themeConfigFor = 'web' : '';
        }

        // update app object in storage
        this.updateAppObject();

        // check if mandatory app updates are available
        if (!this.isWebVersion && data.data && data.data.update_required) {
          this.router.navigate(['update-required']);
        }

        this.isSettingsLoaded = true;
        this.watchSettingsGettingLoaded({ status: 'done' });
      }
    }, error => {
      this.appObject.isLoading = false;
    });
  }

  /**
   * Find supported order types of selected restaurant
   * @param openDetails restaurant open details
   * @param justReturnMethods just return methods instead of assigning to availableDeliveryMethods
   * @deprecated use findSelectedRestaurantOrderTypesV2 instead
   */
  findSelectedRestaurantOrderTypes(openDetails, justReturnMethods: boolean = false) {
    let deliveryMethods = [];
    // commented due to show menu even restaurant has no deliery methods requirement
    if (openDetails.is_delivery == true || openDetails.is_delivery === 'true') {
      deliveryMethods.push("delivery");
    }
    if (openDetails.is_dine_in == true || openDetails.is_dine_in === 'true') {
      deliveryMethods.push("dine_in");
    }
    if (openDetails.is_pickup == true || openDetails.is_pickup === 'true') {
      deliveryMethods.push("pickup");
    }

    // if restaurant is closed and preorder won't accept, then take service from main restaurant
    if (!justReturnMethods && openDetails.current_status === 'closed' && !openDetails.is_pre_order && this.appObject.settings && this.appObject.settings.main_restaurant && this.appObject.settings.main_restaurant.service) {
      // if (this.appObject.settings.app_configuration && !this.appObject.settings.app_configuration.has_multiple_restaurants) {
      let service: string[] = this.appObject.settings.main_restaurant.service.split(',');
      deliveryMethods = [];
      service.includes("'delivery'") ? deliveryMethods.push('delivery') : '';
      service.includes("'dineIn'") ? deliveryMethods.push('dine_in') : '';
      service.includes("'pickup'") ? deliveryMethods.push('pickup') : '';
      // }
    }
    // deliveryMethods = ['delivery', 'dine_in', 'pickup'];
    // console.log('am caleld : ', deliveryMethods);
    if (justReturnMethods) return deliveryMethods;
    else this.availableDeliveryMethods = deliveryMethods;
  }


  /**
   * V2 : This will accept restauant object instead of openDetails object
   * Find supported order types of selected restaurant
   * @param restaurant restaurant object to get opendetails
   * @param justReturnMethods just return methods instead of assigning to availableDeliveryMethods
   */
  findSelectedRestaurantOrderTypesV2(restaurant, justReturnMethods: boolean = false) {
    let openDetails = restaurant.openDetails;
    let deliveryMethods = [];
    // commented due to show menu even restaurant has no deliery methods requirement
    if (openDetails.is_delivery == true || openDetails.is_delivery === 'true') {
      deliveryMethods.push("delivery");
    }
    if (openDetails.is_dine_in == true || openDetails.is_dine_in === 'true') {
      deliveryMethods.push("dine_in");
    }
    if (openDetails.is_pickup == true || openDetails.is_pickup === 'true') {
      deliveryMethods.push("pickup");
    }

    // if restaurant is closed and preorder won't accept, then take service from main restaurant
    if (!justReturnMethods && openDetails.current_status === 'closed' && !openDetails.is_pre_order && this.appObject.settings && this.appObject.settings.main_restaurant && this.appObject.settings.main_restaurant.service) {
      // if (this.appObject.settings.app_configuration && !this.appObject.settings.app_configuration.has_multiple_restaurants) {
      let service: string[] = this.appObject.settings.main_restaurant.service.split(',');
      deliveryMethods = [];
      service.includes("'delivery'") ? deliveryMethods.push('delivery') : '';
      service.includes("'dineIn'") ? deliveryMethods.push('dine_in') : '';
      service.includes("'pickup'") ? deliveryMethods.push('pickup') : '';
      // }
    }

    /**
     * unable to place preorders for feture when desired order option is currently disabled
     * below code is related to this issue
     * fix by Pumayk26
    **/
    let preorderHelper: any = {}
    const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const dayName = daysOfWeek[new Date().getDay()];
    // find out today's schedule of the restaurant
    let openHours = restaurant.openHoursData;
    let todaySchedule = openHours.find((dayData) => dayData.day === dayName);
    // get current time slot in HH:MM
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    // current user time slot
    let time = `${hours}:${minutes}`;

    // if an ordering option not available in ordering methods that means it's not available at the moment, 
    // so if restaurant has preorder option enabled for the option that unavailable right now, then check for other timeslots availability
    let options = ['delivery', 'dinein', 'pickup'];

    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      // this variable made because of this dine_in option _ not available in values from backend
      let includeCheck = option === 'dinein' ? 'dine_in' : option;
      // find out each unavailable options at the moment will be available in second timeslot
      // if it is available for preorder in second timeslot, display the ordering option
      if (!deliveryMethods.includes(includeCheck) && restaurant.openHoursData && restaurant.is_pre_order === 'true' && restaurant['is_pre_order_' + option] === 'true') {
        if (todaySchedule.slot_two_first && (todaySchedule['slot_two_' + option] === 'true' || todaySchedule['slot_two_' + option] === true)) {

          // get string today yyyy-mm-dd to use in calculations below
          let todayDate = this.formatDateObject(new Date(), false);

          let currentTime = new Date(todayDate + ' ' + time);
          let slotTwoFirst = new Date(todayDate + ' ' + todaySchedule.slot_two_first);
          let slot_two_second = new Date(todayDate + ' ' + todaySchedule.slot_two_second);
          // if current time is behind the ordering option available time, then make it available now for preorder
          if (currentTime.getTime() <= slotTwoFirst.getTime() || currentTime.getTime() <= slot_two_second.getTime()) {
            deliveryMethods.push(includeCheck);
            preorderHelper[includeCheck] = true;
          }

        }

      }
    }

    if (justReturnMethods) return { deliveryMethods: deliveryMethods, preorderOptions: preorderHelper };
    else this.availableDeliveryMethods = deliveryMethods;
  }

  /**
   * Watching add to cart from cross selling page when toppings available on selected dish
   * @param data 
   */
  watchSettingsGettingLoaded(data) {
    this.settingsLoadedCallBack.next(data);
  }

  triggerSettingsLoadedSuccssfulyCallback(): Subject<any> {
    return this.settingsLoadedCallBack;
  }

  async openLink(url) {
    const browser = this.iab.create(url);
  }

  async openSideMenu() {
    this.menu.enable(true, 'sidemenu');
    this.menu.open('sidemenu');
  }

  closeMenu() {
    this.menu.close('sidemenu');
  }

  pageSetup(page) {
    switch (page) {
      case 'find-near-by-me':
        this.isFooterMenu = false;
        this.appObject.errors = [];
        break;

      default:
        break;
    }
  }

  goTo(url) {
    //  this.menu.close('sidemenu');
    //this.navController.navigateRoot(url);
    setTimeout(() => {
      this.router.navigate(url);
    }, 200);
    // console.log('here');
  }

  routeToSelectedRestaurant() {
    // console.log('restaurant/' + this.appObject.selectedRestaurant);
    this.router.navigate(['restaurant/' + this.appObject.selectedRestaurant]);
  }


  formataNumero(e: any, separador: string = '.', decimais: number = 2) {
    let a: any = e.value.split('');
    let ns: string = '';
    a.forEach((c: any) => { if (!isNaN(c)) ns = ns + c; });
    ns = parseInt(ns).toString();
    if (ns.length < (decimais + 1)) { ns = ('0'.repeat(decimais + 1) + ns); ns = ns.slice((decimais + 1) * -1); }
    let ans = ns.split('');
    let r = '';
    for (let i = 0; i < ans.length; i++) if (i == ans.length - decimais) r = r + separador + ans[i]; else r = r + ans[i];
    e.value = r;
  }



  async openCart() {

    // console.log(this.appObject);
    this.animationDrop = true;
    this.className = 'animating'

    let response: any = await this.calculateCartAmounts();
    // console.log(response);
    if (response && !response.calculationFailed) {
      setTimeout(() => {
        this.className = '';
        this.animationDrop = false;
        this.openCartPopup();
      }, 1500);
    } else {
      this.animationDrop = false;
      this.className = ''
    }
  }

  async openCartPopup() {

    this.hideCartButton = true;

    const cartModal = await this.modalController.create({
      component: CartPage,
      cssClass: 'custom-popup'
    });

    await cartModal.present();

    cartModal.onDidDismiss().then((data) => {
      this.hideCartButton = false;
    });
  }

  async placeOrder(isWebVersion: boolean = false) {

    /*
     let order_details = {
       delivery_type: this.appObject.selectedDeliveryMethod,
       delivery_time: '',
       delivery_address: {
         name: this.appObject.myAddress.name,
         lat: this.appObject.myAddress.lat,
         lng: this.appObject.myAddress.lng,

    this.appObject.orderObject.order_details.delivery_address.name = this.appObject.myAddress.name;
    this.appObject.orderObject.order_details.delivery_address.lat = this.appObject.myAddress.lat;
    this.appObject.orderObject.order_details.delivery_address.lng = this.appObject.myAddress.lng;
       },
       payment_type: 'cod',
       name: 'viraj harshana',
       email: 'viraj@local.com',
       mobile_number: '071958475543',
       backyard: '',
       special_note:''
     };
     */

    //store user details in cache
    if (this.appObject.user.type == 'guest') {
      let full_name = this.appObject.orderObject.order_details.name;
      let name = full_name.split(" ");
      this.appObject.user.fname = name[0];
      this.appObject.user.lname = name.length > 2 ? name[1] + ' ' + name[2] : name[1];
      this.appObject.user.email = this.appObject.orderObject.order_details.email;
      this.appObject.user.telephone = this.appObject.orderObject.order_details.mobile_number;
    }



    this.appObject.orderObject.order_details.delivery_address.name = this.appObject.myAddress.name;
    this.appObject.orderObject.order_details.delivery_address.lat = this.appObject.myAddress.lat;
    this.appObject.orderObject.order_details.delivery_address.lng = this.appObject.myAddress.lng;


    this.appObject.orderObject['order_details'] = this.appObject.orderObject.order_details;

    this.appObject.orderObject['device_id'] = this.appObject.deviceUUID;
    this.appObject.orderObject['lang'] = this.appObject.currentLang;
    this.appObject.orderObject['resturent_id'] = this.appObject.selectedRestaurantId;
    this.appObject.orderObject['device_type'] = config.plf;
    this.appObject.orderObject['customer_id'] = this.appObject.userId;

    // console.log('this.appObject.orderObject : ', this.appObject.orderObject);
    // Do menu category visibility validation here before placing the order
    let menuCategoryValidation = {
      menuCategories: [],
      delivery_time: this.appObject.orderObject.order_details.delivery_time,
      delivery_type: this.appObject.orderObject.order_details.delivery_type,
      delivery_date: this.appObject.orderObject.order_details.delivery_date,
      lang: this.appObject.currentLang,
      device_id: this.appObject.deviceUUID,
      device_type: config.plf
    }

    for (let i = 0; i < this.appObject.orderObject.cart.length; i++) {
      const cartItem = this.appObject.orderObject.cart[i];
      let exists = menuCategoryValidation.menuCategories.find((cat) => cat.id === cartItem.menu_category_id);
      if (!exists) {
        menuCategoryValidation.menuCategories.push(cartItem.menu_category_id);
      }
    }

    this.appObject.isLoading = true;
    this.menusNotAvailable = [];
    let stopProcess = false;
    await this.requestService.post('menu-category/validate', menuCategoryValidation).then(async data => {
      if (data.status) {
        let validationResult = data.data;
        // find out what menus are not available for selected time slot
        for (let i = 0; i < validationResult.length; i++) {
          const element = validationResult[i];
          if (element && element.visibility && !element.visibility.availableForTime) this.menusNotAvailable.push(element);
        }

        if (this.menusNotAvailable.length) {
          // console.log('show that popup');

          this.tempDeliveryDate = menuCategoryValidation.delivery_date === 'asap' ? this.formatDateObject(new Date(), false) : menuCategoryValidation.delivery_date;

          if (this.isWebVersion) {
            const modal = await this.modalController.create({
              component: ModalPopoverPage,
              cssClass: 'custom-popup',
              componentProps: {
                'type': this.POPOVER_TYPES.UNABLE_TO_COMPLETE_ORDER.TYPE,
                'title': this.POPOVER_TYPES.UNABLE_TO_COMPLETE_ORDER.TITLE
              },
              keyboardClose: false,
            });

            // this.modalController.dismiss();

            modal.present();
          } else {
            const modal = await this.modalController.create({
              component: PopupPage,
              cssClass: 'custom-popup',
              componentProps: {
                'currentUI': 'unable-to-complete-order'
              }
            });
            modal.present();
            this.hideCartButton = true;
          }
          // stop the process

          this.appObject.isLoading = false;
          stopProcess = true;
        }
      }
    }).catch((error) => {
      console.log('menucategory validation error : ', error);
    }).finally(() => {
      this.appObject.isLoading = false;
    });

    if (stopProcess) return;

    // await this.calculateCartAmounts();

    // rebuild order details object (to avoid duplicated dishes) 
    if (this.appObject.orderObject.selectedTable) {
      let re_orderObject = JSON.parse(JSON.stringify(this.appObject.orderObject));
      let itemsWithSize = re_orderObject.cart.filter((item) => item.size_info);
      let itemsWithoutSize = re_orderObject.cart.filter((item) => !item.size_info);
      let recreated = [];
      for (let i = 0; i < itemsWithoutSize.length; i++) {
        const element = itemsWithoutSize[i];
        let existing = recreated.find((existingDish) => existingDish.dish_id === element.dish_id);
        if (existing) {
          existing.points_per_dish += element.points_per_dish;
          existing.count += element.count;
          existing.gross_total += element.gross_total;
          existing.gross_total_with_discount += element.gross_total_with_discount;
          existing.gross_without_tax_price += element.gross_without_tax_price;
          existing.net_total += element.net_total;
        }
        else recreated.push(element);
      }

      // find duplicates in itemsWithoutSize
      let cartItems = itemsWithSize;
      cartItems = cartItems.concat(recreated);

      this.appObject.orderObject.cart = cartItems;

      this.updateAppObject();
    }

    await this.calculateCartAmounts();
    // console.log(this.appObject.orderObject.cart);
    // return;
    //console.log('DAD : ' , this.appObject.orderObject);
    // return;
    this.appObject.isLoading = true;

    return new Promise(async (resolve, reject) => {
      // add the app version, so we can know the user app version
      this.appObject.orderObject.appVersion = this.appObject.appVersion;
      await this.requestService.post('order/add_v3', this.appObject.orderObject).then(data => {
        // console.log('inside:', data);
        if (data.status) {
          // reset the selected time slot to calculate cart amounts
          this.appObject.selectedTimeSlot = '';
          this.updateAppObject();

          if (data.points) {
            this.showToast('Huray! You just earned ' + data.points + ' points!', 'success');
          }

          if (this.appObject.orderObject.order_details.payment_type == 'points' || this.appObject.orderObject.order_details.payment_type == 'cod' || this.appObject.orderObject.order_details.payment_type == 'ecCard') {
            //success
            this.clearCart();
            // if not the web version, then redirect to order summary page
            if (!isWebVersion) this.router.navigate(['/order-summery/' + data.payment_id]);
            else {
              this.appObject.isLoading = false;
              return resolve(data);
            }
          } else {

            //open payments
            const url = data.url;

            if (isWebVersion || this.isMobileWeb()) {

              // console.log('am called : ', url);
              // this.router.navigateByUrl(url);
              if (this.isMobileWeb()) window.open(url, "_self");
              else window.location = url;

              // var Int = setInterval(() => {
              //   if (windoeRef.closed) {
              //     clearInterval(Int);
              //     Int = null;
              //     //order status check
              //     this.requestService.post('order/payment/status', { payment_id: data.payment_id, device_id: this.appObject.deviceUUID, lang: this.appObject.currentLang }).then(paymentStatus => {
              //       if (paymentStatus.status && paymentStatus.data.status === "paid") {
              //         //payment done
              //         this.clearCart();

              //         this.appObject.isLoading = false;
              //         return resolve(data);
              //       } else {
              //         //err
              //         this.translate.get('key.payment_faild_msg').toPromise().then((response) => {
              //           this.showAlert('error', '', response);
              //         });
              //       }
              //     });
              //   } else {
              //     //order status check
              //     this.requestService.post('order/payment/status', { payment_id: data.payment_id, device_id: this.appObject.deviceUUID, lang: this.appObject.currentLang }).then(paymentStatus => {
              //       this.appObject.isLoading = false;
              //       if (paymentStatus.status && paymentStatus.data.status === "paid") {
              //         //payment done
              //         clearInterval(Int);
              //         windoeRef.close();
              //         windoeRef = null;
              //         this.clearCart();

              //         return resolve(data);
              //       } else if (paymentStatus.status && paymentStatus.data.status === "cancelled") {
              //         //payment cancelled
              //         clearInterval(Int);
              //         windoeRef.close();
              //         windoeRef = null;
              //         //err
              //         this.translate.get('key.payment_faild_msg').toPromise().then((response) => {
              //           this.showAlert('error', '', response);
              //         });
              //         return reject(data);
              //       } else {
              //         //err
              //         this.translate.get('key.payment_faild_msg').toPromise().then((response) => {
              //           this.showAlert('error', '', response);
              //         });
              //       }
              //     });
              //   }
              // }, 2000);

              // this.appObject.isLoading = false;
              // return resolve(data);
            } else {
              //app start

              const options: InAppBrowserOptions = {
                location: 'no',
                clearcache: 'yes',
                zoom: 'yes',
                toolbar: 'yes',
                closebuttoncaption: 'close'
              };
              //open in app
              const browser: any = this.iab.create(url, '_blank', options);
              //event subscribe
              browser.on('loadstop').subscribe(event => {
                //order status check
                this.requestService.post('order/payment/status', { payment_id: data.payment_id, device_id: this.appObject.deviceUUID, lang: this.appObject.currentLang }).then(paymentStatus => {
                  if (paymentStatus.status && paymentStatus.data.status === "paid") {
                    setTimeout(() => {
                      //payment done
                      browser.close();
                      this.clearCart();
                      this.router.navigate(['/order-summery/' + data.payment_id]);
                    }, 3000);
                  } else if (paymentStatus.status && paymentStatus.data.status === "cancelled") {
                    setTimeout(() => {
                      //payment cancelled
                      browser.close();
                      //err
                      this.translate.get('key.payment_faild_msg').toPromise().then((response) => {
                        this.showAlert('error', '', response);
                      });
                    }, 3000);
                  } else {
                    //payment cancelled
                    const navUrl = event.url;
                    if (navUrl.includes('return')) {
                      setTimeout(() => {
                        // browser.close();
                        this.showAlert('error', 'status missing', paymentStatus.data.status);
                        //err
                        this.translate.get('key.payment_faild_msg').toPromise().then((response) => {
                          // this.showAlert('error', '', response);
                        });
                      }, 3000);
                    }
                  }
                });
              });
              //end
            }

          }

          // remove coupons after placing the order
          // this.appObject.couponWeb = '';
          // this.appObject.couponWebAdded = false;
          // this.appObject.selectedCoupon = [];
          // this.updateAppObject();
        } else {
          this.showToast(data.msg, 'danger', '', 5000);
          if (isWebVersion) {
            this.appObject.isLoading = false;
            return reject(data);
          }
        }
        this.appObject.isLoading = false;

      }, error => {
        this.appObject.isLoading = false;
      });
    });

  }
  /**
   * Show toast
   * @param msg string message to display
   * @param colorArg toast color ('primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark')
   * @param positionArg toast appearing position ('top' | 'bottom' | 'middle')
   * @param duration duration in miliseconds
   */
  async showToast(msg, colorArg: string = 'dark', positionArg: any = 'bottom', duration: number = 3000, cssClass:string = '') {
    let options:any = {
      message: msg,
      duration: duration,
      position: positionArg,
    }
    if(cssClass){
      options.cssClass = cssClass;
    }else{
      options.color = colorArg;
    }
    const toast = await this.toastController.create(options);
    toast.present();
  }


  async checkout() {
    // Check if there are not available dishes in the cart added previously
    let unavailableDishes = [];
    if (this.appObject.orderObject.cart && this.appObject.orderObject.cart.length) {
      unavailableDishes = this.appObject.orderObject.cart.filter((dish) => dish.not_available);
      if (unavailableDishes.length) {
        this.translate.get('key.one_or_more_dishes_not_available_for').toPromise().then((translation) => {
          this.showToast(translation + ' ' + this.appObject.selectedDeliveryMethod, 'danger', '', 5000);
        });
        return;
      }
    }

    this.modalController.dismiss();

    if (this.appObject.isAuthed) {
      // if (!this.isWebVersion && (this.appObject.selectedDeliveryMethod === 'delivery' && (!this.appObject.myAddress.name || this.appObject.myAddress.lat || this.appObject.myAddress.lng))) {
      //   console.log('Address Invalid');
      // }
      this.router.navigateByUrl('checkout');
    } else {
      this.router.navigate(['login'], {
        queryParams: {
          isOrderProcess: true
        }
      });
    }
  }

  async checkoutAsGuest() {
    this.appObject.user.type = 'guest';
    this.router.navigateByUrl('checkout');
  }

  async getOpenTimes() {
    let preOrderAvailability = this.findSelectedRestaurantOrderTypesV2(this.appObject.selectedRestaurantDetails, true).preorderOptions;
    let onlyAvailableForPreorder = preOrderAvailability && preOrderAvailability[this.appObject.selectedDeliveryMethod] ? true : false;

    return await this.requestService.post('checkout/delivery-time/list', {
      'restaurant_id': this.appObject.selectedRestaurantId,
      'device_id': this.appObject.deviceUUID,
      'device_type': config.plf,
      'delivery_method': this.appObject.selectedDeliveryMethod,
      'lang': this.appObject.currentLang,
      'is_special_preorder': onlyAvailableForPreorder
    });

  }

  async forgotPasswordEmail(value) {
    this.appObject.errors = [];
    this.requestService.post('forgot/password', value).then(data => {
      if (data.status) {
        this.email_content = false;
        this.code_content = true;
      } else {
        this.appObject.errors = data.msg[0];
      }
    }, error => {
      this.appObject.isLoading = false;
    });
  }

  async confirmVerifyCode(value) {
    this.appObject.errors = [];
    this.requestService.post('reset/password/verify', value).then(data => {
      if (data.status) {
        this.repeatpass_content = true;
        this.email_content = false;
        this.code_content = false;
      } else {
        this.appObject.errors = data.msg;
      }
    }, error => {
      this.appObject.isLoading = false;
    });
  }

  async resetOldPassword(value) {
    return new Promise((resolve, reject) => {
      this.appObject.errors = [];
      this.requestService.post('reset/password', value).then(data => {
        if (data.status) {
          this.repeatpass_content = false;
          this.email_content = true;
          this.code_content = false;
          return resolve('true');
        } else {
          this.appObject.errors = data.msg.password[0];
          return resolve('false');
        }
      }, error => {
        this.appObject.isLoading = false;
      });
    })
  }

  /**
   * update Profile
  */
  async updateProfile(profile_details: any) {

    this.appObject.errors = [];
    profile_details['lang'] = this.appObject.currentLang;
    profile_details['device_id'] = this.appObject.deviceUUID;
    profile_details['device_type'] = config.plf;

    return await this.requestService.post('customer/edit', profile_details);

  }

  /**
   * update Profile password
  */
  async updatePassword(passwordDetails: any) {

    this.appObject.errors = [];
    passwordDetails['lang'] = this.appObject.currentLang;
    passwordDetails['device_id'] = this.appObject.deviceUUID;
    passwordDetails['device_type'] = config.plf;

    return await this.requestService.post('customer/password/edit', passwordDetails);

  }

  /**
   * get user Details
  */
  async getUserDetails(profile_details: any) {
    this.appObject.errors = [];
    profile_details['lang'] = this.appObject.currentLang;
    profile_details['device_id'] = this.appObject.deviceUUID;
    profile_details['device_type'] = config.plf;

    return await this.requestService.post('customer/details', profile_details);

  }


  async getNumberValue(data) {
    return parseFloat(data);
  }

  /**
   * get user orders
  */
  async customerOrderList(customerDetails: any) {
    this.appObject.errors = [];
    customerDetails['lang'] = this.appObject.currentLang;
    customerDetails['device_id'] = this.appObject.deviceUUID;
    customerDetails['device_type'] = config.plf;

    return await this.requestService.post('order/list', customerDetails);
  }

  async getOrderInfo(orderUnique: any) {

    return await this.requestService.post('order/details', {
      lang: this.appObject.currentLang,
      device_id: this.appObject.deviceUUID,
      device_type: config.plf,
      payment_id: orderUnique

    });
  }

  /**
   * Save user review from order summary
   * @param reviewData user review details
   * @returns promise
   */
  async insertUserReview(reviewData: any) {
    reviewData['lang'] = this.appObject.currentLang;
    reviewData['device_id'] = this.appObject.deviceUUID;
    reviewData['device_type'] = config.plf;
    return new Promise((resolve, reject) => {
      this.requestService.post('order/review', reviewData).then((response) => {
        return resolve(response);
      }).catch((error) => {
        return reject(error);
      });
    })
  }

  /**
   * Will return given date formatted as yyyy-mm-dd hh:mm am/pm
   * @param dateObject date object to format
   * @returns string formated date
   */
  formatDateObject(dateObject, includeTime = true) {
    let month = dateObject.getMonth() + 1;
    month = month <= 9 ? '0' + month : month;
    let day = dateObject.getDate() <= 9 ? '0' + dateObject.getDate() : dateObject.getDate();
    let year = dateObject.getFullYear();
    let hour = dateObject.getHours() <= 9 ? '0' + dateObject.getHours() : dateObject.getHours();
    let minute = dateObject.getMinutes() <= 9 ? '0' + dateObject.getMinutes() : dateObject.getMinutes();
    return year + '-' + month + '-' + day + (includeTime ? (' ' + hour + ':' + minute) : '')
  }

  /**
   * Fetch opening hours of give restaurant id
   * @param restaurantID restaurant id to fetch data
   * @returns promise
   */
  getRestaurantOpeningHours(restaurantID) {
    return new Promise((resolve, reject) => {
      let data = {
        lang: this.appObject.currentLang,
        device_id: this.appObject.deviceUUID,
        device_type: config.plf
      }
      this.requestService.post('open-hours/restaurant/' + restaurantID, data).then((response) => {
        return resolve(response);
      }).catch((error) => {
        return reject(error);
      });
    });
  }


  // ------------------------------------- WEB API CALLS ONLY -----------------------------------

  /**
   * Get most popular dishes to display on home page
   * @returns promise
   */
  web_getPopularDishesHome() {
    return new Promise((resolve, reject) => {
      let data = {
        lang: this.appObject.currentLang,
        device_id: this.appObject.deviceUUID,
        device_type: config.plf
      }
      this.requestService.post('home/favourite-list', data).then((response) => {
        return resolve(response);
      }).catch((error) => {
        return reject(error);
      });
    });
  }

  clearSelectedRestaurant() {
    this.appObject.selectedRestaurant = null;
    this.appObject.selectedRestaurantDetails = [];
    this.appObject.selectedRestaurantId = null;
  }

  routeToOrder() {
    this.clearSelectedRestaurant();
    this.router.navigate(['/delivery-method']);
  }

  async getAdditivesAndSub() {
    this.appObject.isLoading = true;

    if (this.appObject.deviceUUID == "") {
      setTimeout(() => {
        this.getAdditivesAndSub();
      }, 100);
    } else {
      // empty data before request 
      this.alagic_list = [];

      await this.requestService.post('home/additive-list', {
        'lang': this.appObject.currentLang,
        'device_id': this.appObject.deviceUUID,
        'device_type': config.plf
      }).then(data => {
        if (data.status) {
          this.AddNsUB = data.data;
        }
      }, error => {
        console.log(error);
      });

      await this.requestService.post('home/alagic-list', {
        'lang': this.appObject.currentLang,
        'device_id': this.appObject.deviceUUID,
        'device_type': config.plf
      }).then(data => {
        if (data.status) {
          this.alagic_list = data.data;
        }
      }, error => {
        console.log(error);
      });

      this.appObject.isLoading = false;
    }

  }

  async getHomePromo() {
    this.appObject.isLoading = true;

    if (this.appObject.deviceUUID == "") {
      setTimeout(() => {
        this.getHomePromo();
      }, 100);
    } else {

      this.requestService.post('new-promotion/list', {
        'lang': this.appObject.currentLang,
        'device_id': this.appObject.deviceUUID,
        'device_type': config.plf,
        'restaurant_id': 'main'
      }).then(data => {
        if (data.status) {
          this.appObject.new_promotions = data.data;
        }
        this.appObject.isLoading = false;
      }, error => {
        this.appObject.isLoading = false;
      });
    }

  }

  isMobileWeb() {
    return this.platform.is('mobileweb');
  }

  /**
   * Fetch web app landing page meta details
   */
  getWebAppLandingPageMetaData() {
    return new Promise((resolve, reject) => {
      this.requestService.post('web-meta/restaurant/main', {
        'lang': this.appObject.currentLang,
        'device_id': this.appObject.deviceUUID,
        'device_type': config.plf,
        'restaurant_id': 'main'
      }).then(data => {
        return resolve(data);
      }, error => {
        this.appObject.isLoading = false;
        return reject(error);
      });
    })
  }

  async getDishesOfTheGivenCategory(cat_id) {
    return new Promise((resolve, reject) => {
      //this.appObject.isLoading = true;

      this.activeMenu = cat_id;
      this.requestService.post('dish/list', {
        'device_id': this.appObject.deviceUUID,
        'language': this.appObject.currentLang,
        'device_type': config.plf,
        'menu_categorie_id': cat_id,
        'restaurant_id': this.appObject.selectedRestaurantDetails.id
      }).then(data => {
        //this.appObject.isLoading = false;
        return resolve(data)
      }, error => {
        //this.appObject.isLoading = false;
        return reject(error);
      });
    })
  }

  /**
   * Watching add to cart from cross selling page when toppings available on selected dish
   * @param data 
   */
  watchAddToCartFromCrossSelling(data) {
    this.addToCartSuccessfulyCallBack.next(data);
  }

  triggerAddToCartSuccssfulyCallback(): Subject<any> {
    return this.addToCartSuccessfulyCallBack;
  }

  /**
   * Display alergies and additives popup
   * @param dish dish object
   */
  async displayAlergies(dish) {
    let htmlContent = '';
    let translatedContent = '';
    // additives dataset
    if (dish && dish.additives && dish.additives.length) {
      await this.translate.get('key.additives').toPromise().then((response) => {
        if (response) translatedContent = response;
        else translatedContent = 'key.additives';
      })

      htmlContent += '<strong><p>' + translatedContent + '</p></strong><ul>'
      for (let i = 0; i < dish.additives.length; i++) {
        const additive = dish.additives[i];
        htmlContent += '<li>' + additive.letter + '<br>'
          + additive.description + ' </li>';
      }
      htmlContent += '</ul>'
    }
    // alagics dataset
    if (dish && dish.alagics && dish.alagics.length) {
      await this.translate.get('key.allergies').toPromise().then((response) => {
        if (response) translatedContent = response;
        else translatedContent = 'key.allergies';
      })

      htmlContent += '<strong><p>' + translatedContent + '</p></strong><ul>'
      for (let i = 0; i < dish.alagics.length; i++) {
        const alagic = dish.alagics[i];
        htmlContent += '<li>' + alagic.letter + '<br>'
          + alagic.description + ' </li>';
      }
      htmlContent += '</ul>'
    }

    // no data to display message
    // if (!htmlContent) {
    //   await this.translate.get('key.no_allergies_and_additives_data_to_display').toPromise().then((response) => {
    //     if (response) translatedContent = response;
    //     else translatedContent = 'key.no_allergies_and_additives_data_to_display';
    //   })
    //   htmlContent += '<span><strong>' + translatedContent + '</strong></span>'
    // }

    if (this.isWebVersion && event) {
      const popover = await this.modalController.create({
        component: ModalPopoverPage,
        cssClass: 'my-custom-class',
        componentProps: {
          "htmlContent": htmlContent,
          'type': this.POPOVER_TYPES.ALLERGY.TYPE,
          'title': this.POPOVER_TYPES.ALLERGY.TITLE,
        }
      });
      await popover.present();
    } else {
      this.showAlert('alergy-info', 'key.allergies_and_additives', htmlContent, false);
    }
  }


  /**
   * Submit table reservation data
   * @param dataSet table reservation details
   * @returns promise
   */
  placeTableReservation(dataSet) {
    dataSet.device_id = this.appObject.deviceUUID;
    dataSet.language = this.appObject.currentLang;
    dataSet.device_type = config.plf;

    // this.appObject.isLoading = true;
    return new Promise((resolve, reject) => {
      this.requestService.post('table-reservation/add', dataSet).then(data => {
        // this.appObject.isLoading = false;
        return resolve(data)
      }, error => {
        // this.appObject.isLoading = false;
        return reject(error);
      });
    })
  }

  /**
   * Fetch restaurant list for table reservation form
   * @returns a promise
   */
  fetchRestaurantListForTableReservation() {
    return new Promise((resolve, reject) => {
      this.requestService.post('table-reservation/restaurant/list', {
        'device_id': this.appObject.deviceUUID,
        'language': this.appObject.currentLang,
        'device_type': config.plf,
      }).then(data => {
        return resolve(data)
      }, error => {
        return reject(error);
      });
    })
  }

  /**
   * Fetch restaurant list for table reservation form
   * @param restaurantId restaurant id to fetch action list
   * @returns a promise
   */
  fetchHighlightProducts(restaurantId: number = null) {
    return new Promise((resolve, reject) => {
      this.requestService.post('restaurant/action-list/' + (restaurantId ? restaurantId : 'main'), {
        'device_id': this.appObject.deviceUUID,
        'language': this.appObject.currentLang,
        'device_type': config.plf,
      }).then(data => {
        return resolve(data)
      }, error => {
        return reject(error);
      });
    })
  }

  /**
   * Fetch all restaurants
   */
  async fetchAllRestaurants(isInitCall = false) {
    return new Promise((resolve, reject) => {
      let sending = {
        'device_id': this.appObject.deviceUUID,
        'lang': this.appObject.currentLang,
        'delivery_method': this.appObject.selectedDeliveryMethod
      };
      this.requestService.post('restaurant/list', sending).then(data => {
        if (data.status) {
          if (isInitCall) {
            this.appObject.ourBranches = data.data;
            for (let i = 0; i < this.appObject.ourBranches.length; i++) {
              const branch = this.appObject.ourBranches[i];
              let splitedAddress = branch.address ? branch.address.split(',') : [];
              if (splitedAddress.length >= 2) {
                branch.address_1 = splitedAddress[0];
                branch.address_2 = splitedAddress[1] + ', ' + splitedAddress[2];
              }
            }
          }
          this.appObject.restaurants = data.data;
        } else {
          this.appObject.restaurants = [];
        }
        this.updateAppObject();
        this.appObject.isLoading = false;
        return resolve('done');
      }, error => {
        this.appObject.isLoading = false;
        return reject(error);
      });
    });
  }

  /**
   * Navigate user to ordering page from home page and footer btns
   */
  async navigateToOrderingPage() {
    if (this.appObject.settings && this.appObject.settings.app_configuration) {
      if (this.appObject.settings.app_configuration.has_multiple_restaurants) {
        if (!this.appObject.selectedDeliveryMethod) {
          await this.openOrderTypeChooser(null);
        }
        if (this.appObject.selectedDeliveryMethod) this.router.navigate(['restaurant-list']);
      } else if (this.appObject.settings.main_restaurant && this.appObject.settings.main_restaurant.slug) {
        if (!this.appObject.selectedDeliveryMethod) {
          await this.openOrderTypeChooser(this.appObject.settings.main_restaurant);
        }
        if (this.appObject.selectedDeliveryMethod) this.router.navigate(['restaurant', this.appObject.settings.main_restaurant.slug]);
      }
    }
  }

  async openOrderTypeChooser(branch) {
    return new Promise(async (resolve, reject) => {
      let componentProps = {
        'currentUI': 'order-type',
        'selected_branch': branch
      }

      const cartModal = await this.modalController.create({
        component: SelectOrderTypePage,
        cssClass: 'custom-popup',
        componentProps: componentProps
      });
      await cartModal.present();
      await cartModal.onDidDismiss().then((response) => {
        return resolve('done');
      });
    });
  }

  /**
   * Fetch all notifications of the logged in user
   */
  fetchNotifications() {
    this.appObject.isLoading = true;
    this.requestService.post('notification/list', {
      'device_id': this.appObject.deviceUUID,
      'device_type': config.plf,
      'lang': this.appObject.currentLang,
      'customer_id': this.appObject.userId
    }).then((response) => {
      this.appObject.notifications = [];
      if (response && response.status) {
        for (let i = 0; i < response.data.length; i++) {
          const notification = response.data[i];
          if (notification.created_at) {
            let createdAt = new Date(notification.created_at);
            notification.createdDate = createdAt.getFullYear() + '-' + ((createdAt.getMonth() + 1) <= 9 ? ('0' + (createdAt.getMonth() + 1)) : (createdAt.getMonth() + 1)) + '-' + + ((createdAt.getDate() + 1) <= 9 ? ('0' + (createdAt.getDate() + 1)) : (createdAt.getDate() + 1));
            notification.createdTime = this.formatAMPM(createdAt); // (createdAt.getHours() <= 9 ? ('0' + createdAt.getHours()) : createdAt.getHours()) + ':' + (createdAt.getMinutes() <= 9 ? ('0' + createdAt.getMinutes()) : createdAt.getMinutes()) + ':' + (createdAt.getSeconds() <= 9 ? ('0' + createdAt.getSeconds()) : createdAt.getSeconds());
          }
          if (notification.data) notification.data = JSON.parse(notification.data);
          this.appObject.notifications.push(notification);
        }
      }
      console.log(this.appObject.notifications)
      this.appObject.isLoading = false;
    }).catch((error) => {
      console.log('fetching notification occoured an error ', error);
      this.appObject.isLoading = false;
    });
  }

  formatAMPM(date: Date) {
    var hours = date.getHours();
    var minutes: any = date.getMinutes();
    var seconds: any = date.getSeconds();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    var strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm;
    return strTime;
  }

  /**
   * Mark notification as read
   * @param notification notification object
   * @returns promise
   */
  markNotificationAsRead(notification) {
    return new Promise((resolve, reject) => {
      this.appObject.isLoading = true;
      this.requestService.post('notification/mark-as-read', {
        'device_id': this.appObject.deviceUUID,
        'device_type': config.plf,
        'lang': this.appObject.currentLang,
        'customer_id': notification.customers_id,
        'notification_id': notification.id
      }).then((response) => {
        if (response && response.status) {

        }
        this.appObject.isLoading = false;
        return resolve('done');
      }).catch((error) => {
        console.log('reading notification ', error);
        this.appObject.isLoading = false;
        return reject('error');
      });
    });
  }


  /**
   * Get Dishes Of Given Category
   * @param index index of category
   * @returns promise
   */
  public get_dishes(index) {
    if (this.menuList[index]) this.menuList[index].isLoading = true;

    this.dishFetchTimeoutList.push(setTimeout(() => {
      if (typeof this.menuList[index] !== "undefined" && !this.menuList[index].dishes.length) {
        this.getDishesOfTheGivenCategory(this.menuList[index].id).then((data: any) => {
          if (this.menuList[index]) this.menuList[index].isLoading = false;
          if (data.status) {
            this.menuList[index].dishes = data.data;
          }
        }).catch(err => {
          console.log("err :", err);
          this.menuList[index].isLoading = false;
        });
      }
    }, 500 * index));
  }

  /**
   * Will retrive all the tables in selected restaurant
   * @param id restaurant id
   * @returns a promise
   */
  public fetchAllTablesOfRestaurant(id) {
    return new Promise((resolve, reject) => {
      this.requestService.post('table-number/list', {
        'device_id': this.appObject.deviceUUID,
        'device_type': config.plf,
        'lang': this.appObject.currentLang,
        'restaurant_id': id
      }).then((response) => {
        this.appObject.isLoading = false;
        return resolve(response);
      }).catch((error) => {
        this.appObject.isLoading = false;
        return reject(error);
      });
    })
  }

  /**
   * Open select table popup
   * @param restaurant selected restaurant object 
   * @param isAlreadyInMenuPage a boolean to know if the user is already in menu page
   */
  async openTableSelect(restaurant, isAlreadyInMenuPage: boolean = false) {
    this.appObject.isLoading = true;
    // get user unpaid orders if availabel to avoid placing orders on table else
    await this.getUserUnpaidActiveOrders(isAlreadyInMenuPage);

    let componentProps = {
      'currentUI': 'select-table',
      'tableSelectRestaurant': restaurant
    }

    if (isAlreadyInMenuPage) {
      componentProps['isFromMenuPage'] = true;
    }

    const model = await this.modalController.create({
      component: SelectOrderTypePage,
      cssClass: 'custom-popup',
      componentProps: componentProps
    });
    await model.present();
    this.appObject.isLoading = false;
    model.onDidDismiss().then((data) => {
      // console.log('am Here : ', data)
    })
  }

  /**
   * Send dishes to kitchen order
   * @param closeModal close modal after process (boolean)
   * @returns a promise
   */
  sendToKitchen(closeModal: boolean = true) {
    if (this.appObject.orderObject.selectedTable) {
      // maintain a variable that the selected table has a pending payment order
      this.appObject.orderObject.selectedTable.saved = true;
    }

    if (!this.isWebVersion && this.appObject.orderObject && !this.appObject.orderObject.selectedTable) {
      this.modalController.dismiss();
      this.openTableSelect(this.appObject.selectedRestaurantDetails, true);
      return;
    }

    this.appObject.orderObject['device_id'] = this.appObject.deviceUUID;
    this.appObject.orderObject['lang'] = this.appObject.currentLang;
    this.appObject.orderObject['resturent_id'] = this.appObject.selectedRestaurantId;
    this.appObject.orderObject['device_type'] = config.plf;
    this.appObject.orderObject['customer_id'] = this.appObject.userId;

    let parameters = {
      'device_id': this.appObject.deviceUUID,
      'device_type': config.plf,
      'lang': this.appObject.currentLang,
      resturent_id: this.appObject.selectedRestaurantId,
      customer_id: this.appObject.userId,
      cart: this.appObject.orderObject.cart,
      table_number_id: this.appObject.orderObject.selectedTable.id,
      cart_save: this.appObject.orderObject
    }

    this.appObject.isLoading = true;

    return this.requestService.post('table-order/save', parameters).then(data => {
      if (data.status) {
        this.appObject.orderObject.table_order_id = data.table_order_id;
        this.appObject.orderObject.save_items = data.save_items;
        this.updateAppObject();
        // show success message 
        this.translate.get('key.your_order_has_been_sent_to_kitchen').toPromise().then((response) => {
          if (response) this.showToast(response, 'success');
        });
      }
      if (closeModal) this.modalController.dismiss();
      this.appObject.isLoading = false;
    }).catch((error) => {
      console.log('error :', error);
      this.appObject.isLoading = false;
    });
  }

  /**
   * Update existing kitchen order
   * @returns a promise
   */
  updateKitchenOrder() {
    if (this.appObject.orderObject.save_items) {
      let newItems = this.appObject.orderObject.cart.slice(this.appObject.orderObject.save_items);
      if (!newItems.length) return;

      this.appObject.orderObject['device_id'] = this.appObject.deviceUUID;
      this.appObject.orderObject['lang'] = this.appObject.currentLang;
      this.appObject.orderObject['resturent_id'] = this.appObject.selectedRestaurantId;
      this.appObject.orderObject['device_type'] = config.plf;
      this.appObject.orderObject['customer_id'] = this.appObject.userId;

      let parameters = {
        'device_id': this.appObject.deviceUUID,
        'device_type': config.plf,
        'lang': this.appObject.currentLang,
        customer_id: this.appObject.userId,
        resturent_id: this.appObject.selectedRestaurantId,
        cart: newItems,
        table_order_id: this.appObject.orderObject.table_order_id,
        cart_save: this.appObject.orderObject
      }

      return this.requestService.post('table-order/change', parameters).then(data => {
        if (data.status) {
          this.appObject.orderObject.save_items = data.save_items;
          // console.log('this.appObject.orderObject : ', this.appObject.orderObject);
          this.appObject.orderObjectDup = {
            cart: [...this.appObject.orderObject.cart]
          }

          // console.log('this.appObject.orderObjectDup : ' , this.appObject.orderObjectDup);
          this.updateAppObject();
        }
      }).catch((error) => {
        console.log('error :', error);
      });
    }
  }

  /**
   * Check if new dishes been added to the cart
   * @returns boolean 
   */
  hasNewDishesInCart() {
    let newItems = this.appObject.orderObject.cart.slice(this.appObject.orderObject.save_items);
    return newItems.length ? true : false;
  }

  /**
   * Fetch if logged in user has any unpaid in kitchen orders
   * @param isAlreadyInMenuPage accept this parameter to check if the user already in menu page, then do not clear the cart
   * @returns a promise
   */
  getUserUnpaidActiveOrders(isAlreadyInMenuPage: boolean = false) {
    let parameters = {
      'device_id': this.appObject.deviceUUID,
      'device_type': config.plf,
      'lang': this.appObject.currentLang,
      customer_id: this.appObject.userId,
    }
    return this.requestService.post('table-order/status', parameters).then(async data => {
      if (data.status) {
        this.appObject.orderObject = JSON.parse(data.data.cart);
        // console.log('this.appObject.orderObject : ', this.appObject.orderObject);
        this.appObject.orderObject.table_order_id = data.data.id;
        this.appObject.orderObject.save_items = data.data.save_items;

        this.appObject.orderObjectDup = {
          cart: [...this.appObject.orderObject.cart]
        }

        await this.calculateCartAmounts();

        // console.log('this.appObject.orderObjectDup : ' , this.appObject.orderObjectDup);
        this.updateAppObject();
      } else {
        this.appObject.orderObject.table_order_id = null;
        this.appObject.orderObject.selectedTable = null;
        this.appObject.orderObject.save_items = 0;

        if (!isAlreadyInMenuPage) this.clearCart();
      }
    }).catch((error) => {
      console.log('error :', error);
    });
  }

  /* This will find out what discounts should display in web landing page
  * @returns discount list
  */
  getDiscounts() {
    let discountList = [];
    if (this.appObject && this.appObject.settings && this.appObject.settings.main_restaurant) {
      let restaurant = this.appObject.settings.main_restaurant;
      let options = [];
      if (restaurant.dinein_discount) {
        options.push({ value: restaurant.dinein_discount, label: 'key.discount_for_dinein' })
      }
      if (restaurant.pickup_discount) {
        options.push({ value: restaurant.pickup_discount, label: 'key.discount_for_pickup' })
      }
      if (restaurant.deliver_discount) {
        options.push({ value: restaurant.deliver_discount, label: 'key.discount_for_delivery' })
      }
      if (options.length) {
        let largestDiscount = this.findLargestDiscount(options);
        if (largestDiscount.value === restaurant.deliver_discount) {
          discountList = [{ value: restaurant.deliver_discount, label: 'key.discount_for_delivery' }]
        } else {
          // console.log('an empty array N : ', largestDiscount);
          if (largestDiscount.value === restaurant.pickup_discount) {
            discountList = [{ value: restaurant.pickup_discount, label: 'key.discount_for_pickup' }]
          }
          discountList = [largestDiscount];
        }
      } else {
        // console.log('an empty array 1');
        discountList = [];
      }
    }
    // console.log('an empty array');
    this.discountListWeb = discountList;
    // console.log('this.discountList : ' , this.discountListWeb);
    return discountList;
  }

  /**
   * Find out the most valuable discount from all the available discounts
   * @param arr discount array
   * @returns the largest discount object
   */
  findLargestDiscount(arr) {
    let i;

    // Initialize maximum element
    let max = arr[0].value;
    let ob = arr[0];

    // Traverse array elements 
    // from second and compare
    // every element with current max 
    for (i = 1; i < arr.length; i++) {
      if (arr[i].value > max) {
        max = arr[i].value;
        ob = arr[i];
      }

    }
    return ob;
  }

  /**
   * Will rerurn default theme color object, these colors settings are predefined
   * @returns default theme color configurations
   */
  getDefaultThemeColors() {
    let primaryColor = environment.DEFAULT_PRIMARY_COLOR || '#98cc1f'; //'#059aeb';
    let primaryTextColor = environment.DEFAULT_PRIMARY_COLOR || '#ffffff';
    // this.storage.get('appObject').then(((appObject) =>{
    //   if (appObject && appObject.theme) {
    //     this.appObject.theme = appObject.theme;
    //   }
    // }));

    return {
      app: {
        advance: false,
        primaryColor: primaryColor,
        primaryTextColor: 'white',
        headerBackground: 'black',
        headerText: 'white',
        footerMenuBackground: 'black',
        footerMenuText: 'white',
        orderNowButtonArrowActiveColor: primaryTextColor,
        orderNowButtonArrowInactiveColor: primaryColor,
        spinnerColor: primaryColor,
        favouriteRestaurantButtonBackground: primaryColor,
        favouriteRestaurantButtonIcon: primaryTextColor,
        downloadAppBannerBackgroundColor: primaryColor,
        downloadAppBannerTextColor: primaryTextColor,
        home: {
          secondaryButtons: 'white',
          secondaryButtonsText: 'black',
          backgroundType: 'gradiant', // image, gradiant, solid
          color: 'yellow',
          gradiant: 'linear-gradient(#5d5e5e,#363738)',
          imageURL: 'https://i.stack.imgur.com/uD9js.png',
          orderNowArrowActiveColor: primaryTextColor,
          orderNowArrowInactiveColor: primaryColor,
        },
        sideMenu: {
          textColor: primaryTextColor,
          background: primaryColor
        },
        advanced: {
          orderNowButton: primaryColor,
          orderNowButtonText: 'white',
          gotItButton: primaryColor,
          deliveryMethodTypeButton: primaryColor,
          deliveryMethodTypeButtonText: 'white',
          popupTitleIcon: primaryColor,
          // login page
          loginBtn: primaryColor,
          loginBtnText: 'white',
          createAccountButton: primaryColor,
          createAccountButtonText: 'white',
          orderAsGuestButton: primaryColor,
          orderAsGuestButtonText: 'white',
          // menu page
          menuTitle: primaryColor,
          cartIconColor: primaryColor,
          floatingCartButton: primaryColor,
          floatingCartButtonText: 'white',
          // cart 
          checkoutButton: primaryColor,
          checkoutButtonText: 'white',
          proceedOrderButton: primaryColor,
          proceedOrderButtonText: 'white',

          orderAgainButton: primaryColor,
          orderAgainButtonText: 'white',

          registerButton: primaryColor,
          registerButtonText: 'white',

          addToCartButton: primaryColor,
          addToCartButtonText: 'white',

          openHourDayHeader: primaryColor,
          openHourDayHeaderText: 'white',

          addReviewButton: primaryColor,
          addReviewButtonText: primaryTextColor,
          reviewStarColor: primaryColor,
        }
      },
      web: {
        advance: false,
        primaryColor: primaryColor,
        primaryTextColor: primaryTextColor,
        footerBackground: 'darkgray',
        footerText: primaryTextColor,
        navigationLinkText: primaryColor,
        navigationBackground: primaryTextColor,
        spinnerColor: primaryColor,
        home: {
          titleText: primaryColor,
          subTitleText: 'black',
          orderNowButton: primaryColor,
          orderNowButtonText: primaryTextColor,
          stepIconBackground: primaryColor,
          stepIconText: primaryTextColor,
          stepTitleColor: '#273c75',
          stepSubtitleColor: 'black',
          mobileAppAdBackground: primaryColor,
          mobileAppAdBackgroundText: primaryTextColor
        },
        menu: {
          headerButtonBackground: primaryColor,
          headerButtonText: primaryTextColor,
          categoriesHeaderBackground: primaryColor,
          categoriesHeaderText: primaryTextColor,
          activeCategoryText: primaryColor,
          shoppingCartHeaderBackground: primaryColor,
          shoppingCartHeaderText: primaryTextColor,
          shoppingCartHeaderIcon: primaryColor,
          checkoutButtonBackground: primaryColor,
          checkoutButtonText: primaryTextColor,
          grandTotalText: primaryColor,
          dishQuntityChangeButton: primaryColor,
          dishQuntityChangeButtonText: primaryTextColor,
          addToCartButton: primaryColor,
          sendToKitchenButton: primaryColor,
          sendToKitchenButtonText: primaryTextColor,
          sendToKitchenButtonDisabled: 'lightgray',
          sendToKitchenButtonTextDisabled: 'black',
          restaurantTitleBrushColor: primaryColor,
          restaurantTitleText: primaryTextColor
        },
        advanced: {
          gotItButton: primaryColor,

          deliveryMethodTypeButton: primaryColor,
          deliveryMethodTypeButtonText: primaryTextColor,

          restaurantListCardBackground: primaryColor,
          restaurantListCardText: primaryTextColor,

          popupTitleText: primaryColor,
          popupTitleBackground: 'white',
          // login page
          loginBtn: primaryColor,
          loginBtnText: 'white',

          createAccountButton: primaryColor,
          createAccountButtonText: 'white',

          orderAsGuestButton: primaryColor,
          orderAsGuestButtonText: 'white',
          // menu page
          menuTitle: primaryColor,
          // cart 
          checkoutButton: primaryColor,
          checkoutButtonText: primaryTextColor,

          proceedOrderButton: primaryColor,
          proceedOrderButtonText: primaryTextColor,

          orderAgainButton: primaryColor,
          orderAgainButtonText: primaryTextColor,

          registerButton: primaryColor,
          registerButtonText: primaryTextColor,

          openHourDayHeader: primaryColor,
          openHourDayHeaderText: primaryTextColor,

          addToCartButton: primaryColor,
          addToCartButtonText: primaryTextColor,

          addReviewButton: primaryColor,
          addReviewButtonText: primaryTextColor,
          reviewStarColor: primaryColor,
        }
      }
    };
  }

  /**
   * Refresh favourite restaurant data
   * @param slug restaurant slug to retrive data
   */
  async getFavouriteRestaurantData(slug) {

    // console.log('called multiple times');
    await this.requestService.post('restaurant/' + slug, {
      'device_id': this.appObject.deviceUUID,
      'language': this.appObject.currentLang,
      'device_type': config.plf,
      'delivery_type': this.appObject.selectedDeliveryMethod,
      'lat': this.appObject.myAddress.lat,
      'lng': this.appObject.myAddress.lng
    }).then(data => {
      if (data.status) {
        this.appObject.favouriteRestaurant = data.data;
      }
    }, error => {
      this.appObject.isLoading = false;
    });

  }
  /*
  * Send Account Delete Email
  */
  async sendAccountDeleteEmail(profile_details: any) {
    profile_details['lang'] = this.appObject.currentLang;
    profile_details['device_id'] = this.appObject.deviceUUID;
    profile_details['device_type'] = config.plf;
    return await this.requestService.post('customer/delete/send', profile_details);
  }

  /**
   * Account Delete Email
  */
  async sendAccountDelete(profile_details: any) {
    profile_details['lang'] = this.appObject.currentLang;
    profile_details['device_id'] = this.appObject.deviceUUID;
    profile_details['device_type'] = config.plf;
    return await this.requestService.post('customer/delete', profile_details);
  }

  /**
   * Modal Dismiss
  */
  modalDismiss() {
    this.modalController.dismiss();
  }

  /**
   * Return available delivery methods from all the branches in restaurant
   */
  getAvailableOrderMethodsFromBranches() {
    let availableMethods = [];
    if (this.appObject.ourBranches && this.appObject.ourBranches.length) {
      for (let i = 0; i < this.appObject.ourBranches.length; i++) {
        const element = this.appObject.ourBranches[i];
        let methods = this.findSelectedRestaurantOrderTypesV2(element, true).deliveryMethods;
        methods.forEach(method => {
          if (!availableMethods.find(methodEx => methodEx === method)) availableMethods.push(method);
        });
      }
    }
    return availableMethods;
  }

  checkSecialOpenCloseDay(details) {
    return new Promise((resolve, reject) => {
      details['lang'] = this.appObject.currentLang;
      details['device_id'] = this.appObject.deviceUUID;
      this.appObject.isLoading = true;
      this.requestService.post('special-open-close-days/check', details).then(data => {
        this.appObject.isLoading = false;
        return resolve(data);
      }, error => {
        this.appObject.isLoading = false;
        return reject(error);
      });
    });
  }

  checkHasSecialOpenCloseDayNear(details) {
    return new Promise((resolve, reject) => {
      details['lang'] = this.appObject.currentLang;
      details['device_id'] = this.appObject.deviceUUID;
      this.appObject.isLoading = true;
      this.requestService.post('special-open-close-days/check/near', details).then(data => {
        this.appObject.isLoading = false;
        return resolve(data);
      }, error => {
        this.appObject.isLoading = false;
        return reject(error);
      });
    });
  }

  /**
   * 
   * @param dateToFormat date object or date string to format
   * @param includeTime boolean true/false to include time
   * @param includeSeconds boolean true/false to include seconds in time
   * @returns 
   */
  formatDateTime(dateToFormat = new Date(), includeTime = false, includeSeconds = false) {
    const date = typeof dateToFormat === 'string' ? new Date(dateToFormat) : dateToFormat;
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    let settings = this.appObject.settings;

    let dateFormat = settings.date_format ? settings.date_format : 'DD-MM-YYYY';
    let timeFormat = settings.time_format ? settings.time_format : '24_HOURS';

    let monthName = '';
    if (dateFormat.includes('mmmm')) {
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      monthName = monthNames[date.getMonth()];
    }

    if (dateFormat.includes('MON')) {
      const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
      monthName = monthNames[date.getMonth()];
    }

    let formattedDate = dateFormat
      .replace('YYYY', year)
      .replace('MM', month)
      .replace('DD', day)
      .replace('mmmm', monthName)
      .replace('MON', monthName);

    if (includeTime) {
      let hours = '';
      let minutes = date.getMinutes().toString().padStart(2, '0');
      let seconds = date.getSeconds().toString().padStart(2, '0');
      let period = '';
      if (timeFormat === '12_HOURS') {
        // Determine whether it's AM or PM
        period = date.getHours() >= 12 ? 'PM' : 'AM';
        // Convert 24-hour format to 12-hour format
        let formattedHours = date.getHours() % 12;
        formattedHours = formattedHours ? formattedHours : 12;
        hours = formattedHours.toString().padStart(2, '0');
      } else {
        hours = date.getHours().toString().padStart(2, '0');
      }

      formattedDate += ` ${hours}:${minutes}`;

      if (includeSeconds) {
        formattedDate += `:${seconds}`;
      }

      formattedDate += ` ${period}`;
    }

    return formattedDate.trim();
  }

  /**
   * Will return min order amount and delivery cost for the given time or current time
   * @param postalCodeData postal code details object
   */
  getGivenPostalCodeCurrentPriceAndMinAmount(postalCodeData, time: string = '') {
    if (postalCodeData) {
      let currentTimeSlot = time;

      // if there is no any specified time, the get current HH:mm
      if (!currentTimeSlot) {
        let currentTime = new Date();
        const hours = currentTime.getHours().toString().padStart(2, '0');
        const minutes = currentTime.getMinutes().toString().padStart(2, '0');

        currentTimeSlot = hours + ':' + minutes;
      }

      // get the pricing for the time slot
      let currentTimeSlotDetails = postalCodeData.time_slots.find((slot) => {
        if (slot.status === 'true') {
          if (slot.time_from !== 'All Day') {
            let slotStartTime = new Date('2024-01-01 ' + slot.time_from);
            let slotEndTime = new Date('2024-01-01 ' + slot.time_to);
            let currentTime = new Date('2024-01-01 ' + currentTimeSlot);

            // if the end time is set to 00:00 means tomorrow early morning
            // so increase the day by 1
            if(slot.time_to === '00:00'){
              slotEndTime.setDate(slotEndTime.getDate() + 1);
            }

            if (currentTime >= slotStartTime && currentTime <= slotEndTime) {
              return true;
            } else {
              return false
            }
          } else {
            return true;
          }
        }
        return false;
      });

      return currentTimeSlotDetails ? currentTimeSlotDetails : { min_order: 0, cost: 0 };
    }

    return { min_order: 0, cost: 0 }
  }

}
